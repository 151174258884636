import React from 'react';

import { HStack, Icon, Skeleton, Stack, Tab, TabList, Tabs, Text } from '@ecoinvent/ui';
import { FiLock } from 'react-icons/fi';
import { Link, useLocation } from 'react-router-dom';

import useMenuUrlLinks from 'hooks/useMenuUrlLinks';

type Props = { isLoading?: boolean; hasAccess?: boolean };

const DatasetTabs = ({ isLoading, hasAccess }: Props) => {
  const { items: menuItems, handleRouting } = useMenuUrlLinks();

  const location = useLocation();

  const items = menuItems;

  return (
    <Stack w="100%" overflowX={'auto'}>
      <Skeleton isLoaded={!isLoading} alignSelf={'flex-start'} overflow={'auto'}>
        <Tabs
          overflowY="hidden"
          alignSelf={'flex-start'}
          size="sm"
          index={menuItems.map((item) => item.path).indexOf(location.pathname)}
        >
          <TabList maxW="100%" w="max-content">
            {items.map((item) => {
              const shouldBeDisabled = item.text !== 'Documentation' && !hasAccess;
              const isSelected = location.pathname === item.path;
              return (
                <Tab
                  key={item.text}
                  as={Link}
                  to={item.path}
                  onClick={() => handleRouting(item)}
                  fontWeight={isSelected ? 'semibold' : 'normal'}
                  isDisabled={shouldBeDisabled}
                >
                  <HStack>
                    {shouldBeDisabled && <Icon as={FiLock} />}
                    <Text>{item.text}</Text>
                  </HStack>
                </Tab>
              );
            })}
          </TabList>
        </Tabs>
      </Skeleton>
    </Stack>
  );
};

export default DatasetTabs;
