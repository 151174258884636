import { Icon, Text, useColorModeValue, StackProps, Td, TableCellProps, HStack } from '@ecoinvent/ui';
import { LuSearchX } from 'react-icons/lu';

type Props = StackProps &
  TableCellProps & {
    children: React.ReactNode;
  };

const NoResultCard = ({ children, ...rest }: Props) => {
  const color = useColorModeValue('gray.600', 'gray.400');

  return (
    <Td display="table-cell" p={5} px={0} w="100%" whiteSpace={'nowrap'} color={color} {...rest}>
      <HStack alignSelf={'center'} fontSize={'md'}>
        <Icon as={LuSearchX} fontSize={'lg'} />
        <Text>{children}</Text>
      </HStack>
    </Td>
  );
};

export default NoResultCard;
