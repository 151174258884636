import React from 'react';

import { Stack, Thead, Tr, Th, Tbody, TableContainer, Table, Heading } from '@ecoinvent/ui';

import { ExchangeProperty } from 'hooks/data/datasets/exchanges/types';

import PropertyTableRow from './PropertyTableRow';

type Props = { data: ExchangeProperty[] };

const Properties = ({ data }: Props) => {
  if (!data.length) return null;

  return (
    <Stack spacing={3}>
      <Heading as="h4" size="sm">
        Properties
      </Heading>
      <TableContainer whiteSpace={'break-spaces'}>
        <Table size="sm" aria-label="exchange properties">
          <Thead>
            <Tr>
              <Th pl={0}>Name</Th>
              <Th align="right">Amount</Th>
              <Th align="left">Unit</Th>
              <Th pr={0} />
            </Tr>
          </Thead>
          <Tbody>
            {data.map((item, index) => (
              <PropertyTableRow key={index} item={item} />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  );
};
export default Properties;
