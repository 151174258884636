import { useMemo } from 'react';

import { Table, TableContainer, Tbody, Th, Tr, Thead, Stack } from '@ecoinvent/ui';

import { Review } from 'hooks/data/datasets/documentation/types';

import ReviewRow from './ReviewCard/ReviewRow';

type Props = { reviews: Review[] };

const arrangeReviewersList = (reviews: Review[]) => {
  const ecoinventReviewerList: Review[] = [];
  const externalReviewerList: Review[] = [];

  reviews.forEach((review) => {
    if (review.reviewer_email.endsWith('ecoinvent.org')) {
      ecoinventReviewerList.push(review);
    } else {
      externalReviewerList.push(review);
    }
  });

  return [...externalReviewerList, ...ecoinventReviewerList];
};

const ReviewTable = ({ reviews }: Props) => {
  const arrangedReviews = useMemo(() => arrangeReviewersList(reviews), [reviews]);

  return (
    <TableContainer boxShadow={'xs'} as={Stack} whiteSpace={'break-spaces'} borderRadius={'md'} w="100%">
      <Table size="sm">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Date</Th>
            <Th visibility={'hidden'}>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {arrangedReviews.map((item, index) => (
            <ReviewRow key={index} {...item} />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default ReviewTable;
