import React from 'react';

import { Heading, Box, useColorModeValue, Stack, Divider, HStack, Icon, Text, Link } from '@ecoinvent/ui';
import { BiSupport } from 'react-icons/bi';
import { FiWind } from 'react-icons/fi';

import AppCard from 'components/UI/AppCard';

type Props = {
  query?: string;
};

const NoSearchResults = ({ query }: Props) => {
  const textColor = useColorModeValue('gray.600', 'gray.400');
  const leftBorderColor = useColorModeValue('gray.300', 'gray.600');
  return (
    <AppCard borderLeftColor={leftBorderColor} borderLeftWidth="8px" spacing={7}>
      <Heading size="md">
        <Box as="span" color={textColor}>
          No matches for
        </Box>{' '}
        "{query}"
      </Heading>
      <Stack spacing={5}>
        <HStack spacing={4} alignItems={'flex-start'}>
          <Icon as={FiWind} boxSize={6} color={textColor} />
          <Stack spacing={2}>
            <Heading size="sm">Didn't find what you were looking for?</Heading>
            <Stack as="ul" fontSize={'sm'} paddingInlineStart={5}>
              <Text as="li">Try searching for synonyms, or similar products</Text>
              <Text as="li">Try changing or removing some filters to broaden your search</Text>
              <Text as="li">Double check your search for typos or spelling errors</Text>
            </Stack>
          </Stack>
        </HStack>
        <Divider />
        <HStack spacing={4} alignItems={'flex-start'}>
          <Icon as={BiSupport} boxSize={6} color={textColor} />
          <Stack spacing={2}>
            <Heading size="sm">
              <Box as="span" color={textColor}>
                Need more
              </Box>{' '}
              Help?
            </Heading>
            <Text fontSize={'sm'} maxW="500px">
              We are still growing and add new data continuously. Feel free to reach out to our{' '}
              <Link isExternal={true} href={'https://support.ecoinvent.org/kb-tickets/new'}>
                Support Team
              </Link>{' '}
              or learn more about{' '}
              <Link isExternal={true} href={'https://support.ecoinvent.org/introduction-to-the-database'}>
                database structure
              </Link>
              .
            </Text>
          </Stack>
        </HStack>
      </Stack>
    </AppCard>
  );
};

export default NoSearchResults;
