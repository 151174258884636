import { Stack, Skeleton, Heading, Text, Table, Tbody } from '@ecoinvent/ui';
import { useParams } from 'react-router-dom';

import AppCard from 'components/UI/AppCard';
import NoResultCard from 'components/UI/NoResultCard';
import useLciaMethods from 'hooks/data/datasets/lcia/useLciaMethods';

import ImpactAssessmentDetails from './ImpactAssessmentDetails';

export const ImpactAssessment = () => {
  const { data, isLoading } = useLciaMethods();
  const { spold_id } = useParams();

  return (
    <AppCard>
      <Stack>
        <Skeleton alignSelf={'flex-start'} isLoaded={!isLoading}>
          <Heading as="h4" size="md">
            Impact assessment
          </Heading>
        </Skeleton>
        <Skeleton alignSelf={'flex-start'} isLoaded={!isLoading}>
          <Text>
            The impact scores of the selected activity are calculated using the selected LCIA method. Expand a row to view the
            contributors to each score.
          </Text>
        </Skeleton>
      </Stack>
      {!data && !isLoading ? (
        <Table>
          <Tbody>
            <NoResultCard p={0}>No Impact scores available.</NoResultCard>
          </Tbody>
        </Table>
      ) : (
        <ImpactAssessmentDetails isLoading={isLoading} methodList={data} spoldId={parseInt(spold_id ?? '-1')} />
      )}
    </AppCard>
  );
};

export default ImpactAssessment;
