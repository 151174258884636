import { useMemo } from 'react';

import { Heading, Skeleton, Stack, Table, Tbody } from '@ecoinvent/ui';
import { useParams } from 'react-router-dom';

import AppCard from 'components/UI/AppCard';
import NoResultCard from 'components/UI/NoResultCard';
import { DocumentationResponse, Geography } from 'hooks/data/datasets/documentation/types';
import useDatasetDocumentation from 'hooks/data/datasets/documentation/useDatasetDocumentation';
import useDataset from 'hooks/data/datasets/useDataset';

import DocumentationItem from './components/DocumentationItem';
import { buildDocumentationConfig, loadingConfig } from './documentationConfig';
import KeyData from './KeyData';

type Props = {
  isLoading?: boolean;
};

const Documentation = ({ isLoading }: Props) => {
  const { spold_id } = useParams();
  const { data: keyData } = useDataset(spold_id);
  const { data: documentationData, isLoading: isDocumentationLoading } = useDatasetDocumentation(spold_id);

  const documentationConfig = useMemo(
    () => (documentationData ? buildDocumentationConfig(documentationData as DocumentationResponse) : []),
    [documentationData]
  );

  const isAnythingLoading = isLoading || isDocumentationLoading;

  const configToRender = isAnythingLoading ? loadingConfig : documentationConfig;

  return (
    <Stack>
      {!isAnythingLoading && !documentationData?.activity_description ? (
        <Table>
          <Tbody>
            <NoResultCard>This dataset doesn't seem to have documentation.</NoResultCard>
          </Tbody>
        </Table>
      ) : (
        <Stack width={'100%'} spacing={5} alignItems={'flex-start'} direction={{ base: 'column-reverse', xl: 'row' }}>
          <AppCard width={'100%'} alignSelf={'stretch'}>
            <Stack spacing={8} width={'100%'}>
              <Stack>
                <Skeleton isLoaded={!isAnythingLoading}>
                  <Heading size="md">Documentation</Heading>
                </Skeleton>
              </Stack>
              {configToRender.map(({ label, value, renderValue }) => (
                <DocumentationItem
                  isLoading={isAnythingLoading}
                  key={label}
                  label={label}
                  value={value}
                  renderValue={renderValue}
                />
              ))}
            </Stack>
          </AppCard>
          <Stack
            maxW={{ base: '100%', xl: '350px' }}
            w="100%"
            position={{ base: 'relative', xl: 'sticky' }}
            top={{ base: 'auto', xl: 4 }}
          >
            <KeyData
              isLoading={isAnythingLoading}
              geography={keyData?.geography as Geography}
              time_period={documentationData?.activity_description.time_period}
              reference_product={keyData?.reference_product}
              sector={keyData?.sector}
              unit={keyData?.unit}
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default Documentation;
