import { useState } from 'react';

import {
  Badge,
  Divider,
  Text,
  Heading,
  HStack,
  Icon,
  IconButton,
  Stack,
  useColorModeValue,
  Collapse,
  Tooltip,
} from '@ecoinvent/ui';
import { BiChevronDown, BiChevronUp, BiCalendar } from 'react-icons/bi';
import { FiInfo, FiPieChart } from 'react-icons/fi';
import { HiOutlineDocumentReport } from 'react-icons/hi';

import AppCard from 'components/UI/AppCard';
import { Activity } from 'hooks/data/search/types';

import { activityTypeCopy, activityTypes, timePeriodCopy } from '../ProductSearchResultCard/ProductSearchResultTable/constants';
import { TableViewOptions } from '../SearchPreferencesModalContent/types';

import ActivitySearchResultTable from './ActivitySearchResultTable';

type Props = {
  activity: Activity;
  tableViewOption: TableViewOptions | undefined;
};

const ActivitySearchResultCard = ({ activity, tableViewOption }: Props) => {
  const grayText = useColorModeValue('gray.600', 'gray.400');
  const leftBorderColor = useColorModeValue('green.400', 'green.600');
  const iconColor = useColorModeValue('blue.500', 'blue.300');

  const calculatedTotalDatasets = activity.products.reduce((acc, product) => acc + product.total_datasets, 0);

  const [expanded, setExpanded] = useState<boolean>();
  const shouldBeCollapsed = (tableViewOption ?? TableViewOptions.EXPANDED) !== TableViewOptions.EXPANDED;
  const actualExpanded = expanded ?? !shouldBeCollapsed;

  return (
    <AppCard
      display="flex"
      flexDirection="column"
      alignItems="space-between"
      py={4}
      px={4}
      align="center"
      borderLeftColor={leftBorderColor}
      borderLeftWidth="8px"
    >
      <Stack direction={{ base: 'column', md: 'row' }} justify="space-between" alignItems={{ base: 'stretch', md: 'center' }}>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          justify="space-between"
          alignItems={{ base: 'stretch', md: 'center' }}
          spacing={5}
          px={4}
          w="100%"
        >
          <Stack spacing={1} w="100%" maxW={{ base: '100%', lg: '300px' }}>
            <Heading size="md">{activity.name}</Heading>
            <Badge variant="solid" colorScheme="green" alignSelf="flex-start">
              Activity
            </Badge>
          </Stack>
          <Divider display={{ base: 'none', md: 'block' }} orientation="vertical" w="3px" color="gray" h="80px" />
          <Stack w="100%" justify="center" fontSize={'sm'}>
            <HStack>
              <Icon as={HiOutlineDocumentReport} />
              <Text color={grayText}>{calculatedTotalDatasets} datasets</Text>
            </HStack>
            <HStack>
              <Icon as={FiPieChart} />
              <Text color={grayText}>{activityTypes[activity.activity_type]}</Text>
              <Tooltip
                hasArrow
                bg="gray.900"
                py={2}
                borderRadius="md"
                width="100%"
                color="white"
                label={activityTypeCopy[activity.activity_type]}
              >
                <Stack>
                  <Icon as={FiInfo} color={iconColor} />
                </Stack>
              </Tooltip>
            </HStack>
            <HStack>
              <Icon as={FiPieChart} />
              <Text color={grayText}>{activity.sectors.join(', ')}</Text>
            </HStack>

            <HStack>
              <Icon as={BiCalendar} />
              <Text color={grayText}>{activity.time_period}</Text>
              <Tooltip hasArrow bg="gray.900" py={2} color="white" borderRadius="md" width="100%" label={timePeriodCopy}>
                <Stack>
                  <Icon as={FiInfo} color={iconColor} />
                </Stack>
              </Tooltip>
            </HStack>
          </Stack>
        </Stack>
        <HStack>
          <IconButton
            width={'100%'}
            onClick={() => setExpanded(!actualExpanded)}
            aria-label="Expand"
            icon={actualExpanded ? <BiChevronUp /> : <BiChevronDown />}
            colorScheme="green"
            variant="subtle"
          />
        </HStack>
      </Stack>
      <Collapse in={actualExpanded} unmountOnExit>
        <ActivitySearchResultTable activity={activity} />
      </Collapse>
    </AppCard>
  );
};

export default ActivitySearchResultCard;
