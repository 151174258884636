import { useEffect, useRef, useState } from 'react';

import { AutoCompleteComboBox, InputProps, Icon, useColorModeValue, Button, Stack } from '@ecoinvent/ui';
import mixpanel from 'mixpanel-browser';
import { BiSearch } from 'react-icons/bi';
import { localStorageKeys } from 'storageKeys';

import { FilterMap, Suggestion } from 'hooks/data/search/types';
import useSuggestions from 'hooks/data/search/useSuggestions';
import useVersionInfo from 'hooks/useVersionInfo';
import SearchSuggestion from 'pages/Search/components/SearchSuggestion';

type Props = Omit<InputProps, 'onChange'> & {
  onSearch: (value: string, item_type?: string) => void;
  defaultValue: string;
  filters: FilterMap;
  btnVariant?: string;
  includeButton?: boolean;
};

type RecentSearch = {
  text: string;
  item_type?: string;
};

const loadRecentSearches = () => {
  const recentSearches = localStorage.getItem(localStorageKeys.RECENT_SEARCHES);
  return (recentSearches ? JSON.parse(recentSearches) : []) as RecentSearch[];
};

const saveRecentSearches = (recentSearches: RecentSearch[]) => {
  localStorage.setItem(localStorageKeys.RECENT_SEARCHES, JSON.stringify(recentSearches));
};

const AutoCompleteSearch = ({
  onSearch,
  defaultValue: defaultValue,
  filters,
  btnVariant = 'subtle',
  includeButton = true,
  ...rest
}: Props) => {
  const [query, setQuery] = useState(defaultValue || '');
  const [debouncedQuery, setDebouncedQuery] = useState(query);
  const { ver: version, system_model } = useVersionInfo();

  const { data: suggestions, isLoading } = useSuggestions({ query: debouncedQuery, filters: filters });

  const recentSearches = loadRecentSearches();
  const bg = useColorModeValue('white', 'whiteAlpha.100');
  const textColor = useColorModeValue('gray.800', 'white');
  const grayText = useColorModeValue('gray.500', 'gray.400');

  const myRef = useRef<HTMLInputElement>();

  const shouldShowRecentSearches = query.length === 0;

  const handleSubmit = (v: string, item_type?: string) => {
    myRef.current?.blur();
    if (v && !recentSearches.some((item) => item.text === v)) {
      const updatedRecentSearches = [...recentSearches];
      if (recentSearches.length >= 5) {
        updatedRecentSearches.pop();
      }
      saveRecentSearches([{ text: v, item_type }, ...updatedRecentSearches]);
    }
    onSearch(v, !item_type ? 'activity' : item_type);
  };

  const trackSelection = (selectedItem: Suggestion | undefined) => {
    if (!selectedItem) return;

    const eventName = shouldShowRecentSearches ? 'Recent Search Clicked' : 'Search Suggestion Clicked';

    mixpanel.track(eventName, {
      filters,
      item_type: selectedItem.item_type,
      name: selectedItem.text,
      sector: selectedItem.sector,
      version,
      system_model,
    });
  };

  // Update query after 200 ms from the last update of debouncedQuery
  useEffect(() => {
    const timer = setTimeout(() => setQuery(debouncedQuery), 200);
    return () => clearTimeout(timer);
  }, [debouncedQuery]);

  return (
    <Stack w="100%" direction={{ base: 'column', md: 'row' }} spacing={5}>
      <Stack w="100%">
        <AutoCompleteComboBox
          autoFocus
          leftElement={<Icon color={grayText} as={BiSearch} />}
          inputValue={debouncedQuery}
          onChange={({ inputValue, selectedItem }) => {
            handleSubmit(inputValue ?? '', selectedItem?.item_type);
            trackSelection(selectedItem);
          }}
          onChangeInputValue={(v) => setDebouncedQuery(v)}
          options={
            query.length > 0
              ? suggestions?.slice(0, 5) || []
              : recentSearches.map((search) => ({
                  text: typeof search === 'string' ? search : search.text,
                  highlight: typeof search === 'string' ? search : search.text,
                  item_type: search.item_type ?? '',
                  sector: [],
                }))
          }
          itemToString={(item) => item?.text ?? ''}
          placeholder="Search for an activity or a product..."
          isLoading={isLoading}
          renderOption={(item) => <SearchSuggestion item={item} isRecentSearch={query.length === 0} />}
          colorScheme="green"
          size={'lg'}
          bg={bg}
          color={textColor}
          {...rest}
        />
      </Stack>
      {includeButton && (
        <Button
          size="lg"
          rightIcon={<Icon as={BiSearch} />}
          onClick={() => onSearch(debouncedQuery, 'activity')}
          variant={btnVariant}
        >
          Search
        </Button>
      )}
    </Stack>
  );
};

export default AutoCompleteSearch;
