import React from 'react';

import { Stack } from '@ecoinvent/ui';

import ConsumingActivities from 'pages/ConsumingActivities';
import Documentation from 'pages/Documentation';
import Exchanges from 'pages/Exchanges';
import Export from 'pages/Export';
import DatasetHistory from 'pages/History';
import ImpactAssessment from 'pages/ImpactAssessment';
import LCI from 'pages/LCI';

type Props = {
  path: string;
  isLoading: boolean;
};

const renderSelectedTab = (path: string, isLoading?: boolean) => {
  switch (path) {
    case 'documentation':
      return <Documentation isLoading={isLoading} />;
    case 'exchanges':
      return <Exchanges isLoading={isLoading} />;
    case 'lci':
      return <LCI />;
    case 'impact_assessment':
      return <ImpactAssessment />;
    case 'export':
      return <Export isLoading={isLoading} />;
    case 'consuming_activities':
      return <ConsumingActivities />;
    case 'history':
      return <DatasetHistory />;
    default:
      break;
  }
};

const PrivateDatasetView = ({ path, isLoading }: Props) => {
  return (
    <Stack width={'100%'} spacing={5}>
      {renderSelectedTab(path, isLoading)}
    </Stack>
  );
};

export default PrivateDatasetView;
