import React from 'react';

import { TableContainer, Table, Thead, Tr, Th, Tbody, Skeleton } from '@ecoinvent/ui';

import NoResultCard from 'components/UI/NoResultCard';
import useLciaScores from 'hooks/data/datasets/lcia/useLciaScores';
import { RecentMethod } from 'hooks/useSavedLciaMethod/types';

import ImpactAssessmentRow from './ImpactAssessmentRow';

type Props = {
  isLoading?: boolean;
  spoldId: number;
  selectedMethod: RecentMethod;
};

const ImpactAssessmentTable = ({ isLoading, spoldId, selectedMethod }: Props) => {
  const { data: lciaScores, isLoading: areScoresLoading } = useLciaScores(spoldId, selectedMethod.method_id);

  const isAnythingLoading = isLoading || areScoresLoading;

  return (
    <Skeleton isLoaded={!isLoading}>
      <TableContainer whiteSpace={'break-spaces'}>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th pl={0}>Impact category</Th>
              <Th align="left">Indicator</Th>
              <Th isNumeric whiteSpace={'nowrap'}>
                Impact Score
              </Th>
              <Th align="left">Unit</Th>
              <Th align="left" />
            </Tr>
          </Thead>
          {!isAnythingLoading && lciaScores && lciaScores.length > 0 && (
            <Tbody>
              {lciaScores.map((row) => {
                return <ImpactAssessmentRow selectedMethod={selectedMethod} key={row.index} score={row} />;
              })}
            </Tbody>
          )}
          {!isLoading && lciaScores?.length === 0 && (
            <Tbody>
              <NoResultCard>No Results Found</NoResultCard>
            </Tbody>
          )}
        </Table>
      </TableContainer>
    </Skeleton>
  );
};

export default ImpactAssessmentTable;
