import { useState } from 'react';

import { Badge, useDisclosure, Text, Stack, HStack, Icon, useColorModeValue, Collapse, Button } from '@ecoinvent/ui';
import mixpanel from 'mixpanel-browser';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { useParams } from 'react-router-dom';

import CopyToClipboardButton from 'components/shared/CopyToClipboardButton';
import AppCard from 'components/UI/AppCard';
import { ElementaryExchange, IntermediateExchange } from 'hooks/data/datasets/exchanges/types';
import useVersionInfo from 'hooks/useVersionInfo';
import ExchangeDetails from 'pages/Exchanges/components/ExchangeView/ExchangeItem/ExchangeModalContent';
import { capitalizeFirstLetter } from 'utilities/helpers/formats';
import { formatNumericalValue } from 'utilities/numbers';

import { CombinedExchange } from '../types';

import ActivityLink from './ActivityLink';

type Props = {
  item: CombinedExchange;
  baseUrl?: string;
};

const renderExchangeGroup = (exchangeGroup: string) => {
  switch (exchangeGroup) {
    case 'Reference product':
      return <Badge colorScheme="red">Reference product</Badge>;
    case 'From technosphere':
      return <Badge colorScheme="blue">Technosphere</Badge>;
    case 'Waste to treatment':
      return <Badge colorScheme="orange">Waste</Badge>;
    case 'By-product (Allocatable)':
    case 'By-product (Recyclable)':
    case 'By-product (Waste)':
      return <Badge colorScheme="purple">{exchangeGroup}</Badge>;
    default:
      return <Badge colorScheme="gray">Elementary</Badge>;
  }
};

const ExchangeItem = ({ item, baseUrl = '' }: Props) => {
  const { isOpen, onToggle } = useDisclosure();
  const grayTextColor = useColorModeValue('gray.500', 'gray.400');

  const collapseBg = useColorModeValue('gray.100', 'gray.900');
  const [isHovered, setIsHovered] = useState(false);

  const itemAsIntermediateExchange = item as IntermediateExchange;
  const itemAsElementaryExchange = item as ElementaryExchange;

  const { ver: version, system_model } = useVersionInfo();
  const { spold_id } = useParams();

  return (
    <Stack spacing={1} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <Stack py={2}>
        <HStack
          justify={'space-between'}
          alignItems={{ base: 'flex-start', lg: 'center' }}
          flexDir={{ base: 'column', lg: 'row' }}
        >
          <Stack wordBreak={'break-word'}>
            <Stack direction={{ base: 'column', md: 'row' }} alignItems={{ base: 'flex-start', md: 'center' }}>
              <Text>{item.name}</Text>
              <Stack>{renderExchangeGroup(item['group'])}</Stack>
            </Stack>
            {itemAsIntermediateExchange?.link && baseUrl && (
              <ActivityLink baseUrl={baseUrl} link={itemAsIntermediateExchange?.link} />
            )}
            {itemAsElementaryExchange?.compartment && (
              <Text fontSize={'sm'} color={grayTextColor}>
                {capitalizeFirstLetter(`${itemAsElementaryExchange.compartment}, ${itemAsElementaryExchange.sub_compartment}`)}
              </Text>
            )}
          </Stack>
          <HStack spacing={5} alignItems={{ base: 'flex-start', lg: 'center' }} flexDir={{ base: 'column', lg: 'row' }}>
            <HStack>
              {item['amount'] && <Text color="text.secondary">{`${formatNumericalValue(item.amount)} ${item['unit']}`}</Text>}
              <CopyToClipboardButton
                value={item.amount}
                opacity={isHovered ? 1 : 0}
                onValueCopied={() => {
                  mixpanel.track('Copy Dataset Value', {
                    type: 'exchange',
                    group: item.group,
                    name: item.name,
                    version,
                    system_model,
                    spold_id,
                  });
                }}
              />
            </HStack>
            <Button
              variant={'link'}
              size="sm"
              _hover={{ textDecoration: 'none' }}
              rightIcon={<Icon as={isOpen ? BiChevronUp : BiChevronDown} />}
              onClick={onToggle}
              aria-label="open detail"
            >
              {isOpen ? 'Hide' : 'Show'} details
            </Button>
          </HStack>
        </HStack>
      </Stack>
      <Collapse unmountOnExit in={isOpen}>
        <Stack bg={collapseBg} p={6} rounded="md">
          <AppCard>
            <ExchangeDetails item={item as IntermediateExchange & { key: string }} />
          </AppCard>
        </Stack>
      </Collapse>
    </Stack>
  );
};

export default ExchangeItem;
