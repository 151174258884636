import { Alert, Link, Stack, Heading, AlertIcon, AlertDescription, Skeleton, Button, HStack, Text } from '@ecoinvent/ui';
import { useAuth } from 'react-oidc-context';

import PageHead from 'components/shared/PageHead';
import AppCard from 'components/UI/AppCard';
import { AccessResourceKey } from 'hooks/data/files/types';
import useFiles from 'hooks/data/files/useFiles';
import useAccess from 'hooks/useAccess';
import useVersionInfo from 'hooks/useVersionInfo';
import DocumentationMarkdownRenderer from 'pages/Documentation/components/DocumentationMarkdownRenderer';
import Footer from 'pages/Landing/components/Footer';
import { checkAccessToResource } from 'utilities/auth/access';

import SubscriptionAlert from '../../components/shared/SubscriptionAlert';

import loadingFiles from './loadingData';
import VersionFiles from './VersionFiles';

const Files = () => {
  const auth = useAuth();
  const { isLoading: areFilesLoading, data: files } = useFiles();
  const { data: accessData } = useAccess();
  const { ver } = useVersionInfo();

  const hasFileAccess = checkAccessToResource(accessData, AccessResourceKey.FILES, ver);
  const filesToRender = files ?? loadingFiles;

  const orderedFilesToRender = filesToRender.sort((a, b) =>
    b.version_name.localeCompare(a.version_name, undefined, { numeric: true, sensitivity: 'base' })
  );

  return (
    <Stack alignItems="center" maxW="container.xl" width="100%" spacing={7} py={5} flex={1} justifyContent="space-between">
      <PageHead title="Files" />
      <Stack w="100%" spacing={7} flex={1}>
        <Stack px={{ base: 4, md: 0 }} spacing={7}>
          <Heading as="h1" size="2xl">
            Files
          </Heading>
          <DocumentationMarkdownRenderer components={{ a: (props) => <Link {...props} /> }}>
            Here you can download the database in ecoSpold2 format and access several supporting files for different versions of
            the ecoinvent database (for more information on the files, see [our step-by-step
            guide](https://support.ecoinvent.org/getting-started#step-by-step-guide)). The data type `.spold` can be overwritten
            to `.xml`, and thus all the unit process (UPR), cumulative inventory (LCI), and results of the impact assessment
            (LCIA) of any activity can be viewed in any XML editor.
          </DocumentationMarkdownRenderer>
        </Stack>
        {!auth.isAuthenticated ? (
          <Alert status="info" variant={'left-accent'} borderRadius="md">
            <AlertIcon />
            <AlertDescription>
              To view and download files, please{' '}
              <Button
                variant={'link'}
                colorScheme="blue"
                onClick={() => auth.signinRedirect({ redirect_uri: window.location.href })}
              >
                sign in.
              </Button>
            </AlertDescription>
          </Alert>
        ) : !files?.length && !areFilesLoading ? (
          <Alert status="info" variant="left-accent" borderRadius="md">
            <AlertIcon /> {hasFileAccess ? 'No files available.' : 'Your licence doesn’t give you access to files.'}
          </Alert>
        ) : (
          <Stack spacing={6}>
            {accessData?.expiry_date && <SubscriptionAlert expiryDate={accessData.expiry_date} />}
            <HStack alignItems="flex-start" spacing={6}>
              <AppCard flexGrow={1} spacing={5} width={{ base: '100%', lg: 'auto' }}>
                {orderedFilesToRender?.map((version) => (
                  <Skeleton key={version.version_name} isLoaded={!areFilesLoading}>
                    <VersionFiles versionFiles={version} />
                  </Skeleton>
                ))}
              </AppCard>
              <Stack spacing={1} textAlign="right" top={4} position={'sticky'} display={{ base: 'none', lg: 'flex' }}>
                <Text fontWeight="semibold">Jump to</Text>
                {orderedFilesToRender.map((files, idx) => (
                  <Link key={idx} href={`#${files.version_name}`}>
                    {files.version_name}
                  </Link>
                ))}
              </Stack>
            </HStack>
          </Stack>
        )}
      </Stack>
      <Footer />
    </Stack>
  );
};

export default Files;
