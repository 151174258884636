import { Image } from '@ecoinvent/ui';
import { IconType } from 'react-icons';
import {
  IoLeafOutline,
  IoWaterOutline,
  IoFlaskOutline,
  IoFishOutline,
  IoConstructOutline,
  IoLaptopOutline,
} from 'react-icons/io5';

import NumOne from 'assets/landingNumbers/Num1.svg';
import NumTwo from 'assets/landingNumbers/Num2.svg';
import NumThree from 'assets/landingNumbers/Num3.svg';
import NumFour from 'assets/landingNumbers/Num4.svg';
import NumFive from 'assets/landingNumbers/Num5.svg';
import NumSix from 'assets/landingNumbers/Num6.svg';

export const getStartedGuideSteps = [
  {
    title: 'Step by Step Guide',
    text: 'Get started with 7 important steps to improve your work.',
    icon: <Image h="24px" w="34px" src={NumOne} />,
    link: 'https://support.ecoinvent.org/getting-started',
  },
  {
    title: 'E-learning Course',
    text: 'This course will help you understand how to use the database.',
    icon: <Image h="24px" w="34px" src={NumTwo} />,
    link: 'https://support.ecoinvent.org/resources#e-learning',
  },
  {
    title: 'Knowledge Base',
    text: 'Learn more about sectors and releases of datasets.',
    icon: <Image h="24px" w="34px" src={NumThree} />,
    link: 'https://support.ecoinvent.org/database',
  },
  {
    title: 'Glossary',
    text: 'Dive into alphabetical list of words related to specific subjects',
    icon: <Image h="24px" w="34px" src={NumFour} />,
    link: 'https://support.ecoinvent.org/glossary',
  },
  {
    title: 'FAQ',
    text: 'Find answers to the most common questions about the database.',
    icon: <Image h="24px" w="34px" src={NumFive} />,
    link: 'https://support.ecoinvent.org/faq',
  },
  {
    title: 'Webinars & Recordings',
    text: 'Watch our webinars around the ecoinvent database.',
    icon: <Image h="24px" w="34px" src={NumSix} />,
    link: 'https://support.ecoinvent.org/resources#webinars-and-recordings',
  },
];

export const products = [
  {
    name: 'Market for heat and power co-generation unit, 1MW electrical, common components for heat+electricity',
    refProduct: 'inorganic nitrogen fertiliser',
    geography: 'Albania (AL)',
    badgeText: 'New in 3.10',
  },
  {
    name: '1,1-difluoroethane production',
    refProduct: '1,1-difluoroethane',
    geography: 'Rest-of-World (RoW)',
    badgeText: 'New in 3.10',
  },
  { name: 'cheese production, soft, from cow milk', refProduct: 'whey', geography: 'Global (GLO)', badgeText: 'New in 3.10' },
  {
    name: 'market for copper, sorted, pressed',
    refProduct: 'copper scrap, sorted, pressed',
    geography: 'Global (GLO)',
    badgeText: 'Popular',
  },
  {
    name: 'yogurt production, from cow milk',
    refProduct: 'cheese, from cow milk, fresh, unripened',
    geography: 'Rest-of-World (RoW)',
    badgeText: 'Popular',
  },
  {
    name: 'tomato production, fresh grade, open field',
    refProduct: 'tomato, fresh grade',
    geography: 'Spain (ES)',
    badgeText: 'Popular',
  },
  { name: 'blast furnace production', refProduct: 'blast furnace', geography: 'Europe (RER)' },
  { name: 'kraft paper production', refProduct: 'kraft paper', geography: 'Europe (RER)' },
  {
    name: 'market for inorganic nitrogen fertiliser, as Nitrogen',
    refProduct: 'inorganic nitrogen fertiliser',
    geography: 'Global (GLO)',
  },
];

export const popularSearchesByVersions = {
  '3.11': ['natural gas', 'electricity high voltage', 'carbon monoxide', 'plastic recycled', 'cement mortar'],
  '3.10.1': ['chromium steel', 'electricity high voltage', 'municipal solid waste', 'sodium hydroxide', 'copper'],
  '3.10': ['chromium steel', 'electricity high voltage', 'municipal solid waste', 'sodium hydroxide', 'copper'],
  '3.9.1': ['aluminium', 'electricity high voltage', 'diesel production', 'battery production', 'concrete production'],
  '3.9': ['aluminium', 'electricity high voltage', 'diesel production', 'battery production', 'concrete production'],
};

export const buildPopularSearchesByVersion = (version: string) => {
  if (!popularSearchesByVersions[version as keyof typeof popularSearchesByVersions]) return [];
  return popularSearchesByVersions[version as keyof typeof popularSearchesByVersions];
};

export const knownSectorCategories: Record<string, { icon: IconType }> = {
  'Environmental Management': {
    icon: IoLeafOutline,
  },
  'Energy Supply': {
    icon: IoWaterOutline,
  },
  'Materials & Manufacturing': {
    icon: IoFlaskOutline,
  },
  'Farming & Natural Resources': {
    icon: IoFishOutline,
  },
  'Industrial Infrastructure': {
    icon: IoConstructOutline,
  },
  Technology: {
    icon: IoLaptopOutline,
  },
};
