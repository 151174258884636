import React, { useMemo } from 'react';

import { DetailList, Heading, Stack } from '@ecoinvent/ui';

import { IntermediateExchange } from 'hooks/data/datasets/exchanges/types';

type Props = { data: IntermediateExchange['classification'] };

const Classification = ({ data }: Props) => {
  const intermediateExchangeKeys = Object.keys(data) as Array<keyof IntermediateExchange['classification']>;

  const config = useMemo(() => {
    return intermediateExchangeKeys.map((item) => ({
      key: item,
      label: item,
    }));
  }, [intermediateExchangeKeys]);

  return (
    <Stack spacing={3}>
      <Heading as={'h4'} size="sm">
        Classification
      </Heading>
      <DetailList config={config} data={data} labelProps={{ minW: '180px', alignSelf: 'flex-start' }} />
    </Stack>
  );
};

export default Classification;
