import { Alert, Link, HStack, Stack, AlertTitle, AlertDescription, Button, useColorModeValue, LightMode } from '@ecoinvent/ui';
import { IoLeaf } from 'react-icons/io5';

import useVersionInfo from 'hooks/useVersionInfo';
import { appConfig } from 'utilities/env';

const UpgradeNowUpsellDataset = () => {
  const { ver } = useVersionInfo();
  const upgradeUrl = appConfig.accountsUrl;
  const gradientColorLeft = useColorModeValue('#D8F5E6', '#045D3B');
  const gradientColorRight = useColorModeValue('#B0EACD', '#2EA266');

  return (
    <Alert
      variant="subtle"
      status="success"
      borderRadius={'md'}
      bgGradient={`linear(to-r, ${gradientColorLeft}, ${gradientColorRight})`}
    >
      <HStack w="100%" justifyContent="space-between" flexDirection={{ base: 'column', xl: 'row' }}>
        <HStack spacing={4}>
          <IoLeaf />
          <Stack spacing={0}>
            <AlertTitle>Get full access to this dataset</AlertTitle>
            <AlertDescription>
              View impact scores, LCI results and exchanges with an ecoinvent licence that covers {ver}.
            </AlertDescription>
          </Stack>
        </HStack>
        <LightMode>
          <Button variant="subtle" as={Link} href={upgradeUrl}>
            Upgrade Now
          </Button>
        </LightMode>
      </HStack>
    </Alert>
  );
};

export default UpgradeNowUpsellDataset;
