import React, { useState } from 'react';

import { Button, Skeleton, Stack, StackDivider, Text, useColorModeValue } from '@ecoinvent/ui';

import ExchangeItem from './ExchangeItem';
import { CombinedExchange } from './types';

type Props = {
  exchanges: CombinedExchange[];
  baseUrl: string;
  isLoading?: boolean;
};

const ExchangeList = ({ exchanges, baseUrl, isLoading }: Props) => {
  const [maxItems, setMaxItems] = useState(25);
  const grayTextColor = useColorModeValue('gray.500', 'gray.400');
  return (
    <Stack spacing={2} py={2} divider={<StackDivider />}>
      {exchanges.length > 0 ? (
        exchanges.slice(0, maxItems).map((exc) => <ExchangeItem key={exc.key} item={exc} baseUrl={baseUrl} />)
      ) : (
        <Skeleton isLoaded={!isLoading}>
          <Stack py={5}>
            <Text color={grayTextColor}>There's nothing here.</Text>
          </Stack>
        </Skeleton>
      )}
      {exchanges.length > maxItems && (
        <Button
          alignSelf={'center'}
          variant={'ghost'}
          onClick={() => {
            setMaxItems(maxItems * 10);
          }}
        >
          Load more
        </Button>
      )}
    </Stack>
  );
};

export default ExchangeList;
