import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import api from 'api/api';
import useVersionInfo from 'hooks/useVersionInfo';
import { mapFeaturedProductsToList } from 'utilities/featured';

import { FeaturedSearchResponse, FeaturedSearchParams, FeaturedSearchBody, FeaturedProductWithType } from './types';

const fetchFeaturedDatasets = async (params: FeaturedSearchParams) => {
  const response = await api.post<FeaturedSearchResponse>(`/search/${params.ver}/${params.system_model}/featured`, params);
  return response.data;
};

const useFeaturedDatasets = (params: FeaturedSearchBody) => {
  const { ver, system_model } = useVersionInfo();
  return useQuery<FeaturedSearchResponse, AxiosError, FeaturedProductWithType[]>(
    ['search', ver, system_model, params],
    () => fetchFeaturedDatasets({ ver, system_model, ...params }),
    {
      select: mapFeaturedProductsToList,
      staleTime: Infinity,
    }
  );
};

export default useFeaturedDatasets;
