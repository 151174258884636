import React, { ChangeEvent, useMemo, useState } from 'react';

import {
  Stack,
  InputGroup,
  Input,
  Icon,
  Heading,
  Skeleton,
  Text,
  HStack,
  Wrap,
  Button,
  Pagination,
  MultipleComboBox,
  InputLeftAddon,
} from '@ecoinvent/ui';
import { BiSearch } from 'react-icons/bi';
import { useParams } from 'react-router-dom';

import AppCard from 'components/UI/AppCard';
import { LCIResult } from 'hooks/data/datasets/lci/types';
import useLciResults from 'hooks/data/datasets/lci/useLciResults';
import usePagination from 'hooks/usePagination';

import LciResultTable from './LciResultTable';
import RemoveableBadge from './RemoveableBadge';
import { buildCompartmentKey, isValidCompartment } from './utils';

const LCI = () => {
  const [filterString, setFilterString] = useState('');
  const [filteredCompartments, setFilteredCompartments] = useState<string[]>([]);

  const { currentPage, setCurrentPage, pageSize, totalItems, setTotalItems } = usePagination({
    initialState: {
      currentPage: 1,
      pageSize: 100,
      totalItems: 0,
    },
  });

  const indexOfLastItem = currentPage * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;

  const { spold_id } = useParams();
  const { data, isLoading } = useLciResults(spold_id);

  const exchanges: LCIResult[] = useMemo(() => data?.lci_results ?? [], [data]);

  const onFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1);
    setFilterString(e.target.value);
  };

  const compartments = useMemo(() => Array.from(new Set(exchanges.map(buildCompartmentKey))), [exchanges]);

  const filtered_exchanges = useMemo(() => {
    const computedItems = exchanges
      .filter((item) => item.name.toLowerCase().includes(filterString.toLowerCase()))
      .filter((item) => isValidCompartment(item, filteredCompartments))
      .map((item) => {
        const newName = item.name.replace(
          new RegExp(filterString, 'gi'),
          (match) => `<strong style="color:var(--chakra-colors-green-600);">${match}</strong>`
        );
        const lastName = <div dangerouslySetInnerHTML={{ __html: newName }} />;
        return {
          ...item,
          name: lastName,
          originalName: item.name,
        };
      });
    setTotalItems(computedItems.length);
    return computedItems.slice(indexOfFirstItem, indexOfLastItem);
  }, [filterString, filteredCompartments, exchanges, indexOfFirstItem, setTotalItems, indexOfLastItem]);

  return (
    <AppCard>
      <Stack>
        <Skeleton alignSelf={'flex-start'} isLoaded={!isLoading}>
          <Heading as={'h4'} size="md">
            LCI results
          </Heading>
        </Skeleton>
        <Skeleton alignSelf={'flex-start'} isLoaded={!isLoading}>
          <Text>A list of the resources used and emissions generated throughout a product's entire life cycle.</Text>
        </Skeleton>
      </Stack>
      <Stack spacing={3}>
        {filteredCompartments.length > 0 && (
          <Stack>
            <HStack justify={'space-between'}>
              <Text fontSize={'sm'} fontWeight={'medium'}>
                Selected Compartments
              </Text>
              <Button variant={'link'} size="sm" onClick={() => setFilteredCompartments([])}>
                Clear all
              </Button>
            </HStack>

            <Wrap>
              {filteredCompartments.map((selectedItemForRender) => {
                return (
                  <RemoveableBadge
                    key={selectedItemForRender}
                    name={selectedItemForRender}
                    onRemove={(name) => {
                      const newItems = filteredCompartments.filter((item) => item !== name);
                      setFilteredCompartments(newItems);
                    }}
                  />
                );
              })}
            </Wrap>
          </Stack>
        )}
        <Skeleton isLoaded={!isLoading}>
          <Stack direction={{ base: 'column-reverse', lg: 'row' }} spacing={2} w="100%">
            <InputGroup w={{ base: '100%' }} alignItems={{ base: 'stretch', lg: 'flex-end' }}>
              <InputLeftAddon borderStartRadius={'md'}>
                <Icon as={BiSearch} />
              </InputLeftAddon>
              <Input borderRadius={'md'} placeholder="Filter by exchange name" value={filterString} onChange={onFilterChange} />
            </InputGroup>

            <MultipleComboBox
              itemToString={(item) => item ?? ''}
              selectedItems={filteredCompartments}
              placeholder={'Filter by compartment'}
              showSelectedItems={false}
              isDisabled={!compartments.length}
              filterMenuOptions={(options, selectedItems, inputValue) =>
                options.filter(
                  (option) =>
                    selectedItems.indexOf(option) === -1 && option.toLowerCase().includes(inputValue?.toLowerCase() ?? '')
                )
              }
              onChange={(selectedItems) => {
                setCurrentPage(1);

                if (!selectedItems) {
                  setFilteredCompartments([]);
                  return;
                }

                setFilteredCompartments([...selectedItems]);
              }}
              options={compartments.sort()}
              menuProps={{ maxHeight: '400px' }}
              colorScheme="green"
              width={{ base: '100%', lg: 'auto' }}
            />
          </Stack>
        </Skeleton>
      </Stack>
      <Stack spacing={5} width={'100%'}>
        <Skeleton isLoaded={!isLoading}>
          <LciResultTable exchanges={filtered_exchanges} />
        </Skeleton>
        <Skeleton isLoaded={!isLoading} alignSelf={'flex-end'}>
          <Stack alignItems={'flex-end'}>
            <Pagination
              itemsPerPage={pageSize}
              totalHits={totalItems}
              onPageChange={(v) => setCurrentPage(v)}
              currentPage={currentPage}
            />
          </Stack>
        </Skeleton>
      </Stack>
    </AppCard>
  );
};

export default LCI;
