import React from 'react';

import { LightMode, Stack, Heading, HStack, Icon, Badge, Link, Image, Text, useColorModeValue } from '@ecoinvent/ui';
import mixpanel from 'mixpanel-browser';
import { FiArrowRight } from 'react-icons/fi';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import logo from 'assets/ecoQueryLogo.svg';
import logoWhite from 'assets/ecoQueryWhiteLogo.svg';
import AppCard from 'components/UI/AppCard';
import useVersionInfo from 'hooks/useVersionInfo';
import { buildPopularSearchesByVersion } from 'pages/Landing/content';
import ActivitySearchBar from 'pages/Search/components/ActivitySearch/ActivitySearchBar';

const Hero = () => {
  const { ver, system_model } = useVersionInfo();

  const navigate = useNavigate();

  const onSearch = (value: string, item_type?: string) => {
    if (!value) return;
    navigate(`/${ver}/${system_model}/search?query=${value}&searchBy=${item_type !== 'cas_number' ? item_type : 'activity'}`);
    value && mixpanel.track('Search', { query: value });
  };

  const logoSrc = useColorModeValue(logo, logoWhite);
  const linkColor = useColorModeValue('green.700', 'white');

  const buttonVariant = useColorModeValue('solid', 'subtle');

  const bgGradient = useColorModeValue(
    'linear(263deg, #D8F5E6 2.45%, #D7F4FF 50.11%, #D9DEF8 97.77%)',
    'linear(83deg, #101B56 2.22%, #024D52 49.32%, #14462C 96.42%)'
  );

  const popularSearches = buildPopularSearchesByVersion(ver);

  return (
    <AppCard
      width="100%"
      alignSelf={'center'}
      spacing={7}
      bgGradient={bgGradient}
      justifyContent="space-between"
      px={{ base: 4, md: 8, xl: 20 }}
      py={{ base: 8, md: 16, xl: 32 }}
      alignItems="center"
      position="relative"
      border={'none'}
    >
      <Image src={logoSrc} alt="ecoQuery Logo" width="72px" />
      <Stack alignItems="center" textAlign={'center'}>
        <Heading size={'2xl'}>The world's best environmental database</Heading>
        <Text fontSize={{ base: 'md', md: 'xl' }}>Over 25,000 reliable datasets modeling real-world processes</Text>
      </Stack>
      <Stack w="100%" maxW={'container.md'}>
        <LightMode>
          <ActivitySearchBar onSearch={onSearch} btnVariant={buttonVariant} />
        </LightMode>

        <HStack alignItems="center" alignSelf={{ base: 'center', md: 'flex-start' }}>
          <Link as={RouterLink} color={linkColor} to={`/${ver}/${system_model}/search`}>
            Advanced Search
          </Link>
          <Icon as={FiArrowRight} color={linkColor} />
        </HStack>
      </Stack>

      {popularSearches.length > 0 && (
        <HStack flexWrap="wrap">
          <Text>Popular:</Text>
          {popularSearches.map((search) => (
            <Link
              as={RouterLink}
              key={search}
              to={`/${ver}/${system_model}/search?query=${search}&searchBy=product`}
              onClick={() => mixpanel.track('Popular Search', { query: search, version: ver, system_model })}
            >
              <Badge
                borderRadius="md"
                _hover={{ bg: 'green.600', color: 'white' }}
                key={search}
                colorScheme="green"
                variant="solid"
                px={2}
                textTransform="none"
                fontWeight="500"
              >
                {search}
              </Badge>
            </Link>
          ))}
        </HStack>
      )}
    </AppCard>
  );
};

export default Hero;
