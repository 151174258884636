import { useCallback, useMemo, useState } from 'react';

import { defaultLciaMethod, loadRecentMethods, loadSelectedMethod, saveRecentMethods } from 'utilities/lcia/utils';

import { LciaMethod } from '../data/datasets/lcia/types';
import useVersionInfo from '../useVersionInfo';

import { RecentMethod, RecentMethodMap } from './types';

const getLastSelectedMethod = (
  recentMethods: RecentMethodMap,
  ver: string,
  system_model: string,
  methodData?: LciaMethod[]
) => {
  if (!methodData || !methodData.length) return;

  const allMethods = methodData.map((m) => m.method_id);

  const mostRecentlySelected = recentMethods[ver];
  if (mostRecentlySelected && allMethods.includes(mostRecentlySelected.method_id)) {
    return mostRecentlySelected;
  }

  const lastSelectedForRelease = recentMethods[`${ver}${system_model}`];
  if (lastSelectedForRelease && allMethods.includes(lastSelectedForRelease.method_id)) {
    return lastSelectedForRelease;
  }

  const defaultSelectedMethod = loadSelectedMethod(ver, system_model);
  const lciaMethod = defaultLciaMethod(ver);

  return !!defaultSelectedMethod
    ? methodData.filter((method) => method.method_name === defaultSelectedMethod.lciaMethod)[0]
    : methodData.filter((method) => method.method_name === lciaMethod)[0];
};

const useSavedLciaMethod = (methodList?: LciaMethod[]) => {
  const { ver, system_model } = useVersionInfo();
  const [recentMethods, setRecentMethods] = useState<RecentMethodMap>(loadRecentMethods());

  const addRecentMethod = useCallback(
    (method: RecentMethod) => {
      const newMethods = { ...recentMethods, [ver]: method, [`${ver}${system_model}`]: method };
      setRecentMethods(newMethods);
      saveRecentMethods(newMethods);
    },
    [ver, system_model, recentMethods]
  );

  const selectedMethod = useMemo(() => {
    return methodList?.length ? getLastSelectedMethod(recentMethods, ver, system_model, methodList) : undefined;
  }, [methodList, recentMethods, ver, system_model]);

  return { selectedMethod, setSelectedMethod: addRecentMethod };
};

export default useSavedLciaMethod;
