import React from 'react';

import { Stack, Heading, DetailList, DetailListConfig, Box } from '@ecoinvent/ui';

import { IntermediateExchange } from 'hooks/data/datasets/exchanges/types';
import { formatNumericalValue } from 'utilities/numbers';

type Props = { item: IntermediateExchange };

const productionVolumeConfig: DetailListConfig<IntermediateExchange> = [
  {
    key: 'productionVolumeAmount',
    label: 'Amount',
    shouldRender: (data) => !!data,
    renderItem(value) {
      return <Box as="span">{formatNumericalValue(value)}</Box>;
    },
  },
  {
    key: 'productionVolumeComment',
    label: 'Comment',
    shouldRender: (data) => !!data,
  },
];

const ProductVolume = ({ item }: Props) => {
  return (
    <Stack spacing={3}>
      <Heading as={'h4'} size="sm">
        Production Volume
      </Heading>
      <DetailList
        labelProps={{ minW: '180px', alignSelf: 'flex-start' }}
        rowProps={{ wordBreak: 'break-word' }}
        config={productionVolumeConfig}
        data={item}
      />
    </Stack>
  );
};

export default ProductVolume;
