import { Heading, Skeleton, Stack, Table, Tbody, Text } from '@ecoinvent/ui';
import { useParams } from 'react-router-dom';

import AppCard from 'components/UI/AppCard';
import NoResultCard from 'components/UI/NoResultCard';
import useExchanges from 'hooks/data/datasets/exchanges/useExchanges';
import ExchangeView from 'pages/Exchanges/components/ExchangeView';

type Props = {
  isLoading?: boolean;
};

const Exchanges = ({ isLoading }: Props) => {
  const { spold_id } = useParams();

  const { isLoading: areExchangesLoading, data } = useExchanges(spold_id);

  const isAnythingLoading = isLoading || areExchangesLoading;

  const intermediateExchange = data?.intermediateExchange ?? [];
  const elementaryExchange = data?.elementaryExchange ?? [];

  return (
    <AppCard>
      <Stack>
        <Skeleton alignSelf={'flex-start'} isLoaded={!isAnythingLoading}>
          <Heading as={'h4'} fontSize={'xl'}>
            Exchanges
          </Heading>
        </Skeleton>
        <Skeleton alignSelf={'flex-start'} isLoaded={!isAnythingLoading}>
          <Text>
            Exchanges are amounts of a material, energy, or a substance that are transferred between two activities or from one
            activity to the environment.
          </Text>
        </Skeleton>
      </Stack>
      {!isAnythingLoading && intermediateExchange.length == 0 && elementaryExchange.length == 0 ? (
        <Table>
          <Tbody>
            <NoResultCard>No Results Found</NoResultCard>
          </Tbody>
        </Table>
      ) : (
        <ExchangeView
          isLoading={isAnythingLoading}
          intermediateExchange={intermediateExchange}
          elementaryExchange={elementaryExchange}
        />
      )}
    </AppCard>
  );
};

export default Exchanges;
