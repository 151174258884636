import React from 'react';

import {
  Stack,
  Text,
  Link,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Button,
  Icon,
  useColorModeValue,
  Divider,
  Heading,
} from '@ecoinvent/ui';
import { capitalize } from 'lodash';
import mixpanel from 'mixpanel-browser';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { FiArrowRight } from 'react-icons/fi';
import { useParams } from 'react-router-dom';

import { DatasetHistory } from 'hooks/data/datasets/types';
import useVersionInfo from 'hooks/useVersionInfo';

type Props = {
  datasetHistory: DatasetHistory;
  version: string;
  index: number;
};

type TrackingParams = {
  targetVersion: string;
  targetSystemModel: string;
  targetSpoldId: number;
  targetName: string;
};

const DatasetHistoryTimelineRow = ({ datasetHistory, version, index }: Props) => {
  const { spold_id } = useParams();
  const { ver, system_model } = useVersionInfo();
  const [isOpen, setIsOpen] = React.useState(false);

  const bg = useColorModeValue('gray.100', 'gray.900');
  const tablesBg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('transparent', 'whiteAlpha.200');
  const datasetHistoryVersion = Object.keys(datasetHistory);

  const trackDatasetHistoryNavigation = (params: TrackingParams) => {
    mixpanel.track('Dataset history used', {
      version: ver,
      system_model,
      spold_id,
      ...params,
    });
  };

  return (
    <Stack>
      <Accordion allowToggle>
        <AccordionItem border="none">
          <Stack direction="row">
            <Stack p={2} spacing={10} bg={ver === version ? 'green.500' : ''} borderRadius="md" justifyContent="center">
              <Heading size="md" fontWeight="bold" color={ver === version ? 'white' : 'auto'}>
                {version}
              </Heading>
            </Stack>
            <Stack direction="column" spacing={0}>
              <Text fontWeight="bold" fontSize="lg">
                {capitalize(datasetHistory[version].status_short)}
              </Text>
              <Text>{datasetHistory[version].status_long}</Text>
            </Stack>
            <Stack justifyContent="center" ml="auto">
              {datasetHistory[version].to?.length > 1 || datasetHistory[version].from_?.length > 1 ? (
                <AccordionButton _hover={{ bg: 'none' }} p={0} onClick={() => setIsOpen(!isOpen)}>
                  <Button variant="link">
                    Show Details <Icon as={isOpen ? BiChevronUp : BiChevronDown} color="green" ml={1} />
                  </Button>
                </AccordionButton>
              ) : ver === version ? null : (
                <Link
                  href={`/${version}/${system_model}/dataset/${datasetHistory[version].to[0].id}`}
                  onClick={() =>
                    trackDatasetHistoryNavigation({
                      targetVersion: version,
                      targetSystemModel: system_model,
                      targetSpoldId: datasetHistory[version].to[0].id,
                      targetName: datasetHistory[version].to[0].activity,
                    })
                  }
                >
                  View this version
                  <Icon as={FiArrowRight} color="green" ml={1} />
                </Link>
              )}
            </Stack>
          </Stack>
          <Stack flexDirection="column">
            <AccordionPanel display={'flex'} flex="1" p={8} bg={bg} borderRadius="md" mt={4}>
              <Stack w="100%">
                <Stack spacing={0} bg={tablesBg} p={8} borderRadius="md" border="1px solid" borderColor={borderColor}>
                  <Stack spacing={5}>
                    <Stack spacing={2}>
                      <Heading fontWeight="bold" fontSize="md">
                        Resulting Dataset(s)
                      </Heading>
                      {datasetHistory[version].to.map((resulting, idx) => (
                        <Link
                          key={idx}
                          href={`/${version}/${system_model}/dataset/${resulting.id}`}
                          onClick={() =>
                            trackDatasetHistoryNavigation({
                              targetVersion: version,
                              targetSystemModel: system_model,
                              targetSpoldId: resulting.id,
                              targetName: resulting.activity,
                            })
                          }
                        >
                          <Text>
                            {resulting.activity}, {resulting.product}, {resulting.geography}
                          </Text>
                        </Link>
                      ))}
                    </Stack>
                    {datasetHistory[version].from_ !== null && (
                      <Stack spacing={2}>
                        <Heading fontWeight="bold" fontSize="md">
                          Source Dataset(s)
                        </Heading>
                        {datasetHistory[version].from_.map((from, idx) => (
                          <Link
                            key={idx}
                            href={`/${datasetHistory[version].predecessor}/${system_model}/dataset/${from.id}`}
                            onClick={() =>
                              trackDatasetHistoryNavigation({
                                targetVersion: version,
                                targetSystemModel: system_model,
                                targetSpoldId: from.id,
                                targetName: from.activity,
                              })
                            }
                          >
                            <Text>
                              {from.activity}, {from.product}, {from.geography}
                            </Text>
                          </Link>
                        ))}
                      </Stack>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </AccordionPanel>
          </Stack>
          <Stack height="35px" pl={6} mt={2}>
            {datasetHistory[version].from_ !== null && (
              <Divider orientation="vertical" borderColor="grey.500" borderLeftWidth="2px" />
            )}
          </Stack>
        </AccordionItem>
      </Accordion>
      {datasetHistoryVersion.length - 1 === index && datasetHistory[version].from_ !== null ? (
        <Accordion allowToggle>
          <AccordionItem border="none" width="100%">
            <Stack direction="row">
              <Stack
                p={2}
                spacing={10}
                bg={ver === datasetHistory[version].predecessor ? 'green.500' : ''}
                borderRadius="md"
                justifyContent="center"
              >
                <Heading fontWeight="bold" size="md" color={ver === datasetHistory[version].predecessor ? 'white' : 'auto'}>
                  {datasetHistory[version].predecessor}
                </Heading>
              </Stack>
              <Stack direction="column" spacing={0}>
                <Text fontWeight="bold" fontSize="lg">
                  Dataset available
                </Text>
                <Text>Dataset available in {datasetHistory[version].predecessor}</Text>
              </Stack>
              <Stack justifyContent="center" ml="auto">
                {datasetHistory[version].from_?.length > 1 ? (
                  <AccordionButton _hover={{ bg: 'none' }} p={0}>
                    <Button variant="link">
                      Show Details <Icon as={BiChevronDown} color="green" ml={1} />
                    </Button>
                  </AccordionButton>
                ) : (
                  <Link
                    href={`/${datasetHistory[version].predecessor}/${system_model}/dataset/${datasetHistory[version].from_[0].id}`}
                    onClick={() =>
                      trackDatasetHistoryNavigation({
                        targetVersion: datasetHistory[version].predecessor,
                        targetSystemModel: system_model,
                        targetSpoldId: datasetHistory[version].from_[0].id,
                        targetName: datasetHistory[version].from_[0].activity,
                      })
                    }
                  >
                    View this version
                    <Icon as={FiArrowRight} color="green" ml={1} />
                  </Link>
                )}
              </Stack>
            </Stack>
            <Stack direction="row" w="100%" alignItems="center">
              <AccordionPanel p={8} bg={bg} borderRadius="md" mt={4} w="100%">
                <Stack spacing={8}>
                  <Stack spacing={0} bg={tablesBg} p={8} borderRadius="md" border="1px solid" borderColor={borderColor}>
                    <Stack spacing={5}>
                      <Stack spacing={2}>
                        <Heading fontWeight="bold" fontSize="md">
                          Source Dataset(s)
                        </Heading>
                        {datasetHistory[version].from_.map((from, idx) => (
                          <Link
                            key={idx}
                            href={`/${datasetHistory[version].predecessor}/${system_model}/dataset/${from.id}`}
                            onClick={() =>
                              trackDatasetHistoryNavigation({
                                targetVersion: datasetHistory[version].predecessor,
                                targetSystemModel: system_model,
                                targetSpoldId: from.id,
                                targetName: from.activity,
                              })
                            }
                          >
                            <Text>
                              {from.activity}, {from.product}, {from.geography}
                            </Text>
                          </Link>
                        ))}
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </AccordionPanel>
            </Stack>
          </AccordionItem>
        </Accordion>
      ) : null}
    </Stack>
  );
};

export default DatasetHistoryTimelineRow;
