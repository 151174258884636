import { Stack, Button, Menu, MenuItem, MenuList, Icon, useColorMode, useColorModeValue, IconButton } from '@ecoinvent/ui';
import mixpanel from 'mixpanel-browser';
import { BiSolidUserCircle } from 'react-icons/bi';
import { FiLogIn, FiLogOut, FiMoon, FiSun } from 'react-icons/fi';
import { useAuth } from 'react-oidc-context';
import { Link } from 'react-router-dom';

import { appConfig } from 'utilities/env';

import UserMenuButton from './UserMenuButton';

const UserMenu = () => {
  const auth = useAuth();
  const menuId = 'primary-search-account-menu';

  const { colorMode, toggleColorMode } = useColorMode();

  const iconColor = useColorModeValue('gray.500', 'gray.400');

  return (
    <Stack>
      {auth.isAuthenticated ? (
        <Menu id={menuId}>
          <Stack direction={'row'} alignItems={'center'}>
            <UserMenuButton
              id={menuId}
              isLoading={auth.isLoading}
              name={auth.user?.profile.name}
              email={auth.user?.profile.email}
              imageSrc={auth.user?.profile.picture}
            />
            <MenuList>
              <MenuItem
                onClick={toggleColorMode}
                icon={<Icon color={iconColor} fontSize={'lg'} as={colorMode === 'dark' ? FiSun : FiMoon} />}
              >
                Switch to {colorMode === 'dark' ? 'light' : 'dark'} mode
              </MenuItem>
              <MenuItem
                icon={<Icon color={'GrayText'} fontSize={'lg'} as={BiSolidUserCircle} />}
                as={Link}
                to={appConfig.accountsUrl}
              >
                Manage Account
              </MenuItem>
              <MenuItem
                icon={<Icon color={iconColor} fontSize={'lg'} as={FiLogOut} />}
                onClick={() => {
                  auth.signoutRedirect({ post_logout_redirect_uri: window.location.href });
                }}
              >
                Logout
              </MenuItem>
            </MenuList>
          </Stack>
        </Menu>
      ) : (
        <Stack>
          <IconButton
            variant={'ghost'}
            onClick={toggleColorMode}
            icon={<Icon as={colorMode === 'light' ? FiMoon : FiSun} />}
            aria-label={'toggle color mode'}
          />
          <Button
            size="sm"
            leftIcon={<Icon as={FiLogIn} />}
            onClick={() => {
              mixpanel.track('Login (Header)');
              auth.signinRedirect({ redirect_uri: window.location.href });
            }}
          >
            Log in
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default UserMenu;
