import { InMemoryWebStorage, User, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';

import { appConfig } from 'utilities/env';

const oidcConfig: UserManagerSettings = {
  authority: appConfig.kcAuthority,
  client_id: 'apollo-ui',
  redirect_uri: appConfig.kcRedirectUri,
  scope: 'openid email profile offline_access',
  automaticSilentRenew: true,
  loadUserInfo: true,
  monitorSession: true,
  userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
};

export const userManagerInstance = new UserManager(oidcConfig);

// The void | User union here is a requirement for `react-oidc-context`, we'd probs prefer to use `User | null` instead
export const onSigninCallback = (user: void | User) => {
  const params = new URLSearchParams(window.location.search);
  params.delete('code');
  params.delete('state');
  params.delete('session_state');
  params.delete('iss');

  window.history.replaceState({}, document.title, `${window.location.pathname}?${params}`);

  if (!user) return;

  // Redirect to the upgrade page if the user hasn't completed UMS registration
  // we should do this after mixpanel otherwise we'll never identify the user
  if (user.profile?.ums_registration != true) {
    window.location.assign(`${appConfig.accountsUrl}/upgrade`);
  }
};

export default oidcConfig;
