import React from 'react';

import { Icon, Input, InputGroup, InputLeftAddon, InputProps } from '@ecoinvent/ui';
import { BiSearch } from 'react-icons/bi';

const ExchangeInput = ({ value, placeholder, ...rest }: InputProps) => {
  return (
    <InputGroup w="100%">
      <InputLeftAddon>
        <Icon as={BiSearch} />
      </InputLeftAddon>
      <Input borderRadius={'md'} type={'search'} placeholder={placeholder} value={value} {...rest} />
    </InputGroup>
  );
};

export default ExchangeInput;
