import { Box, Icon, Stack, Table, TableContainer, Tbody, Th, Thead, Tooltip, Tr, useColorModeValue, Text } from '@ecoinvent/ui';
import { FiInfo } from 'react-icons/fi';

import useAllIndicators from 'hooks/data/datasets/lcia/useAllIndicators';
import { AccessResourceKey } from 'hooks/data/files/types';
import { Activity } from 'hooks/data/search/types';
import useAccess from 'hooks/useAccess';
import useVersionInfo from 'hooks/useVersionInfo';
import { newerVersions } from 'pages/Search/utils';
import { checkAccessToResource } from 'utilities/auth/access';

import { buildDefaultSelectedLciaMethod, selectedMethodToString } from '../../SearchPreferencesModalContent/utils';

import DatasetRowGroup from './DatasetRowGroup';

type Props = {
  activity: Activity;
};

const ActivitySearchResultTable = ({ activity }: Props) => {
  const { ver, system_model } = useVersionInfo();

  const { data: accessData } = useAccess();
  const hasScoresAccess = checkAccessToResource(accessData, AccessResourceKey.SCORES, ver);

  const { data: indicators } = useAllIndicators();
  const selectedMethod = indicators && buildDefaultSelectedLciaMethod(indicators, ver, system_model);

  const thGray = useColorModeValue('gray.600', 'gray.400');
  const borderColor = useColorModeValue('gray.100', 'gray.700');
  const iconColor = useColorModeValue('blue.500', 'blue.300');

  return (
    <TableContainer whiteSpace={'auto'}>
      <Table variant="simple" fontSize={'md'} size="sm">
        <Thead borderColor={borderColor}>
          <Tr>
            {system_model !== 'undefined' && (
              <Th borderColor={borderColor} color={thGray} maxW={{ base: '100%', xl: '400px' }} py={2}>
                Reference Product
              </Th>
            )}
            <Th borderColor={borderColor} color={thGray}>
              Geography
            </Th>
            {hasScoresAccess && system_model !== 'undefined' && newerVersions.includes(ver) && (
              <Th justifyContent={'flex-end'} borderColor={borderColor} color={thGray} whiteSpace={'nowrap'} isNumeric pr={14}>
                <Text as="span">Impact Score</Text>
                <Tooltip
                  hasArrow
                  bg="gray.900"
                  py={2}
                  pointerEvents={'all'}
                  borderRadius="md"
                  width="100%"
                  label={
                    <Stack justifyContent="center" textAlign="left" spacing={2}>
                      <Text color="white">
                        The Life Cycle Impact Assessment (LCIA) score corresponding to a chosen methodology, impact category and
                        indicator for each dataset.
                      </Text>
                      <Text color="white">
                        Currently selected LCIA score source:{' '}
                        <Text as="b">{selectedMethod && selectedMethodToString(selectedMethod)}</Text>.
                      </Text>
                      <Text color="white">
                        You can change the source of the score via the ‘Preferences’ control on this page.
                      </Text>
                    </Stack>
                  }
                >
                  <Box p={0} verticalAlign={'middle'} ml={2} display={'inline-block'}>
                    <Icon mt="2px" as={FiInfo} color={iconColor} />
                  </Box>
                </Tooltip>
              </Th>
            )}
            <Th w={'150px'} borderColor={borderColor}>
              Dataset
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {activity.products.map((product, index) => (
            <DatasetRowGroup referenceProduct={product} key={index} isLastGroup={index === activity.products.length - 1} />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default ActivitySearchResultTable;
