import { FeaturedSearchResponse, FeaturedType } from 'hooks/data/search/types';

export const mapFeaturedProductsToList = (data: FeaturedSearchResponse) => {
  const newDatasets = data.new.slice(0, 3).map((item) => ({ ...item, type: FeaturedType.NEW }));

  // Filter out datasets that are already in the new datasets
  const popularDatasets = data.popular
    .filter((item) => !newDatasets.some((newItem) => newItem.dataset_id === item.dataset_id))
    .slice(0, 3)
    .map((item) => ({ ...item, type: FeaturedType.POPULAR }));

  // Filter out datasets that are already in the new or popular datasets and limit to 9
  const randomDatasets = data.random
    .filter(
      (item) =>
        !newDatasets.some((newItem) => newItem.dataset_id === item.dataset_id) &&
        !popularDatasets.some((popularItem) => popularItem.dataset_id === item.dataset_id)
    )
    .slice(0, 9 - newDatasets.length - popularDatasets.length)
    .map((item) => ({ ...item, type: FeaturedType.RANDOM }));

  return [...newDatasets, ...popularDatasets, ...randomDatasets];
};
