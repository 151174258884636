import { Stack } from '@ecoinvent/ui';
import { useAuth } from 'react-oidc-context';

import useAuthUser from 'hooks/useAuthUser';
import Documentation from 'pages/Documentation';
import SignInUpsellDataset from 'pages/Search/components/SignInUpsellDataset';
import UpgradeNowUpsellDataset from 'pages/Search/components/UpgradeNowUpsellDataset';

type Props = { datasetId: string; isLoading?: boolean };

const PublicDatasetView = ({ datasetId, isLoading }: Props) => {
  const auth = useAuth();
  const { data: authUser, isLoading: authLoading } = useAuthUser();

  const renderAlert = () => {
    if (isLoading || authLoading) return null;

    if (auth.isAuthenticated && authUser?.agreement_accepted) {
      return <UpgradeNowUpsellDataset />;
    }

    return <SignInUpsellDataset datasetId={datasetId} />;
  };

  return (
    <Stack spacing={5}>
      {renderAlert()}
      <Documentation />
    </Stack>
  );
};

export default PublicDatasetView;
