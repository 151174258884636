import React from 'react';

import { Stack, Table, Tbody, Thead, Tr, Th, Heading, TableContainer } from '@ecoinvent/ui';

import { DirectContribution } from 'hooks/data/datasets/directContribution/types';
import { LciaMethod } from 'hooks/data/datasets/lcia/types';
import DirectContributionRow from 'pages/ImpactAssessment/components/DirectContribution/DirectContributionRow';

type Props = {
  exchanges: DirectContribution[];
  unitName: string;
  indicatorId: number;
  category: string;
  indicatorName: string;
  method: LciaMethod;
};

const DirectContributionList = ({ method, category, indicatorName, exchanges, unitName, indicatorId }: Props) => {
  return (
    <Stack spacing={4}>
      <Heading as={'h5'} fontWeight={'semibold'} fontSize={'md'}>
        Contributors to impact score
      </Heading>
      <TableContainer>
        <Table size={'sm'}>
          <Thead>
            <Tr>
              <Th>Relative contribution (%)</Th>
              <Th>Contributing exchanges</Th>
              <Th>Impact ({unitName})</Th>
            </Tr>
          </Thead>
          <Tbody>
            {exchanges.map((item, key) => (
              <DirectContributionRow
                method={method}
                category={category}
                indicatorName={indicatorName}
                contributor={item}
                indicatorId={indicatorId}
                index={item.index}
                key={key}
              />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  );
};
export default DirectContributionList;
