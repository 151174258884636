import Layout from 'components/shared/Layout';

type Props = Record<string, any> & {
  component: React.FC;
};

const PageWrapper = ({ component: Component, ...rest }: Props) => {
  return (
    <Layout>
      <Component {...rest} />
    </Layout>
  );
};

export default PageWrapper;
