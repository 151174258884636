import { localStorageKeys } from 'storageKeys';

import { LciaMethod } from 'hooks/data/datasets/lcia/types';
import { LCIAMethod } from 'hooks/data/datasets/lcia/useAllIndicators';
import { defaultLciaMethod, loadRecentMethods, loadSelectedMethod } from 'utilities/lcia/utils';

import { LocalStorageLciaMethodInfo, LocalStorageLciaMethod } from './types';

export const saveSelectedMethod = (
  storedLciaMethod: LocalStorageLciaMethod,
  method: LciaMethod,
  ver: string,
  system_model: string
) => {
  const selectedMethod = localStorage.getItem(localStorageKeys.SELECTED_METHOD);
  const parsedSelectedMethod: LocalStorageLciaMethod = selectedMethod ? JSON.parse(selectedMethod) : {};

  const recentMethod = loadRecentMethods();

  const newSelectedMethod = { ...parsedSelectedMethod, ...storedLciaMethod };
  const newMethods = { ...recentMethod, [ver]: method, [`${ver}${system_model}`]: method };

  localStorage.setItem(localStorageKeys.SELECTED_METHOD, JSON.stringify(newSelectedMethod));
  localStorage.setItem(localStorageKeys.RECENT_METHODS, JSON.stringify(newMethods));
};

export const buildDefaultSelectedLciaMethod = (
  indicatorData: LCIAMethod,
  ver: string,
  system_model: string
): LocalStorageLciaMethodInfo => {
  const defaultMethod = loadSelectedMethod(ver, system_model);

  if (defaultMethod) return defaultMethod;

  const lciaMethod = defaultLciaMethod(ver);

  const impactCategory = ['3.11'].includes(ver) ? 'climate change: total (excl. biogenic CO2)' : 'climate change';
  const indicatorName = 'global warming potential (GWP100)';
  const methodId = indicatorData[lciaMethod][impactCategory][indicatorName];

  return {
    lciaMethod,
    impactCategory,
    indicatorName,
    methodId,
  };
};

export const selectedMethodToString = ({ lciaMethod, impactCategory, indicatorName }: LocalStorageLciaMethodInfo) => {
  return `${lciaMethod}, ${impactCategory}, ${indicatorName}`;
};
