import { Link, Text, Alert, Stack } from '@ecoinvent/ui';

export const system_model_long_name: Record<string, string> = {
  cutoff: 'Allocation, cut-off by classification',
  apos: 'Allocation at the point of substitution',
  consequential: 'Substitution, consequential, long-term',
  EN15804: 'Allocation, cut-off, EN15804',
};

export const systemModelAlerts: Record<string | number, React.ReactNode> = {
  '3.10': (
    <Alert borderRadius={'md'} fontSize={'sm'} status="warning" variant={'left-accent'}>
      <Stack spacing={4}>
        <Text as="b">This version contains issues</Text>
        <Text>
          Two issues in version 3.10 of the ecoinvent database affect the LCIA scores of many datasets for certain impact
          categories.
        </Text>
        <Text>
          As these two issues might affect the usability of the data,{' '}
          <Text as="b">we have published version 3.10.1 in November 2024 to correct them. </Text> For more information about
          which parts of the database are concerned and about the changes that we have made, please refer to the release page of
          version 3.10.1.
        </Text>
        <Text>
          <Text as="b">We recommend ecoinvent users to work with ecoinvent v3.10.1 from its release onwards. </Text>
          However, for ecoinvent users working with data that are not affected by the changes, ecoinvent v3.10 will remain
          available.
        </Text>
      </Stack>
    </Alert>
  ),
  3.9: (
    <Alert borderRadius={'md'} fontSize={'sm'} status="warning" variant={'left-accent'}>
      <Stack spacing={4}>
        <Text as="b">This version has issues</Text>
        <Text>
          We have identified some issues in version 3.9 of the ecoinvent database. They affected parts of the published data and
          can potentially influence the LCIA scores of certain methods or might affect the usability of the data.
        </Text>
        <Text>
          <Text as="b">We published version 3.9.1 in December 2022 to correct these issues.</Text> For more information about
          which parts of the database are concerned and about the changes that we have made, please refer to the release page of
          version 3.9.1.
        </Text>
        <Text>
          <Text as="b">We recommend ecoinvent users to work with ecoinvent v3.9.1 </Text>
          from its release onwards. However, for ecoinvent users working with data that are not affected by the changes,
          ecoinvent v3.9 will remain available.
        </Text>
      </Stack>
    </Alert>
  ),
  3.7: (
    <Alert borderRadius={'md'} fontSize={'sm'} status="warning" variant={'left-accent'}>
      <Stack spacing={4}>
        <Text fontWeight="bold">An issue has been identified in version 3.7 of the ecoinvent database. </Text>
        <Text>
          Specifically, the iron sinter production dataset includes an error in the order of magnitude of the estimated
          emissions for dioxins and particulate matter (PM). This{' '}
          <Link
            href={'https://support.ecoinvent.org/ecoinvent-version-3.7'}
            isExternal={true}
            title="System model documentation"
          >
            error
          </Link>{' '}
          distorts the results of the database calculated using human health and ecotoxicity indicators from most methods.
        </Text>
        <Text>
          All other impact categories, including climate change, water depletion, cumulative energy demand, land use and land
          use change, fossil depletion, acidification, eutrophication, ionising radiation, renewable resources, stratospheric
          ozone depletion, photochemical oxidant formation, radioactive substances and ozone layer depletion, are not impacted
          by this issue.
        </Text>
        <Text>
          <Text as="b">To correct the issue, ecoinvent v3.7.1 was published in December 2020.</Text>
          We strongly recommend that all ecoinvent users work with ecoinvent v3.7.1 from its release onwards. However, for
          ecoinvent users working with impact categories that are not affected by the changes, ecoinvent v3.7 will remain
          available.
        </Text>
      </Stack>
    </Alert>
  ),
  info: (
    <Stack>
      <Text>
        System models set the methodological rules to calculate the database. Different system models are offered to meet the
        demand of different types of studies.
      </Text>
      <Link
        isExternal={true}
        href={'https://support.ecoinvent.org/system-models'}
        title="System model documentation on ecoinvent website"
      >
        Learn more about system models.
      </Link>
    </Stack>
  ),
};

export const systemModelSecondaryText: Record<string, string> = {
  'Allocation, cut-off by classification':
    'Attributional system model in which wastes are the producer’s responsibility (“polluter pays”), and there is an incentivisation to use recyclable products, that are available burden free (cut-off).',
  'Allocation at the point of substitution':
    'Attributional system model in which the responsibility over wastes (burdens) are shared between producers and sub-sequent users of the valuable products generated in the treatment processes.',
  'Substitution, consequential, long-term':
    'Consequential system model in which substitution is applied to resolve multi-functionality and demand is met by unconstrained or marginal suppliers.',
  'Allocation, cut-off, EN15804':
    'Attributional interpretation of EN15804, ISO21930 and ISO14025, developed to support the needs of Environmental Product Declaration (EPD) practitioners.',
};
