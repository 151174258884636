import { useState } from 'react';

import { useColorModeValue, HStack, Stack, Badge, Divider, Icon, IconButton, Text, Heading, Collapse } from '@ecoinvent/ui';
import { BiRuler, BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { FiPieChart } from 'react-icons/fi';
import { HiOutlineDocumentReport } from 'react-icons/hi';

import AppCard from 'components/UI/AppCard';
import { Product } from 'hooks/data/search/types';

import { TableViewOptions } from '../SearchPreferencesModalContent/types';

import ProductSearchResultTable from './ProductSearchResultTable';

type Props = {
  product: Product;
  numDatasets: number;
  sectors: string[];
  unit: string;
  tableViewOption: TableViewOptions | undefined;
};

const ProductSearchResultCard = ({ product, numDatasets, sectors, unit, tableViewOption }: Props) => {
  const grayText = useColorModeValue('gray.600', 'gray.400');
  const leftBorderColor = useColorModeValue('green.400', 'green.600');

  const [expanded, setExpanded] = useState<boolean>();

  const shouldBeCollapsed = (tableViewOption ?? TableViewOptions.EXPANDED) !== TableViewOptions.EXPANDED;

  const actualExpanded = expanded ?? !shouldBeCollapsed;

  return (
    <AppCard
      display="flex"
      flexDirection="column"
      alignItems="space-between"
      py={4}
      px={4}
      align="center"
      borderLeftColor={leftBorderColor}
      borderLeftWidth="8px"
    >
      <Stack direction={{ base: 'column', md: 'row' }} justify="space-between" alignItems={{ base: 'stretch', md: 'center' }}>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          justify="space-between"
          alignItems={{ base: 'stretch', md: 'center' }}
          spacing={5}
          px={4}
          w="100%"
        >
          <Stack spacing={1} w="100%" maxW={{ base: '100%', lg: '300px' }}>
            <Heading size="md">{product.name}</Heading>
            <Badge variant="solid" colorScheme="blue" alignSelf="flex-start">
              Product
            </Badge>
          </Stack>
          <Divider display={{ base: 'none', md: 'block' }} orientation="vertical" w="3px" color="gray" h="80px" />
          <Stack w="100%" justify="center" fontSize={'sm'}>
            <HStack>
              <Icon as={HiOutlineDocumentReport} />
              <Text color={grayText}>{numDatasets} datasets</Text>
            </HStack>
            <HStack>
              <Icon as={FiPieChart} />
              <Text color={grayText}>{sectors.join(', ')}</Text>
            </HStack>
            <HStack>
              <Icon as={BiRuler} />
              <Text color={grayText}>{unit}</Text>
            </HStack>
          </Stack>
        </Stack>
        <HStack alignSelf="stretch">
          <IconButton
            width={'100%'}
            onClick={() => setExpanded(!actualExpanded)}
            aria-label="Expand"
            icon={actualExpanded ? <BiChevronUp /> : <BiChevronDown />}
            colorScheme="green"
            variant="subtle"
          />
        </HStack>
      </Stack>

      <Collapse in={actualExpanded} unmountOnExit>
        <ProductSearchResultTable product={product} />
      </Collapse>
    </AppCard>
  );
};

export default ProductSearchResultCard;
