import { Heading, Stack, Badge, useColorModeValue, Wrap } from '@ecoinvent/ui';
export const pedigreeMatrixLabels = [
  'Reliability',
  'Completeness',
  'Temporal accuracy',
  'Geographical accuracy',
  'Technological accuracy',
];

type Props = {
  pedigreeMatrix: string;
};

const DataQuality = ({ pedigreeMatrix }: Props) => {
  const badgeColor = useColorModeValue('gray.800', 'gray.200');

  if (!pedigreeMatrix) return null;
  const parsedMatrix = JSON.parse(pedigreeMatrix) as number[];

  return (
    <Stack spacing={3}>
      <Heading as={'h4'} size="sm">
        Data Quality
      </Heading>
      <Wrap direction={'row'} spacing={2}>
        {parsedMatrix.map((value, index) => (
          <Badge
            size={'small'}
            px={2}
            variant={'outline'}
            color={badgeColor}
            borderRadius={'full'}
            key={pedigreeMatrixLabels[index]}
          >
            {pedigreeMatrixLabels[index]}: {value}
          </Badge>
        ))}
      </Wrap>
    </Stack>
  );
};

export default DataQuality;
