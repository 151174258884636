import React from 'react';

import { Stack } from '@ecoinvent/ui';
import { useAuth } from 'react-oidc-context';

import useAuthUser from 'hooks/useAuthUser';

import ConsentBanner from '../ConsentBanner';
import SideBar from '../SideBar';
import MobileSideBar from '../SideBar/MobileSideBar';
import YearReviewBanner from '../YearReviewBanner';

type Props = {
  children?: React.ReactNode;
};

const Layout: React.FC<Props> = ({ children }) => {
  const { isLoading, isAuthenticated } = useAuth();
  const { data: authUser, isLoading: isAuthLoading } = useAuthUser();

  const shouldShowConsentBanner = !isLoading && !isAuthLoading && isAuthenticated && !authUser?.agreement_accepted;

  return (
    <Stack width={'100%'} spacing={0}>
      <MobileSideBar />
      <Stack direction="row" spacing={0} alignItems={'stretch'} height={{ md: '100vh' }}>
        <SideBar />
        <Stack maxW="100%" w="100%" overflowY="auto">
          <YearReviewBanner />
          {shouldShowConsentBanner && <ConsentBanner />}
          <Stack
            py={{ base: 0, md: 3 }}
            px={{ base: 0, md: 5 }}
            maxW="100%"
            w="100%"
            flex={1}
            margin={'0 auto'}
            alignItems="center"
          >
            {children}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Layout;
