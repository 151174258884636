import { VersionInfoParams } from 'hooks/useVersionInfo/types';
export type FilterMap = {
  activity_type: string[];
  geography: string[];
  isic_class: string[];
  isic_section: string[];
  sector: string[];
};

export type SpoldSearchParams = {
  from_: number;
  limit: number;
  query: string;
  filters: Omit<FilterMap, 'query'>;
  search_by: string;
};

export type SpoldDataFetchParams = SpoldSearchParams & VersionInfoParams;

export type ElasticBucket = { key: string; doc_count: number };

export type ElasticAggregation = {
  sum_other_doc_count: number;
  doc_count_error_upper_bound: number;
  buckets: ElasticBucket[];
};

export enum ActivityType {
  TRANSFORMING_ACTIVITY = 'TRANSFORMING_ACTIVITY',
  MARKET_ACTIVITY = 'MARKET_ACTIVITY',
  PRODUCTION_MIX = 'PRODUCTION_MIX',
  IMPORT_ACTIVITY = 'IMPORT_ACTIVITY',
  MARKET_GROUP = 'MARKET_GROUP',
}

export type SpoldResult = {
  _id: string;
  _index: string;
  _score: number;
  _source: {
    activity_name: string;
    activity_type: ActivityType;
    byproduct_classification: null;
    cpc_classification: string;
    geography: string;
    geography_shortname: string;
    geography_longname: string;
    isic_class: string;
    isic_section: string;
    product_name: string;
    release_version: string;
    sector: string[] | null;
    spold_id: string;
    system_model: string;
    unit_name: string;
  };
};

type Filter = {
  name: string;
  count: number;
};

export type ActivityDataset = {
  id: number;
  geography: string;
  impact_score: number;
  impact_unit: string;
};

export type ActivityReferenceProduct = {
  name: string;
  datasets: ActivityDataset[];
  total_datasets: number;
  unit: string;
};

export type ProductActivity = {
  name: string;
  datasets: ActivityDataset[];
  score: number;
  activity_type: ActivityType;
  sectors: string[];
  time_period: string;
};

export type Activity = {
  name: string;
  products: ActivityReferenceProduct[];
  score: number;
  activity_type: ActivityType;
  sectors: string[];
  time_period: string;
  total_products: number;
};

export type Product = {
  name: string;
  activities: ProductActivity[];
  score: number;
  unit: string;
  total_activities: number;
};

type Filters = {
  sectors: Filter[];
  geographies: Filter[];
  isic_sections: Filter[];
  isic_classes: Filter[];
  activity_types: Filter[];
};

export type SpoldDataResponse = {
  filters: Filters;
  products?: Product[];
  activities?: Activity[];
  total_hits: number;
};

export type SupportedFilter = { field: keyof FilterMap; label: string };

export type SupportedFilterList = SupportedFilter[];

export type SuggestedSearchParams = Pick<SpoldSearchParams, 'query' | 'filters'>;

export type SuggestedDataFetchParams = SuggestedSearchParams & VersionInfoParams;

export type Suggestion = {
  text: string;
  highlight: string;
  item_type: string;
  sector: string[];
};

export type CategoriesDataResponse = {
  name: string;
  sectors: string[];
};

export type FeaturedSearchBody = {
  limit: number;
  sector: string[];
  interval: string;
};

export type FeaturedSearchParams = FeaturedSearchBody & VersionInfoParams;

export type FeaturedDataset = {
  dataset_id: string;
  activity: string;
  geography: string;
  product: string;
};

export type FeaturedSearchResponse = {
  new: FeaturedDataset[];
  popular: FeaturedDataset[];
  random: FeaturedDataset[];
};

export enum FeaturedType {
  NEW = 'new',
  POPULAR = 'popular',
  RANDOM = 'random',
}

export type FeaturedProductWithType = FeaturedDataset & { type: FeaturedType };
