import React from 'react';

import { Button, HStack, Heading, Icon, Stack, Td, Text, Tr, useColorModeValue } from '@ecoinvent/ui';
import mixpanel from 'mixpanel-browser';
import { BiRuler } from 'react-icons/bi';
import { IoChevronForwardOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';

import CopyToClipboardButton from 'components/shared/CopyToClipboardButton';
import { AccessResourceKey } from 'hooks/data/files/types';
import { ActivityDataset } from 'hooks/data/search/types';
import useSearchFilters from 'hooks/data/search/useSearchFilters';
import useAccess from 'hooks/useAccess';
import useVersionInfo from 'hooks/useVersionInfo';
import { newerVersions } from 'pages/Search/utils';
import { checkAccessToResource } from 'utilities/auth/access';
import { formatNumericalValue } from 'utilities/numbers';

type Props = {
  dataset: ActivityDataset;
  isLastGroup?: boolean;
  index: number;
  referenceProductName: string;
  unit: string;
  currentlyDisplayed: number;
};

const DatasetRow = ({ dataset, unit, isLastGroup, index, referenceProductName, currentlyDisplayed }: Props) => {
  const borderColor = useColorModeValue('gray.100', 'gray.700');
  const { ver, system_model } = useVersionInfo();
  const { data: accessData } = useAccess();
  const hasScoresAccess = checkAccessToResource(accessData, AccessResourceKey.SCORES, ver);
  const {
    filterValues: { query, ...otherFilters },
  } = useSearchFilters();
  const isFirstDataset = index === 0;
  const grayText = useColorModeValue('gray.700', 'gray.400');

  const isLastVisibleGeography = index === currentlyDisplayed - 1 && isLastGroup;
  const [hovering, setHovering] = React.useState(false);

  return (
    <Tr onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
      {isFirstDataset && system_model !== 'undefined' && (
        <Td
          borderBottomWidth={isLastGroup ? 0 : 1}
          verticalAlign={'top'}
          w={{ base: '100%', xl: '350px' }}
          rowSpan={currentlyDisplayed}
        >
          <Stack spacing={4} py={2}>
            <Heading size="sm">{referenceProductName}</Heading>
            <HStack>
              <Icon as={BiRuler} color={grayText} />
              <Text color={grayText}>{unit}</Text>
            </HStack>
          </Stack>
        </Td>
      )}
      <Td borderBottomWidth={isLastVisibleGeography ? 0 : 1}>{dataset.geography}</Td>
      {hasScoresAccess && system_model !== 'undefined' && newerVersions.includes(ver) && (
        <Td borderColor={borderColor} whiteSpace={'nowrap'} borderBottomWidth={isLastVisibleGeography ? 0 : 1}>
          {typeof dataset.impact_score === 'number' ? (
            <HStack justifyContent="flex-end">
              <Text fontSize="md">{formatNumericalValue(dataset.impact_score)}</Text>
              <Text color={grayText}>{dataset.impact_unit}</Text>
              <CopyToClipboardButton
                value={dataset.impact_score}
                opacity={hovering ? 1 : 0}
                onValueCopied={() => {
                  mixpanel.track('Copy Score on Search Results', {
                    score: dataset.impact_score,
                    name: referenceProductName,
                    version: ver,
                    system_model,
                    id: dataset.id,
                    geography: dataset.geography,
                  });
                }}
              />
            </HStack>
          ) : (
            <HStack justifyContent="flex-end" pr={14}>
              <Text color={grayText}>N/A</Text>
            </HStack>
          )}
        </Td>
      )}
      <Td maxW={'120px'} borderColor={borderColor} borderBottomWidth={isLastVisibleGeography ? 0 : 1}>
        <Button
          variant="link"
          alignItems="center"
          as={Link}
          size="sm"
          to={`/${ver}/${system_model}/dataset/${dataset.id}`}
          onClick={() =>
            mixpanel.track('Search Result Clicked', {
              datasetId: dataset.id,
              version: ver,
              system_model,
              query,
              filters: otherFilters,
              result_type: otherFilters.searchBy,
            })
          }
        >
          <Text>View Dataset</Text> <Icon as={IoChevronForwardOutline} />
        </Button>
      </Td>
    </Tr>
  );
};

export default DatasetRow;
