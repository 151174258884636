import React from 'react';

import { HStack, Icon, useColorModeValue, Text, useDisclosure, Stack, Collapse } from '@ecoinvent/ui';
import { FiChevronDown, FiChevronUp, FiExternalLink } from 'react-icons/fi';
import { Link as RouterLink } from 'react-router-dom';

import { NavLink } from 'utilities/routing/types';

import { doPathsMatch } from './utils';

type Props = NavLink & {
  onClick?: (path: string) => void;
};

const SideBarLink = ({ label, path = '', extraMatchingPaths = [], icon, isExternal, onClick, exactMatch, subItems }: Props) => {
  const isActiveBG = useColorModeValue('blackAlpha.50', 'whiteAlpha.200');
  const isActiveHover = useColorModeValue('blackAlpha.100', 'whiteAlpha.100');
  const isActiveColor = useColorModeValue('green.500', 'green.200');
  const isInactiveColor = useColorModeValue('gray.500', 'gray.300');

  const { isOpen, onToggle } = useDisclosure();

  const isActive = doPathsMatch({
    path,
    extraPaths: extraMatchingPaths,
    url: window.location.pathname,
    exactMatch,
  });

  return (
    <Stack>
      <HStack
        py={2}
        px={3}
        spacing={3}
        background={isActive ? isActiveBG : 'auto'}
        _hover={!isActive ? { bg: isActiveHover } : {}}
        color={isActive ? isActiveColor : isInactiveColor}
        as={RouterLink}
        to={path}
        target={isExternal ? '_blank' : undefined}
        borderRadius={'lg'}
        onClick={() => {
          subItems ? onToggle() : onClick?.(path);
        }}
        fontWeight={isActive ? 'bold' : 'normal'}
        transition={'all 0.1s ease-in-out'}
        justifyContent={'space-between'}
      >
        <HStack spacing={3}>
          <Icon as={icon} fontSize={'xl'} />
          <Text>{label}</Text>
        </HStack>
        {subItems ? <Icon as={isOpen ? FiChevronUp : FiChevronDown} /> : isExternal && <Icon as={FiExternalLink} />}
      </HStack>
      {subItems && (
        <Collapse in={isOpen}>
          <Stack>
            {subItems.map((subItem) => (
              <Stack px={2} key={subItem.path} fontSize={'sm'}>
                <SideBarLink key={subItem.path} {...subItem} onClick={onClick} />
              </Stack>
            ))}
          </Stack>
        </Collapse>
      )}
    </Stack>
  );
};

export default SideBarLink;
