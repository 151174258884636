import React from 'react';

import { useBreakpointValue, Stack } from '@ecoinvent/ui';

import SidebarContent from './SideBarContent';

const drawerWidth = 280;

const SideBar = () => {
  const display = useBreakpointValue({ base: 'none', md: 'flex' });

  return (
    <Stack as="nav" p={3} flex={1} display={display} minW={drawerWidth} w={drawerWidth} maxW={drawerWidth}>
      <SidebarContent />
    </Stack>
  );
};

export default SideBar;
