import React from 'react';

import { Badge, HStack, Text, Box } from '@ecoinvent/ui';

type Props = { name: string; onRemove: (name: string) => void };

const RemoveableBadge = ({ name, onRemove }: Props) => {
  return (
    <Badge
      size={'sm'}
      alignSelf={'flex-start'}
      key={name}
      whiteSpace={'break-spaces'}
      fontWeight={'medium'}
      borderRadius={'md'}
      px={2}
      py={0.5}
      colorScheme="green"
      textTransform={'initial'}
    >
      <HStack spacing={1}>
        <Text>{name}</Text>
        <Box
          as={'button'}
          onClick={(e) => {
            e.stopPropagation();
            onRemove(name);
          }}
        >
          &#10005;
        </Box>
      </HStack>
    </Badge>
  );
};

export default RemoveableBadge;
