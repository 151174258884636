import { ExchangeFilterCheckbox } from './types';

export const elementaryCheckboxes: ExchangeFilterCheckbox[] = [
  {
    name: 'Inputs from environment',
    key: 'From environment',
  },
  {
    name: 'Outputs to environment',
    key: 'To environment',
  },
];
