import { localStorageKeys } from 'storageKeys';

import { RecentMethodMap } from 'hooks/useSavedLciaMethod/types';
import {
  LocalStorageLciaMethod,
  LocalStorageLciaMethodInfo,
} from 'pages/Search/components/SearchPreferencesModalContent/types';

export const defaultLciaMethod = (ver: string) =>
  ['3.9', '3.9.1', '3.10', '3.10.1', '3.11'].includes(ver) ? 'IPCC 2021' : 'IPCC 2013';

export const loadSelectedMethod = (ver: string, system_model: string): LocalStorageLciaMethodInfo | undefined => {
  const selectedMethod = localStorage.getItem(localStorageKeys.SELECTED_METHOD);

  if (!selectedMethod) return;

  const parsedMethod: LocalStorageLciaMethod = JSON.parse(selectedMethod);

  return parsedMethod?.[ver]?.[system_model];
};

export const loadRecentMethods = () => {
  const recentMethods = localStorage.getItem(localStorageKeys.RECENT_METHODS);
  return recentMethods ? JSON.parse(recentMethods) : {};
};

export const saveRecentMethods = (recentMethods: RecentMethodMap | undefined) => {
  localStorage.setItem(localStorageKeys.RECENT_METHODS, JSON.stringify(recentMethods));
};
