import React from 'react';

import {
  useColorModeValue,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Icon,
  Text,
  Tooltip,
  Stack,
  Box,
  Button,
  HStack,
  Td,
} from '@ecoinvent/ui';
import { BiChevronDown } from 'react-icons/bi';
import { FiInfo } from 'react-icons/fi';

import useAllIndicators from 'hooks/data/datasets/lcia/useAllIndicators';
import { AccessResourceKey } from 'hooks/data/files/types';
import { Product } from 'hooks/data/search/types';
import useAccess from 'hooks/useAccess';
import useVersionInfo from 'hooks/useVersionInfo';
import { newerVersions } from 'pages/Search/utils';
import { checkAccessToResource } from 'utilities/auth/access';

import { buildDefaultSelectedLciaMethod, selectedMethodToString } from '../../SearchPreferencesModalContent/utils';

import ActivityRowGroup from './ActivityRowGroup';

type Props = {
  product: Product;
};

const ProductSearchResultTable = ({ product }: Props) => {
  const thGray = useColorModeValue('gray.600', 'gray.400');
  const borderColor = useColorModeValue('gray.100', 'gray.700');
  const iconColor = useColorModeValue('blue.500', 'blue.300');

  const { data: indicators } = useAllIndicators();
  const { ver, system_model } = useVersionInfo();
  const selectedMethod = indicators && buildDefaultSelectedLciaMethod(indicators, ver, system_model);

  const { data: accessData } = useAccess();

  const [displayCount, setDisplayCount] = React.useState(10);

  const canSeeScores = checkAccessToResource(accessData, AccessResourceKey.SCORES, ver);

  return (
    <TableContainer whiteSpace={'auto'}>
      <Table variant="simple" fontSize={'md'} size="sm">
        <Thead borderColor={borderColor}>
          <Tr>
            <Th borderColor={borderColor} color={thGray} maxW={{ base: '100%', xl: '400px' }} py={2}>
              Activity & Type
            </Th>
            <Th borderColor={borderColor} color={thGray}>
              Geography
            </Th>
            {canSeeScores && newerVersions.includes(ver) && (
              <Th justifyContent={'flex-end'} borderColor={borderColor} color={thGray} whiteSpace={'nowrap'} isNumeric pr={14}>
                <Text as="span">Impact Score</Text>
                <Tooltip
                  hasArrow
                  bg="gray.900"
                  py={2}
                  pointerEvents={'all'}
                  borderRadius="md"
                  width="100%"
                  label={
                    <Stack justifyContent="center" textAlign="left" spacing={2}>
                      <Text color="white">
                        The Life Cycle Impact Assessment (LCIA) score corresponding to a chosen methodology, impact category and
                        indicator for each dataset.
                      </Text>
                      <Text color="white">
                        Currently selected LCIA score source:{' '}
                        <Text as="b">{selectedMethod && selectedMethodToString(selectedMethod)}</Text>.
                      </Text>
                      <Text color="white">
                        You can change the source of the score via the ‘Preferences’ control on this page.
                      </Text>
                    </Stack>
                  }
                >
                  <Box p={0} verticalAlign={'middle'} ml={2} display={'inline-block'}>
                    <Icon mt="2px" as={FiInfo} color={iconColor} />
                  </Box>
                </Tooltip>
              </Th>
            )}
            <Th w={'150px'} borderColor={borderColor}>
              Dataset
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {product.activities.slice(0, displayCount).map((activity, activityIndex) => (
            <>
              <ActivityRowGroup
                activity={activity}
                key={activityIndex}
                isLastGroup={activityIndex === product.activities.length - 1 || activityIndex === displayCount - 1}
              />
              {displayCount < product.activities.length && activityIndex === displayCount - 1 && (
                <Tr>
                  <Td colSpan={4} border="none">
                    <HStack justifyContent={'center'} py={2}>
                      <Button
                        variant={'link'}
                        rightIcon={<Icon as={BiChevronDown} />}
                        onClick={() => setDisplayCount((count) => count * 2)}
                      >
                        Show more activities ({product.activities.length - displayCount} more)
                      </Button>
                    </HStack>
                  </Td>
                </Tr>
              )}
            </>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default ProductSearchResultTable;
