import React, { useState } from 'react';

import { Tr, Td, HStack, IconButton, Icon, Alert, AlertIcon, AlertDescription, useDisclosure } from '@ecoinvent/ui';
import mixpanel from 'mixpanel-browser';
import { BiChevronUp, BiChevronDown } from 'react-icons/bi';
import { useParams } from 'react-router-dom';

import CopyToClipboardButton from 'components/shared/CopyToClipboardButton';
import { LciaMethod, LciaScore } from 'hooks/data/datasets/lcia/types';
import { RecentMethod } from 'hooks/useSavedLciaMethod/types';
import useVersionInfo from 'hooks/useVersionInfo';
import { formatNumericalValue } from 'utilities/numbers';

import DirectContributionView from '../DirectContribution';

type Props = {
  score: LciaScore;
  selectedMethod?: RecentMethod;
};

const ImpactAssessmentRow = ({ score, selectedMethod }: Props) => {
  const { ver: version, system_model } = useVersionInfo();
  const { spold_id } = useParams();

  const { isOpen, onToggle } = useDisclosure();

  const [hovering, setHovering] = useState(false);
  return (
    <React.Fragment>
      <Tr bg={isOpen ? 'blackAlpha.50' : 'auto'} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
        <Td pl={0}>{score.category_name}</Td>
        <Td>{score.indicator_name}</Td>
        <Td isNumeric>{formatNumericalValue(score.amount)}</Td>
        <Td whiteSpace={'nowrap'}>{score.unit_name}</Td>
        <Td px={0}>
          <HStack>
            <CopyToClipboardButton
              value={score.amount}
              opacity={hovering ? 1 : 0}
              onValueCopied={() => {
                mixpanel.track('Copy Dataset Value', {
                  version,
                  system_model,
                  spold_id,
                  type: 'impact_score',
                  category: score.category_name,
                  indicator: score.indicator_name,
                  lcia_method: selectedMethod?.method_name,
                });
              }}
            />
            <IconButton
              aria-label="expand score"
              variant={'ghost'}
              onClick={onToggle}
              icon={<Icon as={isOpen ? BiChevronUp : BiChevronDown} />}
            />
          </HStack>
        </Td>
      </Tr>
      {isOpen && (
        <Tr>
          <Td colSpan={5} p={5}>
            {score.amount === 0 ? (
              <Alert variant="info">
                <AlertIcon />
                <AlertDescription>No contributors, the total score for this indicator is 0.</AlertDescription>
              </Alert>
            ) : (
              <DirectContributionView
                category={score.category_name}
                indicatorName={score.indicator_name}
                spoldId={Number(spold_id)}
                indicatorId={score.index}
                unitName={score.unit_name}
                method={selectedMethod as LciaMethod}
              />
            )}
          </Td>
        </Tr>
      )}
    </React.Fragment>
  );
};

export default ImpactAssessmentRow;
