import { AxiosError } from 'axios';
import { useAuth } from 'react-oidc-context';
import { useQuery } from 'react-query';

import api from 'api/api';
import { Access } from 'hooks/data/files/types';

const fetchAccess = async () => {
  const { data } = await api.get(`/web/access`);

  return data;
};

const useAccess = () => {
  const { isAuthenticated } = useAuth();

  return useQuery<Access, AxiosError>(['access', isAuthenticated], fetchAccess, {
    enabled: !!isAuthenticated,
  });
};

export default useAccess;
