import React from 'react';

import { Heading, Text, useColorModeValue } from '@ecoinvent/ui';

import AppCard from 'components/UI/AppCard';

const NothingHereYet = () => {
  const textColor = useColorModeValue('gray.600', 'gray.400');
  return (
    <AppCard alignItems={'center'} py={14} spacing={3}>
      <Heading size="md" color={textColor}>
        There's nothing here yet
      </Heading>
      <Text color={textColor}>Try typing a search query or apply a filter to see results!</Text>
    </AppCard>
  );
};

export default NothingHereYet;
