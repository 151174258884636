import { mode } from '@chakra-ui/theme-tools';
import { extendTheme, ecoinventTheme, systemFontStack, StyleFunctionProps, withDefaultColorScheme } from '@ecoinvent/ui';

const chakraTheme = extendTheme(
  {
    ...ecoinventTheme,
    styles: {
      global: (props: StyleFunctionProps) => ({
        body: {
          bg: mode('blackAlpha.50', 'gray.800')(props),
          textUnderlineOffset: '0.1em',
        },
      }),
    },
    fonts: {
      heading: `'PPMori', ${systemFontStack}`,
      body: `'Inter', ${systemFontStack}`,
    },
    components: {
      ...ecoinventTheme.components,
      Input: {
        ...ecoinventTheme.components.Input,
        defaultProps: {
          focusBorderColor: 'green.500',
        },
      },
      Select: {
        defaultProps: {
          focusBorderColor: 'green.500',
        },
      },
    },
  },
  withDefaultColorScheme({
    colorScheme: 'green',
    components: ['Button', 'Link', 'Tabs', 'Checkbox', 'Spinner', 'Select', 'Input'],
  })
);

export default chakraTheme;
