import { Heading, Stack, Text } from '@ecoinvent/ui';
import { useParams } from 'react-router-dom';

import AppCard from 'components/UI/AppCard';
import useDatasetHistory from 'hooks/data/datasets/useDatasetHistory';

import DatasetHistoryTimelineRow from './components/DatasetHistoryTimelineRow';

const DatasetHistory = () => {
  const { spold_id } = useParams();
  const { data: datasetHistory } = useDatasetHistory(spold_id);

  return (
    <AppCard>
      <Stack gap={6}>
        <Heading as="h4" size="md">
          History
        </Heading>
        <Stack>
          {datasetHistory ? (
            Object.keys(datasetHistory)?.map((version, idx) => (
              <DatasetHistoryTimelineRow key={idx} datasetHistory={datasetHistory} version={version} index={idx} />
            ))
          ) : (
            <Text>No history available</Text>
          )}
        </Stack>
      </Stack>
    </AppCard>
  );
};
export default DatasetHistory;
