import { Alert, Link, HStack, Stack, AlertTitle, AlertDescription, useColorModeValue, Icon, IconButton } from '@ecoinvent/ui';
import { BiX } from 'react-icons/bi';
import { IoLeaf } from 'react-icons/io5';

import useCloseableAlertState from 'hooks/useCloseableAlertState';

const NewerVersionUpsell = () => {
  const { isOpen, onClose } = useCloseableAlertState('useNewerVersionUpsell');
  const gradientColorLeft = useColorModeValue('#D8F5E6', '#045D3B');
  const gradientColorRight = useColorModeValue('#B0EACD', '#2EA266');

  if (!isOpen) {
    return null;
  }

  return (
    <Alert
      variant="subtle"
      status="success"
      borderRadius={'md'}
      bgGradient={`linear(to-r, ${gradientColorLeft}, ${gradientColorRight})`}
    >
      <HStack w="100%" justifyContent="space-between" flexDirection={{ base: 'row', xl: 'row' }}>
        <HStack spacing={4}>
          <Icon as={IoLeaf} />
          <Stack spacing={0}>
            <AlertTitle>See Impact Scores in search results with an ecoinvent licence</AlertTitle>
            <AlertDescription>
              Starting from version 3.10 onwards, impact scores are included inline to save you time.{' '}
              <Link>Learn more about ecoinvent licences</Link>
            </AlertDescription>
          </Stack>
        </HStack>
        <IconButton
          onClick={onClose}
          aria-label={'close'}
          icon={<Icon as={BiX} />}
          size={'xs'}
          fontSize={'lg'}
          variant={'ghost'}
          colorScheme="blue"
        />
      </HStack>
    </Alert>
  );
};

export default NewerVersionUpsell;
