import React, { useMemo } from 'react';

import { Select, FormLabel, FormControl, Stack, Skeleton } from '@ecoinvent/ui';

import { LciaMethod } from 'hooks/data/datasets/lcia/types';
import useSavedLciaMethod from 'hooks/useSavedLciaMethod';

import ImpactAssessmentTable from './components/ImpactAssessmentTable';
import IPCC2021Alert from './components/IPCC2021Alert';
import IPCCAlert from './components/IPCCAlert';
import { ipccBiogenicsMethodNames } from './constants';

type Props = { methodList?: LciaMethod[]; spoldId: number; isLoading?: boolean };

const ImpactAssessmentDetails = ({ methodList, spoldId, isLoading }: Props) => {
  const { selectedMethod, setSelectedMethod } = useSavedLciaMethod(methodList);

  const methodMap = useMemo(() => {
    if (!methodList) return {};

    return methodList.reduce(
      (acc, m) => {
        acc[m.method_id] = m;
        return acc;
      },
      {} as Record<number, LciaMethod>
    );
  }, [methodList]);

  return (
    <Stack spacing={4}>
      {ipccBiogenicsMethodNames.includes(selectedMethod?.method_name || '') && <IPCCAlert />}
      {selectedMethod?.method_name === 'IPCC 2021' && <IPCC2021Alert />}
      <FormControl size="sm">
        <Skeleton isLoaded={!isLoading} alignSelf={'flex-start'}>
          <FormLabel id="method" fontSize={'sm'}>
            LCIA Method
          </FormLabel>
        </Skeleton>
        <Skeleton isLoaded={!isLoading}>
          <Select
            isDisabled={!methodList?.length}
            value={selectedMethod?.method_id}
            onChange={(e) => setSelectedMethod(methodMap[parseInt(e.target.value)])}
          >
            {methodList?.map((m) => {
              return (
                <option key={m.method_id} value={m.method_id}>
                  {m.method_name}
                </option>
              );
            })}
          </Select>
        </Skeleton>
      </FormControl>
      {selectedMethod && <ImpactAssessmentTable selectedMethod={selectedMethod} spoldId={spoldId} isLoading={isLoading} />}
    </Stack>
  );
};

export default ImpactAssessmentDetails;
