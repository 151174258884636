import mixpanel from 'mixpanel-browser';
import { useParams } from 'react-router-dom';

import useVersionInfo from 'hooks/useVersionInfo';

export type MenuItem = {
  text: string;
  path: string;
};

const useMenuUrlLinks = () => {
  const { spold_id } = useParams();
  const { ver, system_model } = useVersionInfo();
  const base_url = `/${ver}/${system_model}/dataset/${spold_id}`;

  const handleRouting = (item: MenuItem) => {
    mixpanel.track_pageview({ page: 'Dataset', datasetId: spold_id, tab: item.path.split('/').pop() });
  };

  const items: MenuItem[] =
    system_model === 'undefined'
      ? [
          {
            text: 'Documentation',
            path: `${base_url}/documentation`,
          },
          {
            text: 'Exchanges',
            path: `${base_url}/exchanges`,
          },
          {
            text: 'Export',
            path: `${base_url}/export`,
          },
        ]
      : [
          {
            text: 'Documentation',
            path: `${base_url}/documentation`,
          },
          {
            text: 'Exchanges',
            path: `${base_url}/exchanges`,
          },
          {
            text: 'Consuming activities',
            path: `${base_url}/consuming_activities`,
          },
          {
            text: 'LCI results',
            path: `${base_url}/lci`,
          },
          {
            text: 'Impact assessment',
            path: `${base_url}/impact_assessment`,
          },
          {
            text: 'History',
            path: `${base_url}/history`,
          },
          {
            text: 'Export',
            path: `${base_url}/export`,
          },
        ];

  return { items, handleRouting };
};

export default useMenuUrlLinks;
