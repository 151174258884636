import React from 'react';

import { Heading, Stack, HStack, Icon, Skeleton, Text } from '@ecoinvent/ui';
import { BiRuler, BiCalendar } from 'react-icons/bi';
import { FiPackage, FiPieChart } from 'react-icons/fi';
import { IoEarth } from 'react-icons/io5';

import AppCard from 'components/UI/AppCard';
import { Geography } from 'hooks/data/datasets/documentation/types';
import useVersionInfo from 'hooks/useVersionInfo';

type KeyData = {
  geography?: Geography;
  reference_product?: string;
  sector?: string;
  unit?: string;
  time_period?: string;
};

type Props = { isLoading?: boolean } & KeyData;

const KeyData = ({ geography, reference_product, sector, unit, time_period, isLoading }: Props) => {
  const { system_model } = useVersionInfo();

  return (
    <AppCard>
      <Heading size="md">Key Data</Heading>
      <Stack spacing={6}>
        <Stack spacing={0.5}>
          <Skeleton isLoaded={!isLoading} alignSelf={'flex-start'}>
            <HStack>
              <Icon as={IoEarth} />
              <Heading size="sm">Geography</Heading>
            </HStack>
          </Skeleton>
          <Skeleton minH={'16px'} isLoaded={!isLoading}>
            <Text>
              {geography?.long_name} ({geography?.short_name})
            </Text>
          </Skeleton>
        </Stack>
        {system_model !== 'undefined' && (
          <Stack spacing={0.5}>
            <Skeleton isLoaded={!isLoading} alignSelf={'flex-start'}>
              <HStack>
                <Icon as={FiPackage} />
                <Heading size="sm">Reference Product</Heading>
              </HStack>
            </Skeleton>
            <Skeleton minH={'16px'} isLoaded={!isLoading}>
              <Text>{reference_product}</Text>
            </Skeleton>
          </Stack>
        )}
        <Stack spacing={0.5}>
          <Skeleton isLoaded={!isLoading} alignSelf={'flex-start'}>
            <HStack>
              <Icon as={BiRuler} />
              <Heading size="sm">Unit</Heading>
            </HStack>
          </Skeleton>
          <Skeleton minH={'16px'} isLoaded={!isLoading}>
            <Text>{unit}</Text>
          </Skeleton>
        </Stack>
        <Stack spacing={0.5}>
          <Skeleton isLoaded={!isLoading} alignSelf={'flex-start'}>
            <HStack>
              <Icon as={FiPieChart} />
              <Heading size="sm">Sector</Heading>
            </HStack>
          </Skeleton>
          <Skeleton minH={'16px'} isLoaded={!isLoading}>
            <Text>{sector}</Text>
          </Skeleton>
        </Stack>
        <Stack>
          <Skeleton isLoaded={!isLoading} alignSelf={'flex-start'}>
            <HStack>
              <Icon as={BiCalendar} />
              <Heading size="sm">Time Period</Heading>
            </HStack>
          </Skeleton>
          <Skeleton minH={'16px'} isLoaded={!isLoading}>
            <Text>{time_period}</Text>
          </Skeleton>
        </Stack>
      </Stack>
    </AppCard>
  );
};

export default KeyData;
