import { Alert, AlertIcon, Link, AlertDescription, IconButton, Icon } from '@ecoinvent/ui';
import { BiX } from 'react-icons/bi';

import useCloseableAlertState from 'hooks/useCloseableAlertState';

const IPCC2021Alert = () => {
  const { isOpen, onClose } = useCloseableAlertState('ipcc2021Warning');

  return (
    isOpen && (
      <Alert status="warning">
        <AlertIcon />
        <AlertDescription fontSize="sm">
          We have updated impact category names for v3.11 because we introduced the assessment of biogenic carbon dioxide with
          the method "IPCC 2021 (incl. biogenic CO2)". You can find a mapping of new (v3.11) to old (v3.10 and before) impact
          category names{' '}
          <Link colorScheme="orange" href="https://support.ecoinvent.org/guidance-on-ipcc-methods" isExternal>
            here.
          </Link>
        </AlertDescription>
        <IconButton onClick={onClose} aria-label={'close'} icon={<Icon as={BiX} />} variant={'ghost'} colorScheme="orange" />
      </Alert>
    )
  );
};

export default IPCC2021Alert;
