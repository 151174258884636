import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import api from 'api/api';

import { CategoriesDataResponse } from './types';

const fetchCategories = async () => {
  const response = await api.get<CategoriesDataResponse[]>(`web/categories`);
  return response.data;
};

const useCategoryData = () => {
  return useQuery<CategoriesDataResponse[], AxiosError>(['categories'], () => fetchCategories());
};

export default useCategoryData;
