import { InputProps, MultipleComboBox } from '@ecoinvent/ui';

import { ElasticBucket } from 'hooks/data/search/types';
import { stringifyOption } from 'utilities/search/filters';

type Props = Omit<InputProps, 'onChange'> & {
  onChange: (newSelectedItems?: ElasticBucket[]) => void;
  selectedItems: ElasticBucket[];
  options: ElasticBucket[];
  placeholder: string;
  displayName?: string;
  showSelectedItems?: boolean;
};

const FilterComboBox = ({ options, displayName, placeholder, onChange, selectedItems, showSelectedItems, ...rest }: Props) => {
  return (
    <MultipleComboBox
      placeholder={placeholder}
      options={options}
      selectedItems={selectedItems}
      filterMenuOptions={(options, selectedItems, inputValue) =>
        options.filter((o) => selectedItems.indexOf(o) === -1 && o.key.toLowerCase().includes(inputValue?.toLowerCase() ?? ''))
      }
      onChange={onChange}
      itemToString={stringifyOption.bind(null, displayName as string)}
      colorScheme="green"
      menuProps={{ maxHeight: '300px' }}
      showSelectedItems={showSelectedItems}
      {...rest}
    />
  );
};

export default FilterComboBox;
