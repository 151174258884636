import React from 'react';

import { Table, TableContainer, Td, Text, Tbody, Tr, Stack, Thead, Th, Heading } from '@ecoinvent/ui';

import { ExchangeUncertainty } from 'hooks/data/datasets/exchanges/types';
import { formatNumericalValue } from 'utilities/numbers';

type Props = { data: ExchangeUncertainty };

const Uncertainty = ({ data }: Props) => {
  if (!data?.type) return null;

  const uncertaintyKeys = Object.keys(data?.value) as Array<keyof ExchangeUncertainty['value']>;

  const mapHeaderToDisplayName = (header: keyof ExchangeUncertainty['value']) => {
    switch (header) {
      case 'mu':
        return 'μ';
      default:
        return header;
    }
  };

  return (
    <Stack spacing={3}>
      <Heading as={'h4'} size="sm">
        Uncertainty
      </Heading>
      {data?.comment && <Text>{data?.comment}</Text>}
      <TableContainer>
        <Table variant={'simple'} size={'sm'}>
          <Thead>
            <Th pl={0}>Type</Th>
            {uncertaintyKeys.map((key_string, idx) => {
              return (
                <Th textTransform={key_string === 'mu' ? 'lowercase' : undefined} key={idx}>
                  {mapHeaderToDisplayName(key_string)}
                </Th>
              );
            })}
          </Thead>
          <Tbody>
            <Tr>
              <Td pl={0} borderBottom={'0px'}>
                {data?.type}
              </Td>
              {uncertaintyKeys.map((key_string, idx) => {
                return (
                  <Td borderBottom={'0px'} key={idx}>
                    {formatNumericalValue(data?.value[key_string])}
                  </Td>
                );
              })}
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default Uncertainty;
