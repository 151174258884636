import { HStack, Icon, Badge, Stack, Text, useColorModeValue } from '@ecoinvent/ui';
import { BiHistory } from 'react-icons/bi';
import { FiPieChart } from 'react-icons/fi';
import { IoSearch } from 'react-icons/io5';

import { Suggestion } from 'hooks/data/search/types';

type Props = {
  item: Suggestion;
  isRecentSearch?: boolean;
};

const SearchSuggestion = ({ item, isRecentSearch }: Props) => {
  const recentSearchColor = useColorModeValue('blue.700', 'blue.200');
  const grayText = useColorModeValue('gray.500', 'gray.400');
  const textColor = useColorModeValue('gray.800', 'white');
  return (
    <Stack px={2} py={isRecentSearch ? 1 : 2} fontWeight="normal">
      <HStack justifyContent="space-between">
        <HStack>
          <Icon boxSize="18px" as={isRecentSearch ? BiHistory : IoSearch} color={grayText} />
          <Text
            fontWeight={isRecentSearch ? 'medium' : 'normal'}
            color={isRecentSearch ? recentSearchColor : textColor}
            dangerouslySetInnerHTML={{ __html: item.highlight }}
            fontSize="md"
          />
        </HStack>
        <Badge colorScheme={item.item_type === 'product' ? 'blue' : 'green'} variant="solid">
          {item.item_type.split('_').join(' ')}
        </Badge>
      </HStack>
      {item.sector.length > 0 && (
        <HStack>
          <Icon as={FiPieChart} color={grayText} />
          <Text color={grayText}>{item.sector.join(', ')}</Text>
        </HStack>
      )}
    </Stack>
  );
};

export default SearchSuggestion;
