export enum TableViewOptions {
  EXPANDED = 'Expanded',
  COLLAPSED = 'Collapsed',
}

export type LocalStorageLciaMethodInfo = {
  lciaMethod: string;
  impactCategory: string;
  indicatorName: string;
  methodId: number;
};

export type LocalStorageLciaMethod = Record<string, Record<string, LocalStorageLciaMethodInfo>>;
