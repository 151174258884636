import 'focus-visible/dist/focus-visible';
import '@fontsource/inter';
import '@fontsource/inter/100.css';
import '@fontsource/inter/200.css';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/800.css';
import '@fontsource/inter/900.css';

import { ThemeProvider } from '@ecoinvent/ui';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from 'react-oidc-context';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import chakraTheme from 'themes/theme';

import ApiInterceptor from 'components/auth/ApiInterceptor';
import Fallback from 'components/Fallback';
import ScrollToTop from 'components/shared/ScrollToTop';
import DatasetView from 'pages/Dataset';
import Files from 'pages/Files';
import Landing from 'pages/Landing';
import Logout from 'pages/Logout';
import NotFound from 'pages/NotFound';
import Root from 'pages/Root';
import Search from 'pages/Search';
import WhatsNew from 'pages/WhatsNew';
import { onSigninCallback, userManagerInstance } from 'utilities/auth/oidc';
import { resetUserData } from 'utilities/auth/user';
import { datasetPaths, defaultBasePath } from 'utilities/constants/routing';
import { DEFAULT_SYSTEM_MODEL, MOST_RECENT_VERSION } from 'utilities/constants/versions';
import { queryClient } from 'utilities/data/queryClient';

import PageWrapper from './components/PageWrapper';
import Login from './pages/Login';
import Register from './pages/Register';
import Reports from './pages/Reports';

const App = () => {
  return (
    <AuthProvider userManager={userManagerInstance} onSigninCallback={onSigninCallback} onRemoveUser={resetUserData}>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <ThemeProvider theme={chakraTheme}>
            <ApiInterceptor>
              <BrowserRouter>
                <ErrorBoundary FallbackComponent={Fallback} onError={() => {}}>
                  <ScrollToTop />
                  <Routes>
                    <Route path={defaultBasePath} element={<PageWrapper component={Landing} />} />
                    {datasetPaths.map((path) => (
                      <Route key={path} path={path} element={<PageWrapper component={DatasetView} exact />} />
                    ))}
                    <Route path={`${defaultBasePath}/search`} element={<PageWrapper component={Search} />} />
                    <Route path={`${defaultBasePath}/files`} element={<PageWrapper component={Files} />} />
                    <Route path={`${defaultBasePath}/reports`} element={<PageWrapper component={Reports} />} />
                    <Route path={`${defaultBasePath}/whats-new`} element={<PageWrapper component={WhatsNew} />} />
                    <Route path="/files" element={<Navigate to={`/${MOST_RECENT_VERSION}/${DEFAULT_SYSTEM_MODEL}/files`} />} />
                    <Route
                      path="/reports"
                      element={<Navigate to={`/${MOST_RECENT_VERSION}/${DEFAULT_SYSTEM_MODEL}/reports`} />}
                    />
                    <Route path="/" element={<PageWrapper component={Root} />} />
                    <Route path="/logout" element={<PageWrapper component={Logout} />} />
                    <Route path="/404" element={<NotFound />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="*" element={<Navigate replace to="/404" />} />
                  </Routes>
                </ErrorBoundary>
              </BrowserRouter>
            </ApiInterceptor>
          </ThemeProvider>
        </HelmetProvider>
      </QueryClientProvider>
    </AuthProvider>
  );
};

export default App;
