import {
  Alert,
  HStack,
  Stack,
  AlertTitle,
  AlertDescription,
  useDisclosure,
  useColorModeValue,
  IconButton,
  Icon,
} from '@ecoinvent/ui';
import { BiX } from 'react-icons/bi';
import { IoLeaf } from 'react-icons/io5';

const ImpactScoresUpsell = () => {
  const { isOpen: isVisible, onClose } = useDisclosure({ defaultIsOpen: true });
  const gradientColorLeft = useColorModeValue('#D8F5E6', '#045D3B');
  const gradientColorRight = useColorModeValue('#B0EACD', '#2EA266');

  return (
    isVisible && (
      <Alert
        variant="subtle"
        status="success"
        borderRadius={'md'}
        bgGradient={`linear(to-r, ${gradientColorLeft}, ${gradientColorRight})`}
      >
        <HStack w="100%" justifyContent="space-between">
          <HStack spacing={4}>
            <Icon as={IoLeaf} />
            <Stack spacing={0}>
              <AlertTitle>See Impact Scores in search results from version 3.10 onwards</AlertTitle>
              <AlertDescription>
                Starting from version 3.10, impact scores are included inline to save you time.
              </AlertDescription>
            </Stack>
          </HStack>
          <IconButton
            onClick={onClose}
            aria-label={'close'}
            icon={<Icon as={BiX} />}
            size={'xs'}
            fontSize={'lg'}
            variant={'ghost'}
            colorScheme="blue"
          />
        </HStack>
      </Alert>
    )
  );
};

export default ImpactScoresUpsell;
