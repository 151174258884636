import { BoxProps, Skeleton } from '@ecoinvent/ui';

import useReleaseVersions from 'hooks/data/versions/useReleaseVersions';

import ModalSelector from './ModalSelector';

const ReleaseAndSystemModel = (props: BoxProps) => {
  const { data: allReleases, isLoading } = useReleaseVersions();

  return (
    <Skeleton height={'40px'} display={'flex'} flex={1} width={'100%'} isLoaded={!isLoading} {...props}>
      {allReleases ? <ModalSelector all_releases={allReleases ?? {}} /> : <></>}
    </Skeleton>
  );
};

export default ReleaseAndSystemModel;
