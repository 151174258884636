import React from 'react';

import { Button, ButtonProps } from '@ecoinvent/ui';
import { AiOutlineClear } from 'react-icons/ai';

type Props = ButtonProps;

const ClearAllButton = (props: Props) => {
  return (
    <Button leftIcon={<AiOutlineClear />} variant="link" size="sm" {...props}>
      Reset Filters
    </Button>
  );
};

export default ClearAllButton;
