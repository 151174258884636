import React from 'react';

import { Stack, Accordion, AccordionItem, AccordionButton, AccordionPanel, useColorModeValue } from '@ecoinvent/ui';

import AppCard from 'components/UI/AppCard';
import { FileResponse } from 'hooks/data/files/types';
import useReleaseVersions from 'hooks/data/versions/useReleaseVersions';

import FilesTable from './FilesTable';
import VersionRow from './VersionRow';

type Props = { versionFiles: FileResponse };

const VersionFiles = ({ versionFiles }: Props) => {
  const bg = useColorModeValue('gray.100', 'gray.900');
  const tablesBg = useColorModeValue('white', 'gray.800');

  const { data: versions } = useReleaseVersions();

  return (
    <Stack width="100%">
      <Accordion allowToggle>
        <AccordionItem border="none">
          <AccordionButton _hover={{ bg: 'none' }} p={0}>
            <VersionRow
              versionName={versionFiles.version_name}
              releaseDate={
                Object.values(versions ?? {})?.find((item) => item[0].version === versionFiles.version_name)?.[0]
                  ?.release_date || ''
              }
            />
          </AccordionButton>
          <AccordionPanel p={{ base: 2, md: 4, lg: 6 }} bg={bg} borderRadius="md" mt={4}>
            <Stack gap={8}>
              {versionFiles.releases.map((release, releaseIdx) => (
                <AppCard key={releaseIdx} bg={tablesBg} borderRadius="md">
                  <FilesTable categoryName={release.system_model_name} data={release.release_files} type="release" />
                </AppCard>
              ))}
              <AppCard spacing={0} bg={tablesBg} borderRadius="md">
                <FilesTable categoryName="Supporting Documents" data={versionFiles.version_files} type="version" />
              </AppCard>
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Stack>
  );
};

export default VersionFiles;
