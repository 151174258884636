import { HStack, Stack, Badge, Icon, Text, Button, Heading, useColorModeValue } from '@ecoinvent/ui';
import { formatDistance } from 'date-fns';
import mixpanel from 'mixpanel-browser';
import { FiPackage } from 'react-icons/fi';
import { IoEarth, IoChevronForwardOutline as CkChevronRight } from 'react-icons/io5';
import { MdAccessTime } from 'react-icons/md';
import { Link } from 'react-router-dom';

import AppCard from 'components/UI/AppCard';
import { FeaturedType } from 'hooks/data/search/types';

type Props = {
  name: string;
  refProduct?: string;
  geography: string;
  spoldId: string;
  badgeText: FeaturedType;
  viewedAt?: string;
  version: string;
  systemModel: string;
  selectedSubSectors?: string[];
  type?: string;
};

const FeaturedDatasetCard = ({
  name,
  refProduct,
  geography,
  spoldId,
  badgeText,
  viewedAt,
  version,
  systemModel,
  selectedSubSectors,
  type,
}: Props) => {
  const grayText = useColorModeValue('gray.600', 'gray.400');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const labelColor = useColorModeValue('gray.500', 'gray.400');

  const trackClick = () => {
    mixpanel.track(type === 'recent' ? 'Recently viewed dataset' : 'Explore single dataset', {
      name,
      version,
      system_model: systemModel,
      sectors: selectedSubSectors,
      geography,
      spoldId,
      badgeText,
    });
  };

  return (
    <AppCard p={2} borderRadius="md" h="100%" w="100%" border="1px" borderColor={borderColor} shadow="none">
      <HStack justifyContent="space-between" alignItems="stretch" h="100%" spacing={4}>
        <Stack justifyContent="space-between" flexGrow={1} py={viewedAt ? 1 : 3} px={3}>
          <Stack w="100%">
            {viewedAt && (
              <HStack direction="row" alignItems="center" color={labelColor}>
                <Icon as={MdAccessTime} fontSize="inherit" />
                <Text fontSize="sm">{formatDistance(new Date(), new Date(viewedAt))} ago</Text>
              </HStack>
            )}
            <Stack direction={{ base: 'column', md: 'row' }} alignItems="flex-start" justifyContent="space-between">
              <Heading size="sm" fontWeight="bold">
                {name}
              </Heading>
              {badgeText === FeaturedType.NEW && (
                <Badge variant="solid" colorScheme="green" borderRadius="md" fontWeight="500">
                  {`${badgeText} in ${version}`}
                </Badge>
              )}
              {badgeText === FeaturedType.POPULAR && (
                <Badge variant="solid" colorScheme="green" borderRadius="md" fontWeight="500">
                  {badgeText}
                </Badge>
              )}
            </Stack>
          </Stack>
          <Stack spacing={0}>
            {refProduct && (
              <HStack spacing={2}>
                <Icon as={FiPackage} />
                <Text fontSize="sm" color={grayText}>
                  {refProduct}
                </Text>
              </HStack>
            )}
            <HStack spacing={2}>
              <Icon as={IoEarth} />
              <Text fontSize="sm" color={grayText}>
                {geography}
              </Text>
            </HStack>
          </Stack>
        </Stack>
        <Button
          px={0}
          colorScheme="green"
          variant="subtle"
          height="100%"
          as={Link}
          to={`/${version}/${systemModel}/dataset/${spoldId}`}
          onClick={() => trackClick()}
        >
          <Icon color="green" as={CkChevronRight} />
        </Button>
      </HStack>
    </AppCard>
  );
};

export default FeaturedDatasetCard;
