import { useMemo, useState } from 'react';

import { Stack, Text, Heading, useColorModeValue, HStack, Wrap, Skeleton, Pagination } from '@ecoinvent/ui';
import mixpanel from 'mixpanel-browser';
import { useAuth } from 'react-oidc-context';

import PageHead from 'components/shared/PageHead';
import useAllIndicators from 'hooks/data/datasets/lcia/useAllIndicators';
import { AccessResourceKey } from 'hooks/data/files/types';
import { ElasticAggregation, ElasticBucket, FilterMap } from 'hooks/data/search/types';
import useSearchFilters from 'hooks/data/search/useSearchFilters';
import useSpoldData from 'hooks/data/search/useSpoldData';
import useAccess from 'hooks/useAccess';
import useVersionInfo from 'hooks/useVersionInfo';
import Footer from 'pages/Landing/components/Footer';
import RemoveableBadge from 'pages/LCI/RemoveableBadge';
import { checkAccessToResource } from 'utilities/auth/access';
import { stringifyOption } from 'utilities/search/filters';

import ActivitySearchBar from './components/ActivitySearch/ActivitySearchBar';
import ActivitySearchFilters from './components/ActivitySearch/filters/ActivitySearchFilters';
import ClearAllButton from './components/ActivitySearch/filters/ActivitySearchFilters/ClearAllButton';
import { generateSelectedItems } from './components/ActivitySearch/utilities';
import ActivitySearchResultCard from './components/ActivitySearchResultCard';
import ImpactScoresUpsell from './components/ImpactScoresUpsell';
import NewerVersionUpsell from './components/NewerVersionUpsell';
import ProductSearchResultCard from './components/ProductSearchResultCard';
import SearchPreferencesModal from './components/SearchPreferencesModal';
import { TableViewOptions } from './components/SearchPreferencesModalContent/types';
import { buildDefaultSelectedLciaMethod } from './components/SearchPreferencesModalContent/utils';
import SectorsSection from './components/SectorsSection';
import SignInUpsell from './components/SignInUpsell';
import UpgradeNowUpsell from './components/UpgradeNowUpsell';
import NoSearchResults from './NoSearchResults';
import NothingHereYet from './NothingHereYet';
import { loadUserSearchPreferences, newerVersions } from './utils';
import { buildAggregations } from './utils';

export type UserSearchPreferences = {
  tableViewOption: string;
};

const Search = () => {
  const auth = useAuth();
  const grayText = useColorModeValue('gray.600', 'gray.400');
  const userSearchPreferences = loadUserSearchPreferences();
  const { ver, system_model } = useVersionInfo();

  const { data: accessData, isLoading: isAccessLoading } = useAccess();
  const hasScoresAccess = checkAccessToResource(accessData, AccessResourceKey.SCORES, ver);
  const hasNewScoresAccess = newerVersions.some((version) =>
    checkAccessToResource(accessData, AccessResourceKey.SCORES, version)
  );

  const renderUpsell = () => {
    if (isAccessLoading) {
      return null;
    }

    if (!auth.isAuthenticated) {
      return <SignInUpsell />;
    }

    if (!hasScoresAccess) {
      return <UpgradeNowUpsell />;
    }

    if (!newerVersions.includes(ver)) {
      if (!hasNewScoresAccess) {
        return <NewerVersionUpsell />;
      }

      return <ImpactScoresUpsell />;
    }

    return null;
  };

  const {
    setBulkFilterValues,
    setFilterValue,
    filterValues: { query, currentPage = 1, pageSize = 5, searchBy, ...otherFilters },
    activeFilterCount,
    resetFilters,
  } = useSearchFilters();
  const { data: indicatorData, isLoading: areIndicatorsLoading } = useAllIndicators();
  // needed so updates cause a re-fetch of search query data
  const [selectedMethodId, setSelectedMethodId] = useState<number | undefined>();
  const [tableViewOption, setTableViewOptions] = useState<TableViewOptions | undefined>(userSearchPreferences?.tableViewOption);

  const isMakingInitialSearch = !query && !activeFilterCount;
  const isUndefinedSystemModel = system_model === 'undefined';
  const initialPayload = {
    query: '',
    search_by: isUndefinedSystemModel ? 'activity' : searchBy,
    filters: { ...otherFilters },
    from_: 0,
    limit: 1,
  };

  const payload = isMakingInitialSearch
    ? initialPayload
    : {
        query: query ?? '',
        search_by: isUndefinedSystemModel ? 'activity' : searchBy,
        indicator:
          selectedMethodId ??
          (indicatorData ? buildDefaultSelectedLciaMethod(indicatorData, ver, system_model).methodId : undefined),
        filters: { ...otherFilters },
        from_: pageSize * (currentPage - 1),
        limit: pageSize,
      };

  const { data, isLoading: areSearchResultsLoading } = useSpoldData(payload, {
    enabled: !!indicatorData && !areIndicatorsLoading,
  });

  const aggregations: Record<keyof FilterMap, ElasticAggregation> = useMemo(() => buildAggregations(data), [data]);

  const onFilterChange = (field: keyof FilterMap, data: ElasticBucket[]) => {
    setBulkFilterValues({
      currentPage: 1,
      [field]: data?.map((item) => item.key) ?? [],
    });
  };

  const determineSearchBy = (itemType?: string, searchBy?: string) => {
    if (!itemType) return searchBy ?? 'activity';

    return itemType === 'cas_number' ? 'activity' : itemType;
  };

  const onSearch = (value: string, itemType?: string) => {
    setBulkFilterValues({
      query: value,
      currentPage: 1,
      searchBy: determineSearchBy(itemType, searchBy),
    });
    value && mixpanel.track('Search', { query: value, searchBy: itemType ?? searchBy ?? 'activity', filters: otherFilters });
  };

  const selectedItems = useMemo(() => generateSelectedItems(aggregations, otherFilters), [aggregations, otherFilters]);

  const isLoading = [areSearchResultsLoading, areIndicatorsLoading].some(Boolean);

  const totalResults = data?.total_hits ?? 0;

  const shouldShowResults = (query || activeFilterCount) && indicatorData;
  const shouldShowSectorCategories = shouldShowResults && totalResults;

  return (
    <Stack maxW="container.xl" width="100%" spacing={7} py={5} flex={1} justifyContent="space-between">
      <PageHead title="Dataset Search" />
      <Stack spacing={7} flex={1}>
        <Stack spacing={5} px={{ base: 4, md: 0 }}>
          <Stack>
            <Heading size="xl">Search Datasets</Heading>
            {shouldShowResults && (
              <Skeleton isLoaded={!isLoading} alignSelf={'flex-start'}>
                <Text color={grayText}>
                  {data?.total_hits} {searchBy === 'product' ? 'Products' : 'Activities'} Found
                </Text>
              </Skeleton>
            )}
          </Stack>
          <Stack alignItems="center" w="100%" maxW={'container.md'}>
            <ActivitySearchBar btnVariant="solid" onSearch={onSearch} />
          </Stack>
          <Stack
            justifyContent="space-between"
            alignItems={{ base: 'flex-start', xl: 'start' }}
            flexDirection={{ base: 'column', xl: 'row' }}
            w="100%"
            spacing={{ base: 1, xl: 6 }}
          >
            <Stack w="100%">
              <Text fontSize={'sm'} color={grayText} fontWeight="semibold">
                Filter by:
              </Text>
              <Stack
                direction={{ base: 'column', lg: 'row' }}
                alignItems={{ base: 'stretch', lg: 'center' }}
                spacing={3}
                justify={'space-between'}
              >
                <ActivitySearchFilters
                  aggregations={aggregations}
                  selectedItems={selectedItems}
                  onFilterChange={onFilterChange}
                />
                {indicatorData && (
                  <SearchPreferencesModal
                    onChangeTableView={(option) => setTableViewOptions(option as TableViewOptions)}
                    indicatorData={indicatorData}
                    onChangeMethodId={(id) => setSelectedMethodId(id)}
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
          {!!activeFilterCount && (
            <Skeleton isLoaded={!isLoading}>
              <HStack w="100%" justifyContent="space-between">
                <Wrap>
                  {Object.entries(selectedItems).map(([key, selectedItemsForField]) => {
                    return selectedItemsForField?.values.map((selectedItemForRender) => {
                      return (
                        <RemoveableBadge
                          key={selectedItemForRender.key}
                          name={`${selectedItemsForField.displayName}: ${stringifyOption(selectedItemsForField.displayName, selectedItemForRender)}`}
                          onRemove={() => {
                            const newItems = selectedItemsForField.values.filter(
                              (item) => item.key !== selectedItemForRender.key
                            );
                            onFilterChange(key as keyof FilterMap, newItems);
                          }}
                        />
                      );
                    });
                  })}
                </Wrap>
                {activeFilterCount > 0 && (
                  <Stack direction="row" justifyContent={{ base: 'flex-start', xl: 'flex-end' }} alignItems="center" py={2}>
                    <ClearAllButton onClick={resetFilters} />
                  </Stack>
                )}
              </HStack>
            </Skeleton>
          )}
        </Stack>
        {isLoading && !isMakingInitialSearch ? (
          new Array(4).fill(0).map((_, index) => <Skeleton key={index} height="300px" />)
        ) : (
          <Stack spacing={4}>
            {renderUpsell()}
            {shouldShowResults ? (
              data?.products && data.products.length ? (
                Object.entries(data.products).map(([key, value]) => (
                  <ProductSearchResultCard
                    key={key}
                    sectors={value.activities[0].sectors}
                    numDatasets={value.activities.reduce((res, { datasets }) => res + datasets.length, 0)}
                    unit={value.unit}
                    product={value}
                    tableViewOption={tableViewOption}
                  />
                ))
              ) : data?.activities && data.activities.length ? (
                Object.entries(data.activities).map(([key, value]) => (
                  <ActivitySearchResultCard key={key} activity={value} tableViewOption={tableViewOption} />
                ))
              ) : (
                <NoSearchResults query={query} />
              )
            ) : (
              <NothingHereYet />
            )}
          </Stack>
        )}
        {shouldShowResults && data?.total_hits && data.total_hits > 0 && (
          <Stack width="100%" alignItems={'center'} justifyContent={'flex-end'} direction={'row'}>
            <Pagination
              currentPage={currentPage}
              itemsPerPage={pageSize}
              totalHits={data.total_hits || 0}
              onPageChange={(page) => setFilterValue('currentPage', page)}
            />
          </Stack>
        )}
        {shouldShowSectorCategories && <SectorsSection />}
      </Stack>
      <Footer />
    </Stack>
  );
};

export default Search;
