import { HStack, Stack, Image, Text, Link, useColorModeValue } from '@ecoinvent/ui';

import logoWhite from 'assets/ecoQuery-white.svg';
import logo from 'assets/ecoQuery.svg';
import ReleaseAndSystemModel from 'components/shared/SideBar/ReleaseAndSystemModel';

const Footer = () => {
  const logoSrc = useColorModeValue(logo, logoWhite);
  const borderColor = useColorModeValue('gray.300', 'gray.600');
  const textColor = useColorModeValue('gray.600', 'gray.400');
  return (
    <HStack
      width="100%"
      alignSelf={'center'}
      justifyContent="space-evenly"
      borderRadius="md"
      alignItems="start"
      flexDirection={{ base: 'column', lg: 'row' }}
      spacing={4}
      fontSize={'sm'}
      p={{ base: 4, md: 0 }}
    >
      <Stack w="100%" spacing={4}>
        <HStack
          spacing={4}
          w="100%"
          justifyContent={'space-between'}
          flexDirection={{ base: 'column', xl: 'row' }}
          alignItems={{ base: 'stretch', xl: 'center' }}
        >
          <Image src={logoSrc} alt="ecoQuery Logo" width="150px" />
          <ReleaseAndSystemModel
            borderColor={borderColor}
            borderWidth={'1px'}
            borderRadius={'md'}
            boxSizing="content-box"
            borderStyle="solid"
            width={'100%'}
            maxW={{ base: 'auto', lg: '200px' }}
          />
        </HStack>
        <Text color={textColor}>© {new Date().getFullYear()} All Rights Reserved.</Text>
      </Stack>
      <HStack
        spacing={{ base: 2, lg: 6 }}
        w="100%"
        flexDirection={{ base: 'column', lg: 'row' }}
        alignItems={{ base: 'start', lg: 'center' }}
        justifyContent={{ base: 'flex-start', lg: 'center' }}
      >
        <Link href="https://ecoinvent.org/gdpr-eula#privacy-policy" isExternal>
          Privacy Policy
        </Link>
        <Link href="https://ecoinvent.org/gdpr-eula#eula" isExternal>
          Terms and Conditions
        </Link>
      </HStack>
      <Stack w="100%">
        <Text color={textColor}>
          We are a non-profit based in Zurich, Switzerland. Learn more at{' '}
          <Link isExternal href="https://ecoinvent.org/">
            ecoinvent.org
          </Link>
        </Text>
      </Stack>
    </HStack>
  );
};

export default Footer;
