import { Alert, AlertIcon, AlertDescription, Stack, Text, AlertTitle, Link } from '@ecoinvent/ui';
import { format } from 'date-fns';

import { appConfig } from 'utilities/env';

const SubscriptionAlert = ({ expiryDate }: { expiryDate: string }) => {
  return (
    <Alert status="warning">
      <AlertIcon />
      <Stack spacing={0}>
        <AlertTitle fontWeight="bold">Your access to downloads</AlertTitle>
        <AlertDescription>
          By downloading ecoinvent files & reports, you acknowledge that their access and use are strictly limited to the
          duration of your license period, which is valid until{' '}
          <Text as="span" fontWeight="bold">
            {format(new Date(expiryDate), 'PPP')}
          </Text>
          . Any use beyond an active license requires a license renewal.{' '}
          <Link colorScheme="orange" href={appConfig.accountsUrl} isExternal>
            View current access details
          </Link>
        </AlertDescription>
      </Stack>
    </Alert>
  );
};

export default SubscriptionAlert;
