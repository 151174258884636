import { ActivityType } from 'hooks/data/search/types';

export const activityTypes = {
  MARKET_ACTIVITY: 'Market Activity',
  TRANSFORMING_ACTIVITY: 'Transforming Activity',
  PRODUCTION_MIX: 'Production Mix',
  IMPORT_ACTIVITY: 'Import Activity',
  MARKET_GROUP: 'Market Group',
};

export const activityTypeCopy: Record<ActivityType, string> = {
  [ActivityType.MARKET_ACTIVITY]:
    'A market activity represents the consumption mix of a product for a given region, accounting for the trade between the producer and consumer, and, when needed, for product losses that occur during the product’s transportation.',
  [ActivityType.MARKET_GROUP]:
    'A market group is a market dataset exclusively created by market activities that are concerned with the same product but cover different regions. Therefore, the geography of the market group contains the geography of all supplying markets.',
  [ActivityType.PRODUCTION_MIX]:
    'The production-volume-weighted average activity of the suppliers of a specific product within a geographical area.',
  [ActivityType.TRANSFORMING_ACTIVITY]:
    'A human activity that transforms inputs into outputs that are different from the inputs. This is the most common type of activity in the ecoinvent database, e.g. a hard coal mine that transforms hard coal in ground to the marketable product hard coal, or an automobile plant that turns metals, plastics and energy into cars.',
  [ActivityType.IMPORT_ACTIVITY]:
    'Import and exports are modeled, where needed, to better represent the regional reality. In the ecoinvent database, trade between regions is always represented by an import activity.',
};

export const timePeriodCopy =
  'The time period does not represent an expiring date but the period of original data collection or the period to which the dataset has been extrapolated. If the end date is in the past it is still considered valid today, since processes or technologies did not change or the supply chain was updated.';
