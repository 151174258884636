import { useMemo } from 'react';

import { isEqual } from 'lodash';
import { useSearchParams } from 'react-router-dom';

import { FilterMap } from './types';

type ExtendedFilterMap = FilterMap & { query?: string; currentPage?: number; pageSize?: number; searchBy: string };

export const defaultFilterValues: ExtendedFilterMap = {
  query: '',
  geography: [],
  isic_section: [],
  isic_class: [],
  activity_type: [],
  sector: [],
  currentPage: 1,
  pageSize: 10,
  searchBy: 'activity',
};

export const removeDefaults = <T extends Record<string, any>>(values: T, defaultValues: T) => {
  return Object.keys(values)
    .filter((key) => !isEqual(values[key], defaultValues[key]))
    .reduce((acc, key) => {
      return { ...acc, [key]: values[key] };
    }, {});
};

const useSearchFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const filterValues: ExtendedFilterMap = useMemo(
    () => ({
      query: searchParams.get('query') || defaultFilterValues.query,
      currentPage: Number(searchParams.get('currentPage')) || defaultFilterValues.currentPage,
      pageSize: Number(searchParams.get('pageSize')) || defaultFilterValues.pageSize,
      geography: searchParams.getAll('geography') || defaultFilterValues.geography,
      isic_section: searchParams.getAll('isic_section') || defaultFilterValues.isic_section,
      isic_class: searchParams.getAll('isic_class') || defaultFilterValues.isic_class,
      activity_type: searchParams.getAll('activity_type') || defaultFilterValues.activity_type,
      sector: searchParams.getAll('sector') || defaultFilterValues.sector,
      searchBy: searchParams.get('searchBy') || defaultFilterValues.searchBy,
    }),
    [searchParams]
  );

  const resetFilters = () => {
    setSearchParams({
      ...defaultFilterValues,
      query: filterValues.query as string,
    } as any);
  };

  const setFilterValue = (field: keyof ExtendedFilterMap, value: string | string[] | number) => {
    setSearchParams({ ...filterValues, [field]: value } as any);
  };

  const setBulkFilterValues = (values: Partial<ExtendedFilterMap>) => {
    setSearchParams({ ...filterValues, ...values } as any);
  };

  const activeFilterCount = useMemo(() => {
    // don't include the search string, or pagination stuff in the active filter count
    const { query: _, pageSize: __, currentPage: ___, searchBy: ____, ...rest } = filterValues;
    return Object.values(removeDefaults(rest, defaultFilterValues)).length;
  }, [filterValues]);

  return { filterValues, resetFilters, setFilterValue, setBulkFilterValues, activeFilterCount };
};

export default useSearchFilters;
