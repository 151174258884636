import { Button, Heading, HStack, Icon, Stack, Text, useColorModeValue } from '@ecoinvent/ui';
import { IconType } from 'react-icons';
import { IoChevronForwardOutline } from 'react-icons/io5';

import AppCard from 'components/UI/AppCard';
import useSearchFilters from 'hooks/data/search/useSearchFilters';

const SectorCard = ({ title, sectors, icon }: { title: string; sectors: string[]; icon: IconType }) => {
  const iconColor = useColorModeValue('gray.500', 'gray.400');
  const { setFilterValue } = useSearchFilters();

  const onClick = () => {
    setFilterValue('sector', sectors);
  };

  return (
    <AppCard key={title} p={1} borderRadius="md" h="100%" w="100%">
      <HStack height="100%" justifyContent="space-between">
        <HStack alignItems="center" spacing={4} p={3}>
          <Icon color={iconColor} as={icon} borderRadius="md" fontSize={32} />
          <Stack justifyContent="space-between">
            <Heading size="sm">{title}</Heading>
            <Text fontSize="sm">{sectors.join(', ')}</Text>
          </Stack>
        </HStack>
        <Button px={0} colorScheme="green" variant="subtle" height="100%" onClick={onClick}>
          <Icon color="green" as={IoChevronForwardOutline} />
        </Button>
      </HStack>
    </AppCard>
  );
};

export default SectorCard;
