import { Alert, AlertDescription, AlertIcon, Link, Stack } from '@ecoinvent/ui';

import { appConfig } from 'utilities/env';

const ConsentBanner = () => {
  return (
    <Stack p={3} px={5}>
      <Alert status="warning" rounded="lg">
        <AlertIcon />
        <AlertDescription fontSize={'sm'}>
          We've updated our Data Privacy agreements. To continue using ecoQuery,{' '}
          <Link colorScheme="orange" href={`${appConfig.accountsUrl}/consent?redirectUrl=${window.location.href}`}>
            please accept the updated agreements.
          </Link>
        </AlertDescription>
      </Alert>
    </Stack>
  );
};

export default ConsentBanner;
