import React, { useState } from 'react';

import { Td, Stack, HStack, Heading, Icon, Button, Tr, Text, useColorModeValue, Tooltip } from '@ecoinvent/ui';
import mixpanel from 'mixpanel-browser';
import { BiCalendar } from 'react-icons/bi';
import { FiPieChart, FiInfo } from 'react-icons/fi';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { IoChevronForwardOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';

import CopyToClipboardButton from 'components/shared/CopyToClipboardButton';
import { AccessResourceKey } from 'hooks/data/files/types';
import { Product } from 'hooks/data/search/types';
import useSearchFilters from 'hooks/data/search/useSearchFilters';
import useAccess from 'hooks/useAccess';
import useVersionInfo from 'hooks/useVersionInfo';
import { newerVersions } from 'pages/Search/utils';
import { checkAccessToResource } from 'utilities/auth/access';
import { formatNumericalValue } from 'utilities/numbers';

import { activityTypes, timePeriodCopy } from './constants';
import { activityTypeCopy } from './constants';

type Props = {
  isLastGroup?: boolean;
  activity: Product['activities'][0];
  index: number;
  currentlyDisplayed: number;
};

const ActivityRow = ({ isLastGroup, activity, index, currentlyDisplayed }: Props) => {
  const isLastCurrentlyVisibleGeography = index === activity.datasets.length - 1 || index === currentlyDisplayed - 1;
  const doMoreDatasetsExist = activity.datasets.length > currentlyDisplayed;

  // hide bottom borders of last geography in group if it's the last group or there are more datasets than are currently visible
  const shouldHideLastDatasetBorder = isLastCurrentlyVisibleGeography && (isLastGroup || doMoreDatasetsExist);

  // hide activity column border if it's the last group or there are more datasets than are currently visible
  const shouldHideActivityColumnBorder = isLastGroup || doMoreDatasetsExist;

  const [hovering, setHovering] = useState(false);
  const { ver, system_model } = useVersionInfo();
  const { data: accessData } = useAccess();
  const {
    filterValues: { query, ...otherFilters },
  } = useSearchFilters();

  const hasScoresAccess = checkAccessToResource(accessData, AccessResourceKey.SCORES, ver);

  const grayText = useColorModeValue('gray.700', 'gray.400');
  const iconColor = useColorModeValue('blue.500', 'blue.300');
  const borderColor = useColorModeValue('gray.100', 'gray.700');

  const shouldIncludeActivityName = index === 0;
  const dataset = activity.datasets[index];

  return (
    <Tr onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
      {shouldIncludeActivityName && (
        <Td
          w={{ base: '100%', xl: '450px' }}
          verticalAlign={'top'}
          pl={0}
          py={0}
          rowSpan={Math.min(currentlyDisplayed, activity.datasets.length)}
          borderColor={borderColor}
          borderBottomWidth={shouldHideActivityColumnBorder ? 0 : 1}
          pb={shouldHideActivityColumnBorder ? 0 : 1}
        >
          <Stack key={activity.name} spacing={4} p={4} pb={shouldHideActivityColumnBorder ? 2 : 4}>
            <HStack justifyContent="space-between">
              <Heading size="sm">{activity.name}</Heading>
              <HStack spacing={0.5}>
                <Icon as={HiOutlineDocumentReport} color={grayText} />
                <Text color={grayText}>{activity.datasets.length}</Text>
              </HStack>
            </HStack>
            <Stack fontSize={'sm'}>
              <HStack>
                <Icon as={FiPieChart} color={grayText} />
                <Text color={grayText}>{activityTypes[activity.activity_type]}</Text>
                <Tooltip
                  hasArrow
                  bg="gray.900"
                  py={2}
                  color="white"
                  borderRadius="md"
                  width="100%"
                  label={activityTypeCopy[activity.activity_type]}
                >
                  <Stack>
                    <Icon as={FiInfo} color={iconColor} />
                  </Stack>
                </Tooltip>
              </HStack>
              <HStack>
                <Icon as={BiCalendar} color={grayText} />
                <Text color={grayText}>{activity.time_period}</Text>
                <Tooltip hasArrow bg="gray.900" py={2} color="white" borderRadius="md" width="100%" label={timePeriodCopy}>
                  <Stack>
                    <Icon as={FiInfo} color={iconColor} />
                  </Stack>
                </Tooltip>
              </HStack>
            </Stack>
          </Stack>
        </Td>
      )}
      <Td borderColor={borderColor} py={3} borderBottomWidth={shouldHideLastDatasetBorder ? 0 : 1}>
        {dataset.geography}
      </Td>

      {hasScoresAccess && newerVersions.includes(ver) && (
        <Td borderColor={borderColor} whiteSpace={'nowrap'} borderBottomWidth={shouldHideLastDatasetBorder ? 0 : 1}>
          {typeof dataset.impact_score === 'number' ? (
            <HStack justifyContent="flex-end">
              <Text fontSize="md">{formatNumericalValue(dataset.impact_score)}</Text>
              <Text color={grayText}>{dataset.impact_unit}</Text>
              <CopyToClipboardButton
                value={dataset.impact_score}
                opacity={hovering ? 1 : 0}
                onValueCopied={() => {
                  mixpanel.track('Copy Score on Search Results', {
                    score: dataset.impact_score,
                    name: activity.name,
                    version: ver,
                    system_model,
                    id: dataset.id,
                    geography: dataset.geography,
                  });
                }}
              />
            </HStack>
          ) : (
            <HStack justifyContent="flex-end" pr={14}>
              <Text color={grayText}>N/A</Text>
            </HStack>
          )}
        </Td>
      )}
      <Td maxW={'120px'} borderColor={borderColor} borderBottomWidth={shouldHideLastDatasetBorder ? 0 : 1}>
        <Button
          variant="link"
          alignItems="center"
          as={Link}
          size="sm"
          to={`/${ver}/${system_model}/dataset/${dataset.id}`}
          onClick={() =>
            mixpanel.track('Search Result Clicked', {
              datasetId: dataset.id,
              version: ver,
              system_model,
              query,
              filters: otherFilters,
              result_type: otherFilters.searchBy,
            })
          }
        >
          <Text>View Dataset</Text> <Icon as={IoChevronForwardOutline} />
        </Button>
      </Td>
    </Tr>
  );
};

export default ActivityRow;
