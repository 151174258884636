import { Stack, HStack, useColorModeValue } from '@ecoinvent/ui';

import { supportedFilters } from 'hooks/data/search/constants';
import { ElasticBucket, FilterMap, ElasticAggregation } from 'hooks/data/search/types';

import FilterComboBox from '../FilterComboBox';

import { LabeledFilterBucket } from './types';

type Props = {
  onFilterChange: (field: keyof FilterMap, data: ElasticBucket[]) => void;
  selectedItems: Record<keyof FilterMap, LabeledFilterBucket>;
  aggregations: Record<keyof FilterMap, ElasticAggregation> | undefined;
  isLoading?: boolean;
};

const ActivitySearchFilters = ({ onFilterChange, selectedItems, aggregations, isLoading }: Props) => {
  const comboBoxBg = useColorModeValue('white', 'gray.700');

  return (
    <Stack spacing={5} w="100%">
      <HStack w="100%">
        <Stack spacing={2} w="100%" direction={{ base: 'column', lg: 'row' }} alignItems={{ base: 'stretch', lg: 'center' }}>
          {supportedFilters.map((f) => (
            <Stack width={'100%'} key={f.label}>
              <FilterComboBox
                displayName={f.label}
                placeholder={f.label}
                onChange={(v) => {
                  onFilterChange(f.field, v ?? []);
                }}
                isDisabled={isLoading || !aggregations?.[f.field]}
                selectedItems={selectedItems[f.field]?.values}
                options={aggregations?.[f.field].buckets ?? []}
                showSelectedItems={false}
                bg={comboBoxBg}
                w="100%"
                flex={1}
              />
            </Stack>
          ))}
        </Stack>
      </HStack>
    </Stack>
  );
};
export default ActivitySearchFilters;
