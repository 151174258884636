import React from 'react';

import { InputProps } from '@ecoinvent/ui';

import AutoCompleteSearch from 'components/shared/AutoCompleteSearch';
import useSearchFilters from 'hooks/data/search/useSearchFilters';

type Props = {
  onSearch: (value: string, item_type?: string) => void;
  btnVariant?: string;
  includeButton?: boolean;
  size?: InputProps['size'];
};

const ActivitySearchBar = ({ onSearch, btnVariant, includeButton = true, size = 'lg' }: Props) => {
  const {
    filterValues: { query, ...filters },
  } = useSearchFilters();

  return (
    <AutoCompleteSearch
      onSearch={onSearch}
      defaultValue={query ?? ''}
      filters={filters}
      btnVariant={btnVariant}
      includeButton={includeButton}
      size={size}
    />
  );
};

export default ActivitySearchBar;
