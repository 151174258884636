export const defaultBasePath = `/:ver/:system_model`;

export const baseDatasetPath = `${defaultBasePath}/dataset/:spold_id`;

export const datasetPaths = [
  `${baseDatasetPath}/documentation`,
  `${baseDatasetPath}/exchanges`,
  `${baseDatasetPath}/lci`,
  `${baseDatasetPath}/impact_assessment`,
  `${baseDatasetPath}/direct_contribution`,
  `${baseDatasetPath}/consuming_activities`,
  `${baseDatasetPath}/history`,
  `${baseDatasetPath}/export`,
  `${baseDatasetPath}`, // fallback
];

export const searchPath = `${defaultBasePath}/search`;
