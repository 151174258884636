import {
  Alert,
  Link,
  HStack,
  Stack,
  AlertTitle,
  AlertDescription,
  Button,
  useColorModeValue,
  LightMode,
  Icon,
} from '@ecoinvent/ui';
import { IoLeaf } from 'react-icons/io5';

import { appConfig } from 'utilities/env';

const UpgradeNowUpsell = () => {
  const upgradeUrl = appConfig.accountsUrl;
  const gradientColorLeft = useColorModeValue('#D8F5E6', '#045D3B');
  const gradientColorRight = useColorModeValue('#B0EACD', '#2EA266');

  return (
    <Alert
      variant="subtle"
      status="success"
      borderRadius={'md'}
      bgGradient={`linear(to-r, ${gradientColorLeft}, ${gradientColorRight})`}
    >
      <HStack w="100%" justifyContent="space-between" flexDirection={{ base: 'column', md: 'row' }}>
        <HStack spacing={4}>
          <Icon as={IoLeaf} />
          <Stack spacing={0}>
            <AlertTitle>See Impact Scores in search results with an ecoinvent licence</AlertTitle>
            <AlertDescription>
              Starting from version 3.10 onwards, impact scores are included inline to save you time.{' '}
              <Link>Learn more about ecoinvent licences</Link>
            </AlertDescription>
          </Stack>
        </HStack>
        <LightMode>
          <Button flexShrink={'0'} width={{ base: '100%', md: 'auto' }} variant="subtle" as={Link} href={upgradeUrl}>
            Upgrade Now
          </Button>
        </LightMode>
      </HStack>
    </Alert>
  );
};

export default UpgradeNowUpsell;
