import React from 'react';

import { HStack, Text, Stack, Heading, Button, Icon, useColorModeValue, Link } from '@ecoinvent/ui';
import { IoChevronForwardOutline } from 'react-icons/io5';

import AppCard from 'components/UI/AppCard';

import { GuideStep } from './types';

type Props = {
  step: GuideStep;
};

const GuideCard = ({ step: { icon, title, text, link } }: Props) => {
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <AppCard border="1px" borderColor={borderColor} shadow="none" key={title} p={4} py={0} borderRadius="md" h="100%" w="100%">
      <HStack height="100%" justifyContent="space-between" py={4}>
        <HStack alignItems="start">
          {icon}
          <Stack justifyContent="space-between">
            <Heading fontSize="lg" fontWeight="bold">
              {title}
            </Heading>
            <Text fontSize="sm">{text}</Text>
          </Stack>
        </HStack>
        <Button as={Link} isExternal px={0} href={link} colorScheme="green" variant="subtle" height="100%" alignSelf="stretch">
          <Icon color="green" as={IoChevronForwardOutline} />
        </Button>
      </HStack>
    </AppCard>
  );
};

export default GuideCard;
