import { Stack, StackProps } from '@ecoinvent/ui';
import ReactMarkdown, { Components } from 'react-markdown';

import { markdownComponentMap } from './markdownComponents';

type Props = { children: string; components?: Components } & StackProps;

const DocumentationMarkdownRenderer = ({ children, components, ...stackProps }: Props) => {
  return (
    <Stack spacing={3} {...stackProps}>
      <ReactMarkdown
        components={{
          ...markdownComponentMap,
          ...components,
        }}
      >
        {children}
      </ReactMarkdown>
    </Stack>
  );
};

export default DocumentationMarkdownRenderer;
