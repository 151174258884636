import { useMemo } from 'react';

import { Box, Stack, useColorModeValue, Divider } from '@ecoinvent/ui';
import mixpanel from 'mixpanel-browser';
import { Link as RouterLink } from 'react-router-dom';

import logoWhite from 'assets/ecoQuery-white.svg';
import logo from 'assets/ecoQuery.svg';
import useVersionInfo from 'hooks/useVersionInfo';
import { topLinks } from 'utilities/routing/appLinks';

import ReleaseAndSystemModel from './ReleaseAndSystemModel';
import SideBarLink from './SideBarLink';
import UserMenu from './UserMenu';

type Props = {
  onLinkClick?: (route: string) => void;
};

const SidebarContent = ({ onLinkClick }: Props) => {
  const { system_model, ver } = useVersionInfo();

  const sidebarBg = useColorModeValue('white', 'whiteAlpha.50');

  const mainLinks = useMemo(() => topLinks({ ver, system_model }), [ver, system_model]);
  const logoSrc = useColorModeValue(logo, logoWhite);

  return (
    <Stack spacing={0} height="100%" borderRadius={'xl'} bg={sidebarBg} boxShadow={'sm'}>
      <Stack py={5} px={4} direction="row" alignItems="center">
        <Box maxW={'180px'}>
          <RouterLink
            to={`/${ver}/${system_model}`}
            onClick={() => {
              mixpanel.track('Logo Clicked', {
                version: ver,
                system_model: system_model,
              });
            }}
          >
            <img className="logo" alt="logo" src={logoSrc} />
          </RouterLink>
        </Box>
      </Stack>
      <Divider />
      <Stack height="100%" py={4} justifyContent="space-between">
        <Stack px={4} spacing={5}>
          <ReleaseAndSystemModel />
          <Stack>
            {mainLinks.map((link) => (
              <SideBarLink key={link.path} {...link} onClick={onLinkClick} />
            ))}
          </Stack>
        </Stack>

        <Stack>
          <Stack spacing={4} px={4} width="100%">
            <Divider />
            <UserMenu />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SidebarContent;
