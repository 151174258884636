import { Heading, Stack } from '@ecoinvent/ui';
import { IoLeafOutline } from 'react-icons/io5';

import useCategoryData from 'hooks/data/search/useCategories';
import { knownSectorCategories } from 'pages/Landing/content';

import SectorCard from './SectorCard';

const SectorsSection = () => {
  const { data: categoryData } = useCategoryData();
  return (
    <Stack>
      <Heading size="md" py={2}>
        Broaden your search with Sector Categories
      </Heading>
      <Stack w="100%" display="grid" gridTemplateColumns={{ base: 'repeat(1,1fr)', lg: 'repeat(2, 1fr)' }}>
        {categoryData?.map((category) => (
          <SectorCard
            key={category.name}
            title={category.name}
            sectors={category.sectors}
            icon={knownSectorCategories[category.name]?.icon ?? IoLeafOutline}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default SectorsSection;
