import { useState } from 'react';

import { HStack, Heading, Stack, Text, Divider, Skeleton, Button } from '@ecoinvent/ui';
import mixpanel from 'mixpanel-browser';
import { FiArrowRight } from 'react-icons/fi';
import { IoLeafOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

import PageHead from 'components/shared/PageHead';
import AppCard from 'components/UI/AppCard';
import useRecentDatasets from 'hooks/data/datasets/useRecentDatasets';
import { FeaturedType } from 'hooks/data/search/types';
import useCategoryData from 'hooks/data/search/useCategories';
import useFeaturedDatasets from 'hooks/data/search/useFeaturedDatasets';
import useVersionInfo from 'hooks/useVersionInfo';
import FeaturedDatasetCard from 'pages/Landing/components/FeaturedDatasetCard';
import SectorBadges from 'pages/Landing/components/SectorBadges';

import Footer from './components/Footer';
import GuideCard from './components/GuideCard';
import Hero from './components/Hero';
import SectorIcon from './components/SectorIcon';
import { getStartedGuideSteps, knownSectorCategories } from './content';

const Landing = () => {
  const [selectedSubSectors, setSelectedSubSectors] = useState<string[]>([]);

  const { ver, system_model } = useVersionInfo();

  const { data: categoryData } = useCategoryData();

  const { data: recentDatasets = [] } = useRecentDatasets();

  const { data, isLoading } = useFeaturedDatasets({ limit: 9, sector: selectedSubSectors, interval: 'month' });

  const navigate = useNavigate();

  const browseMoreDataset = () => {
    const baseUrl = new URL(`${window.location.href}/search`);
    selectedSubSectors.forEach((subSector) => baseUrl.searchParams.append('sector', subSector));
    const newUrl = baseUrl.href.replace(window.location.origin, '');
    mixpanel.track('Explore all datasets', { version: ver, system_model, selectedSubSectors });
    navigate(newUrl);
  };

  return (
    <Stack
      width="100%"
      alignSelf={'center'}
      justifyContent="space-between"
      alignItems="center"
      overflow="clip"
      spacing={7}
      maxW="100rem"
    >
      <PageHead title="Home" />
      <Hero />
      {recentDatasets.length > 0 && (
        <AppCard w="100%">
          <Heading size="md">Recently Viewed Datasets</Heading>
          <Stack width={'100%'}>
            <HStack
              alignItems="start"
              display="grid"
              gridTemplateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)', xl: 'repeat(4, 1fr)' }}
              gridTemplateRows="auto"
            >
              {recentDatasets
                ?.slice(0, 4)
                .map((featuredDataset) => (
                  <FeaturedDatasetCard
                    key={featuredDataset.dataset.index}
                    name={featuredDataset.dataset.activity_name}
                    refProduct={featuredDataset.dataset.reference_product}
                    geography={
                      typeof featuredDataset.dataset.geography === 'string'
                        ? featuredDataset.dataset.geography
                        : `${featuredDataset.dataset.geography.long_name} (${featuredDataset.dataset.geography.short_name})`
                    }
                    spoldId={featuredDataset.dataset.index.toString()}
                    badgeText={FeaturedType.RANDOM}
                    viewedAt={featuredDataset.viewed_at}
                    version={featuredDataset.dataset.version}
                    systemModel={featuredDataset.dataset.system_model}
                    type="recent"
                  />
                ))}
            </HStack>
          </Stack>
        </AppCard>
      )}
      <AppCard width="100%" justifyContent="space-between" alignItems="start">
        <Stack>
          <Heading size="md" fontSize={'2xl'}>
            Explore the database via sectors
          </Heading>
          <Text>
            Our database contains a broad range of different activities, divided across industrial sectors. Select a sector to
            browse its contents.
          </Text>
        </Stack>
        <HStack alignItems="start" spacing={8} flexWrap="wrap">
          {categoryData?.map((category) => (
            <SectorBadges
              key={category.name}
              sector={category.name}
              subsectors={category.sectors}
              icon={<SectorIcon icon={knownSectorCategories[category.name]?.icon ?? IoLeafOutline} />}
              selectedSubSectors={selectedSubSectors}
              onSelectedSubSectorsChange={setSelectedSubSectors}
            />
          ))}
        </HStack>
        <Stack width={'100%'}>
          <HStack
            alignItems="start"
            display="grid"
            gridTemplateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)', xl: 'repeat(3, 1fr)' }}
            gridTemplateRows="auto"
          >
            {isLoading
              ? new Array(9).fill(0).map((_, index) => <Skeleton key={index} height="120px" isLoaded={false} />)
              : data &&
                data?.map((featuredDataset) => (
                  <FeaturedDatasetCard
                    key={featuredDataset.dataset_id}
                    name={featuredDataset.activity}
                    refProduct={featuredDataset.product}
                    geography={featuredDataset.geography}
                    spoldId={featuredDataset.dataset_id}
                    badgeText={featuredDataset.type}
                    version={ver}
                    systemModel={system_model}
                    selectedSubSectors={selectedSubSectors}
                    type="featured"
                  />
                ))}
          </HStack>
          {selectedSubSectors.length > 0 && (
            <Button
              mt={4}
              variant="link"
              size="sm"
              colorScheme="green"
              onClick={() => browseMoreDataset()}
              rightIcon={<FiArrowRight />}
              alignSelf={'flex-end'}
            >
              Browse more for {selectedSubSectors.length > 1 ? 'these sectors' : 'this sector'}
            </Button>
          )}
        </Stack>
      </AppCard>
      <HStack
        width="100%"
        alignSelf={'center'}
        flexDirection={{ base: 'column', xl: 'row' }}
        spacing={7}
        justifyContent="space-between"
        alignItems="start"
      >
        <Stack
          alignItems={{ base: 'center', xl: 'stretch' }}
          textAlign={{ base: 'center', xl: 'left' }}
          maxW={{ base: 'auto', xl: 80 }}
          px={{ base: 4, md: 0 }}
        >
          <Heading size="lg">Get started with ecoinvent data</Heading>
          <Text>Browse numerous resources to help you understand how to work with ecoinvent datasets, scores, and more.</Text>
        </Stack>
        <AppCard
          w="100%"
          display="grid"
          gridTemplateColumns={{ base: 'repeat(1,1fr)', xl: 'repeat(2, 1fr)' }}
          gridTemplateRows="auto"
          px={{ base: 4, md: 8 }}
          py={5}
          borderRadius="md"
        >
          {getStartedGuideSteps.map((step) => (
            <GuideCard key={step.title} step={step} />
          ))}
        </AppCard>
      </HStack>
      <Divider />
      <Footer />
    </Stack>
  );
};

export default Landing;
