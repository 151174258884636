import React from 'react';

import { Button, HStack, Icon, Td, Tr } from '@ecoinvent/ui';
import { BiChevronDown } from 'react-icons/bi';

import { Product } from 'hooks/data/search/types';

import ActivityRow from './ActivityRow';

type Props = {
  activity: Product['activities'][0];
  isLastGroup?: boolean;
};

const ActivityRowGroup = ({ activity, isLastGroup }: Props) => {
  const [displayCount, setDisplayCount] = React.useState(10);

  return (
    <>
      {activity.datasets.slice(0, displayCount).map((dataset, index) => {
        return (
          <ActivityRow
            key={dataset.id}
            activity={activity}
            isLastGroup={isLastGroup || index === displayCount - 1}
            index={index}
            currentlyDisplayed={displayCount}
          />
        );
      })}
      {displayCount < activity.datasets.length && (
        <Tr>
          <Td border={isLastGroup ? 'none' : 'auto'} />
          <Td border={isLastGroup ? 'none' : 'auto'} colSpan={3}>
            <HStack justifyContent={'center'} py={2}>
              <Button
                variant={'link'}
                rightIcon={<Icon as={BiChevronDown} />}
                size="sm"
                onClick={() => setDisplayCount((count) => count * 2)}
              >
                Show more geographies ({activity.datasets.length - displayCount} more)
              </Button>
            </HStack>
          </Td>
        </Tr>
      )}
    </>
  );
};
export default React.memo(ActivityRowGroup);
