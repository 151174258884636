type DoPathsMatchParams = { path: string; extraPaths: string[]; url: string; exactMatch?: boolean };

export const doPathsMatch = ({ path, extraPaths, url, exactMatch }: DoPathsMatchParams) => {
  if (exactMatch) {
    return path === url;
  }

  const combinedPaths = [path, ...extraPaths];
  return [combinedPaths, url].every((p) => p === '/') || combinedPaths.some((path) => url.startsWith(path) && path.length > 1);
};
