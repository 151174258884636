import React, { useState } from 'react';

import { Tr, IconButton, Collapse, Td, Box, Icon } from '@ecoinvent/ui';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

import { ExchangeProperty } from 'hooks/data/datasets/exchanges/types';
import { formatNumericalValue } from 'utilities/numbers';

type Props = { item: ExchangeProperty };

const PropertyTableRow = ({ item }: Props) => {
  const [open, setOpen] = useState(false);

  const hasCommentOrUncertainty = item.comment || item.uncertainty;

  return (
    <>
      <Tr cursor={hasCommentOrUncertainty ? 'pointer' : ''} onClick={() => setOpen(!open)}>
        <Td pl={0} w="100%">
          {item.name}
        </Td>
        <Td align="right">{formatNumericalValue(item.amount)}</Td>
        <Td>{item.unit}</Td>
        <Td pr={0}>
          {(item.comment || item.uncertainty) && (
            <IconButton
              colorScheme="black"
              variant={'link'}
              aria-label="expand row"
              size="sm"
              height={'auto'}
              onClick={() => setOpen(!open)}
              icon={<Icon as={open ? MdKeyboardArrowUp : MdKeyboardArrowDown} />}
            />
          )}
        </Td>
      </Tr>
      <Tr>
        {hasCommentOrUncertainty && (
          <Td px={0} py={0} colSpan={4} border={open ? 'auto' : 'none'}>
            <Collapse in={open} unmountOnExit>
              <Box p={4}>
                <b>Comment: </b> {item.comment}
              </Box>
            </Collapse>
          </Td>
        )}
      </Tr>
    </>
  );
};

export default PropertyTableRow;
