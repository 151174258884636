import React from 'react';

import {
  Button,
  Table,
  useDisclosure,
  Thead,
  Th,
  Tr,
  Td,
  Tbody,
  DetailList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  TableContainer,
} from '@ecoinvent/ui';

import { DataGeneratorAndPublication } from 'hooks/data/datasets/documentation/types';

import dataGeneratorConfig from './dataGeneratorConfig';

type Props = { details: DataGeneratorAndPublication };

const DataGeneratorTable = ({ details }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const detailsToRender = {
    ...details,
    person_name: details.person_name === '[System]' ? 'ecoinvent' : details.person_name,
  };

  return (
    <TableContainer boxShadow={'xs'} as={Stack} whiteSpace={'break-spaces'} borderRadius={'md'} w="100%">
      <Table size="sm">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Published Year</Th>
            <Th visibility={'hidden'}>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>{detailsToRender.person_name}</Td>
            <Td>{detailsToRender.published_source_year}</Td>
            <Td width={'100px'}>
              <Button size="sm" variant="link" onClick={onOpen}>
                Show Details
              </Button>
              <Modal size={'2xl'} onClose={onClose} isOpen={isOpen} isCentered>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Data Publication details</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <DetailList
                      config={dataGeneratorConfig}
                      data={detailsToRender}
                      labelProps={{ minWidth: '130px' }}
                      rowProps={{ alignItems: 'flex-start' }}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button variant={'subtle'} size="sm" onClick={onClose}>
                      Close
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default DataGeneratorTable;
