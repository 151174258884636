import { SkeletonText } from '@ecoinvent/ui';

import {
  DataEntryBy,
  DataGeneratorAndPublication,
  DocumentationResponse,
  FileAttributes,
  Review,
} from 'hooks/data/datasets/documentation/types';

import DataEntryByTable from './components/DataEntryByTable';
import DataGeneratorTable from './components/DataGeneratorTable';
import DocumentationMarkdownRenderer from './components/DocumentationMarkdownRenderer';
import FileAttributesTable from './components/FileAttributesTable';
import ReviewTable from './components/ReviewTable';
import { DocumentationConfigItem } from './types';

const renderWithMarkdown = (value: DocumentationDatum) => (
  <DocumentationMarkdownRenderer>{value as string}</DocumentationMarkdownRenderer>
);

type DocumentationDatum = string | Review[] | DataGeneratorAndPublication | DataEntryBy | FileAttributes;

export const buildDocumentationConfig = (data: DocumentationResponse): DocumentationConfigItem<DocumentationDatum>[] => [
  {
    label: 'General comment',
    value: data.activity_description?.general_comment,
    renderValue: renderWithMarkdown,
  },
  {
    label: 'Technology',
    value: data.activity_description?.technology?.comment,
    renderValue: renderWithMarkdown,
  },
  {
    label: 'Included activities starts',
    value: data.activity_description?.included_activities_start,
  },
  {
    label: 'Included activities ends',
    value: data.activity_description?.included_activities_end,
  },
  {
    label: 'Synonyms',
    value: data.activity_description?.synonyms?.join(', ') ?? undefined,
  },
  {
    label: 'Product information',
    value: data.activity_description?.product_information,
    renderValue: renderWithMarkdown,
  },
  {
    label: 'Geography comment',
    value: data.activity_description?.geography?.comment,
    renderValue: renderWithMarkdown,
  },
  {
    label: 'Time period Comment',
    value: data.activity_description.time_period_comment
      ? ` ${data.activity_description.time_period_comment ?? ''}`
      : undefined,
    renderValue: renderWithMarkdown,
  },
  {
    label: 'Sampling procedure',
    value: data.modelling_and_validation.representativeness.sampling_procedure,
  },
  {
    label: 'Extrapolations',
    value: data.modelling_and_validation.representativeness.extrapolations,
  },
  {
    label: 'Representativeness',
    value: data?.modelling_and_validation?.representativeness?.percent
      ? `${data?.modelling_and_validation?.representativeness?.percent}%`
      : undefined,
  },
  {
    label: 'Reviews',
    value: data.modelling_and_validation?.reviews as Review[],
    renderValue: (values: DocumentationDatum) => <ReviewTable reviews={values as Review[]} />,
  },
  {
    label: 'Data generator and publication',
    value: data.data_generator_and_publication,
    renderValue: (values: DocumentationDatum) => <DataGeneratorTable details={values as DataGeneratorAndPublication} />,
  },
  {
    label: 'Data entry by',
    value: data.administrative_information?.data_entry_by,
    renderValue: (values: DocumentationDatum) => <DataEntryByTable details={values as DataEntryBy} />,
  },
  {
    label: 'File attributes',
    value: data.administrative_information?.file_attributes,
    renderValue: (values: DocumentationDatum) => <FileAttributesTable details={values as FileAttributes} />,
  },
];

export const loadingConfig = [
  { isLoading: true, label: 'Loading item 0', value: 'Oh James! eu quero uma salada de frutas' },
  {
    isLoading: true,
    label: 'Loading item 1',
    value: 'Oh James! eu quero uma salada de frutas',
    renderValue: () => <SkeletonText mt={2} noOfLines={4} spacing={2} skeletonHeight={4} />,
  },
  {
    isLoading: true,
    label: 'Loading item 2',
    value: 'Oh James! eu quero uma salada de frutas',
    renderValue: () => <SkeletonText mt={2} noOfLines={2} spacing={3} skeletonHeight={4} />,
  },
  { isLoading: true, label: 'Loading item 3', value: 'Oh James! eu quero uma salada de frutas' },
  {
    isLoading: true,
    label: 'Loading item 4',
    value: 'Oh James! eu quero uma salada de frutas',
    renderValue: () => <SkeletonText mt={2} noOfLines={6} spacing={3} skeletonHeight={4} />,
  },
];
