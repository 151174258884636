import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import api from 'api/api';
import useVersionInfo from 'hooks/useVersionInfo';

import { DatasetHistory, FetchDatasetParams } from './types';

const fetchDatasetHistory = async (params: FetchDatasetParams) => {
  const { data } = await api.get('/spold/history', { params });

  return data;
};

const useDatasetHistory = (id?: string) => {
  const { ver: version, system_model } = useVersionInfo();
  return useQuery<DatasetHistory, AxiosError>(
    ['history', id, version, system_model],
    () => fetchDatasetHistory({ dataset_id: id as string, version, system_model }),
    { enabled: !!id, retry: false }
  );
};

export default useDatasetHistory;
