import { ReleaseVersion } from '../versions/types';

import { Geography } from './documentation/types';

export type RecentDataset = {
  dataset: Dataset;
  viewed_at: string;
};

export type FetchDatasetParams = Pick<ReleaseVersion, 'version' | 'system_model'> & { dataset_id: string };

export type Dataset = {
  activity_name: string;
  geography: Geography;
  has_access: boolean;
  index: number;
  reference_product: string;
  system_model: ReleaseVersion['system_model'];
  version: ReleaseVersion['version'];
  unit: string;
  sector: string;
};

export type RelatedDataset = {
  description: string;
  is_current: boolean;
  related_dataset_type: string;
  spold_id: number;
  system_model: ReleaseVersion['system_model'];
  version: ReleaseVersion['version'];
};

export enum ExportFileType {
  XML = 'XML',
  PDF = 'PDF',
}

export type ExportFile = {
  name: string;
  description: string;
  type: ExportFileType;
  url: string;
  is_s3_presigned: boolean;
};

export type DatasetHistoryDetail = {
  id: number;
  activity: string;
  geography: string;
  product: string;
};

export type DatasetHistoryItem = {
  status_long: string;
  status_short: string;
  from_: DatasetHistoryDetail[];
  predecessor: string;
  to: DatasetHistoryDetail[];
};

export type DatasetHistory = {
  [key: string]: DatasetHistoryItem;
};
