import React from 'react';

import {
  Button,
  Stack,
  Table,
  TableContainer,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Modal,
  Tbody,
  Thead,
  Th,
  Tr,
  Td,
  DetailList,
} from '@ecoinvent/ui';

import { FileAttributes } from 'hooks/data/datasets/documentation/types';
import useDisclosure from 'hooks/useModal';
import { convertISOToDate } from 'utilities/helpers/formats';

import fileAttributesConfig from './fileAttributeConfig';

type Props = { details: FileAttributes };

const FileAttributesTable = ({ details }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { creation_timestamp, file_timestamp } = details;
  return (
    <TableContainer boxShadow={'xs'} as={Stack} whiteSpace={'break-spaces'} borderRadius={'md'} w="100%">
      <Table size="sm">
        <Thead>
          <Tr>
            <Th>Creation date</Th>
            <Th>Last edit</Th>
            <Th visibility={'hidden'}>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>{creation_timestamp && convertISOToDate(creation_timestamp)}</Td>
            <Td>{file_timestamp && convertISOToDate(file_timestamp)}</Td>
            <Td width={'100px'}>
              <Button size="sm" variant="link" onClick={onOpen}>
                Show Details
              </Button>
              <Modal onClose={onClose} isOpen={isOpen} isCentered>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>File attributes details</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <DetailList config={fileAttributesConfig} data={details} />
                  </ModalBody>
                  <ModalFooter>
                    <Button variant={'subtle'} size="sm" onClick={onClose}>
                      Close
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default FileAttributesTable;
