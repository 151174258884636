import { BiData, BiFile, BiHome, BiSupport } from 'react-icons/bi';
import { GrResources } from 'react-icons/gr';
import { HiOutlineDocumentReport, HiSparkles } from 'react-icons/hi';

import { VersionInfoParams } from 'hooks/useVersionInfo/types';

import { NavLink } from './types';

export const topLinks = ({ ver, system_model }: VersionInfoParams): NavLink[] => [
  {
    label: 'Home',
    path: `/${ver}/${system_model}`,
    icon: BiHome,
    exactMatch: true,
  },
  {
    label: 'Datasets',
    path: `/${ver}/${system_model}/search`,
    extraMatchingPaths: [`/${ver}/${system_model}/dataset`],
    icon: BiData,
  },
  {
    label: 'Resources',
    icon: GrResources,
    subItems: [
      {
        label: 'Files',
        path: `/${ver}/${system_model}/files`,
        icon: BiFile,
      },
      {
        label: 'Reports',
        path: `/${ver}/${system_model}/reports`,
        icon: HiOutlineDocumentReport,
      },
    ],
  },
  {
    label: `What's new`,
    path: `/${ver}/${system_model}/whats-new`,
    icon: HiSparkles,
  },
  {
    label: 'Support',
    path: `https://support.ecoinvent.org`,
    icon: BiSupport,
    isExternal: true,
  },
];
