import {
  Alert,
  HStack,
  Stack,
  AlertTitle,
  AlertDescription,
  useDisclosure,
  Button,
  useColorModeValue,
  LightMode,
} from '@ecoinvent/ui';
import mixpanel from 'mixpanel-browser';
import { IoLeaf } from 'react-icons/io5';
import { useAuth } from 'react-oidc-context';

type Props = {
  datasetId: string;
};

const SignInUpsell = ({ datasetId }: Props) => {
  const { isOpen: isVisible } = useDisclosure({ defaultIsOpen: true });
  const auth = useAuth();
  const gradientColorLeft = useColorModeValue('#D9DEF8', '#324692');
  const gradientColorRight = useColorModeValue('#D8F5E6', '#045D3B');
  const buttonColorVariant = useColorModeValue('solid', 'subtle');

  return (
    isVisible && (
      <Alert
        variant="subtle"
        status="success"
        borderRadius={'md'}
        bgGradient={`linear(to-r, ${gradientColorLeft}, ${gradientColorRight})`}
      >
        <HStack w="100%" justifyContent="space-between" flexDirection={{ base: 'column', md: 'row' }}>
          <HStack spacing={4}>
            <IoLeaf />
            <Stack spacing={0}>
              <AlertTitle>Get full access to this dataset</AlertTitle>
              <AlertDescription>Create an account or sign in to continue.</AlertDescription>
            </Stack>
          </HStack>
          <LightMode>
            <Button
              variant={buttonColorVariant}
              onClick={() => {
                mixpanel.track('Login (Dataset)', { datasetId: datasetId });
                auth.signinRedirect({ redirect_uri: window.location.href });
              }}
            >
              Sign In
            </Button>
          </LightMode>
        </HStack>
      </Alert>
    )
  );
};

export default SignInUpsell;
