import React from 'react';

import { Heading, Link, Stack } from '@ecoinvent/ui';

import PageHead from 'components/shared/PageHead';
import AppCard from 'components/UI/AppCard';
import DocumentationMarkdownRenderer from 'pages/Documentation/components/DocumentationMarkdownRenderer';
import Footer from 'pages/Landing/components/Footer';

import { releaseMdString } from './releaseInfo';

const WhatsNew = () => {
  return (
    <Stack alignItems="center" maxW="container.xl" width="100%" spacing={7} py={5} flex={1} justifyContent="space-between">
      <PageHead title="What's New" />
      <Stack w="100%" spacing={7} flex={1}>
        <Heading px={{ base: 4, md: 0 }} as="h1" size="2xl">
          What's New
        </Heading>
        <AppCard>
          <Stack spacing={0}>
            <DocumentationMarkdownRenderer
              spacing={1}
              components={{
                a: (props) => <Link {...props} isExternal />,
                h5: (props) => <Heading {...props} size={'md'} my={5} />,
              }}
            >
              {releaseMdString}
            </DocumentationMarkdownRenderer>
          </Stack>
        </AppCard>
      </Stack>
      <Footer />
    </Stack>
  );
};

export default WhatsNew;
