import { ElasticBucket } from 'hooks/data/search/types';
import { capitalizeFirstLetter } from 'utilities/helpers/formats';

export const stringifyOption = (displayName: string, option: ElasticBucket | null) => {
  switch (displayName) {
    case 'Activity type':
      return `${capitalizeFirstLetter(option?.key.split('_').join(' ').toLowerCase() ?? '')} (${option?.doc_count})`;
    case 'Sector':
      return `${capitalizeFirstLetter(option?.key.toLowerCase() ?? '')} (${option?.doc_count})`;
    default:
      return `${option?.key} (${option?.doc_count})`;
  }
};
