import { Button, Modal, ModalOverlay } from '@ecoinvent/ui';
import { IoSettingsOutline } from 'react-icons/io5';

import { LCIAMethod } from 'hooks/data/datasets/lcia/useAllIndicators';
import useModal from 'hooks/useModal';

import SearchPreferencesModalContent from '../SearchPreferencesModalContent';

type Props = {
  onChangeTableView: (value: string) => void;
  onChangeMethodId: (value: number) => void;
  indicatorData: LCIAMethod;
};
const SearchPreferencesModal = ({ indicatorData, onChangeTableView, onChangeMethodId }: Props) => {
  const { isOpen, onOpen, onClose } = useModal();

  return (
    <>
      <Button variant="subtle" colorScheme="green" leftIcon={<IoSettingsOutline />} onClick={onOpen}>
        Preferences
      </Button>
      <Modal onClose={onClose} isOpen={isOpen} size="3xl">
        <ModalOverlay />
        <SearchPreferencesModalContent
          indicatorData={indicatorData}
          onChangeTableView={onChangeTableView}
          onClose={onClose}
          onChangeMethodId={onChangeMethodId}
        />
      </Modal>
    </>
  );
};

export default SearchPreferencesModal;
