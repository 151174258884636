import { useState } from 'react';

import {
  ModalContent,
  Stack,
  ModalHeader,
  Link,
  HStack,
  Heading,
  ModalCloseButton,
  ModalBody,
  Text,
  Select,
  Button,
  ModalFooter,
  useColorModeValue,
  useRadioGroup,
} from '@ecoinvent/ui';
import { BiEdit, BiSave } from 'react-icons/bi';

import { LCIAMethod } from 'hooks/data/datasets/lcia/useAllIndicators';
import useLciaMethods from 'hooks/data/datasets/lcia/useLciaMethods';
import useVersionInfo from 'hooks/useVersionInfo';
import { newerVersions, saveUserSearchPreferences } from 'pages/Search/utils';
import { loadUserSearchPreferences } from 'pages/Search/utils';

import TableViewRadioCard from './TableViewRadioCard';
import { TableViewOptions } from './types';
import { buildDefaultSelectedLciaMethod, saveSelectedMethod, selectedMethodToString } from './utils';

const tableViewOptions = [TableViewOptions.EXPANDED, TableViewOptions.COLLAPSED];

type Props = {
  onChangeTableView: (value: string) => void;
  onChangeMethodId: (value: number) => void;
  indicatorData: LCIAMethod;
  onClose: () => void;
};

const SearchPreferencesModalContent = ({
  indicatorData,
  onChangeTableView: setTableViewOption,
  onChangeMethodId: onChangeMethodId,
  onClose,
}: Props) => {
  const { ver, system_model } = useVersionInfo();
  const { data: methodList } = useLciaMethods();

  const selectedMethod = buildDefaultSelectedLciaMethod(indicatorData, ver, system_model);
  const defaultTableViewOption = loadUserSearchPreferences().tableViewOption as string;

  const [lciaMethod, setLciaMethod] = useState(selectedMethod.lciaMethod);
  const [impactCategory, setImpactCategory] = useState(selectedMethod.impactCategory);
  const [indicatorName, setIndicatorName] = useState(selectedMethod.indicatorName);

  const tableViewBgColor = useColorModeValue('gray.200', 'gray.600');

  const {
    value: tableViewOption,
    onChange,
    getRootProps,
    getRadioProps,
  } = useRadioGroup({
    name: 'tableView',
    defaultValue: defaultTableViewOption || TableViewOptions.EXPANDED,
  });

  const [isSelectingScore, setIsSelectingScore] = useState(false);
  const [hasChange, setHasChange] = useState(false);

  const group = getRootProps();

  return (
    <ModalContent p={8} as={Stack} spacing={5}>
      <ModalHeader p={0}>
        <HStack justify="space-between">
          <Heading size="md" alignItems="center">
            Search Preferences
          </Heading>
          <ModalCloseButton top={6} right={5} />
        </HStack>
      </ModalHeader>
      <ModalBody p={0} as={Stack} spacing={5}>
        <Stack spacing={8}>
          {newerVersions.includes(ver) && (
            <Stack spacing={3}>
              <Stack>
                <Heading size="sm">Impact Score</Heading>
                <Text>
                  The source indicator displayed in search results.{' '}
                  <Link
                    href={'https://support.ecoinvent.org/impact-assessment'}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Release documentation on ecoinvent website"
                    fontWeight={'semibold'}
                    colorScheme="green"
                  >
                    Learn more about Impact Scores
                  </Link>
                </Text>
              </Stack>

              <Stack>
                <Text fontSize="sm" fontWeight="semibold">
                  Current Selection
                </Text>
                {isSelectingScore ? (
                  <Stack>
                    <Text fontWeight="semibold" fontSize="sm">
                      LCIA Method
                    </Text>
                    <Select
                      value={lciaMethod}
                      onChange={(e) => {
                        setLciaMethod(e.target.value);
                        setImpactCategory(Object.keys(indicatorData[e.target.value]).sort()[0]);
                        setIndicatorName(
                          Object.keys(
                            indicatorData[e.target.value][Object.keys(indicatorData[e.target.value]).sort()[0]]
                          ).sort()[0]
                        );
                      }}
                    >
                      {Object.keys(indicatorData)
                        .sort()
                        .map((key) => (
                          <option key={key} value={key}>
                            {key}
                          </option>
                        ))}
                    </Select>
                    <Text fontWeight="semibold" fontSize="sm">
                      Impact Category
                    </Text>
                    <Select
                      value={impactCategory}
                      onChange={(e) => {
                        setImpactCategory(e.target.value);
                        setIndicatorName(Object.keys(indicatorData[lciaMethod][e.target.value]).sort()[0]);
                      }}
                    >
                      {Object.keys(indicatorData[lciaMethod])
                        .sort()
                        .map((key) => (
                          <option key={key} value={key}>
                            {key}
                          </option>
                        ))}
                    </Select>
                    <Text fontWeight="semibold" fontSize="sm">
                      Indicator Name
                    </Text>
                    <Select value={indicatorName} onChange={(e) => setIndicatorName(e.target.value)}>
                      {Object.keys(indicatorData[lciaMethod][impactCategory])
                        .sort()
                        .map((key) => (
                          <option key={key} value={key}>
                            {key}
                          </option>
                        ))}
                    </Select>
                  </Stack>
                ) : (
                  <HStack justify="space-between">
                    <Text fontWeight="medium">{selectedMethodToString(selectedMethod)}</Text>
                    <Button
                      colorScheme="green"
                      variant="link"
                      leftIcon={<BiEdit />}
                      onClick={() => {
                        setHasChange(true);
                        setIsSelectingScore(true);
                      }}
                    >
                      Change
                    </Button>
                  </HStack>
                )}
              </Stack>
            </Stack>
          )}
          <Stack>
            <Heading size="sm">View Settings</Heading>
            <Stack>
              <Text fontSize={'sm'} fontWeight={'semibold'}>
                Default Table View{' '}
              </Text>
              <HStack {...group} bg={tableViewBgColor} p={1} borderRadius="md" alignSelf="flex-start">
                {tableViewOptions.map((value) => {
                  const radio = getRadioProps({ value });
                  return (
                    <TableViewRadioCard
                      key={value}
                      {...radio}
                      onChange={() => {
                        setHasChange(true);
                        setTableViewOption(value);
                        onChange(value);
                      }}
                    >
                      {value}
                    </TableViewRadioCard>
                  );
                })}
              </HStack>
            </Stack>
          </Stack>
        </Stack>
      </ModalBody>
      <ModalFooter p={0}>
        <HStack spacing={4}>
          <Button colorScheme="green" variant="subtle" onClick={onClose}>
            Cancel
          </Button>
          <Button
            leftIcon={<BiSave />}
            variant="solid"
            onClick={() => {
              const newLciaMethod = {
                [ver]: {
                  [system_model]: {
                    lciaMethod: lciaMethod,
                    impactCategory: impactCategory,
                    indicatorName: indicatorName,
                    methodId: indicatorData[lciaMethod][impactCategory][indicatorName],
                  },
                },
              };
              saveUserSearchPreferences({ tableViewOption: String(tableViewOption) });
              indicatorData &&
                methodList &&
                saveSelectedMethod(
                  newLciaMethod,
                  methodList.filter((method) => method.method_name === lciaMethod)[0],
                  ver,
                  system_model
                );
              onChangeMethodId(indicatorData[lciaMethod][impactCategory][indicatorName]);
              onClose();
            }}
            isDisabled={!hasChange}
          >
            Save
          </Button>
        </HStack>
      </ModalFooter>
    </ModalContent>
  );
};

export default SearchPreferencesModalContent;
