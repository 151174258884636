import { UseRadioProps, useRadio, useColorModeValue, Box } from '@ecoinvent/ui';

type TableViewRadioCardProps = UseRadioProps & {
  children: React.ReactNode;
};

const TableViewRadioCard = (props: TableViewRadioCardProps) => {
  const { getInputProps, getRadioProps } = useRadio(props);
  const grayText = useColorModeValue('gray.600', 'gray.400');
  const bgColor = useColorModeValue('gray.200', 'gray.600');
  const checkedBgColor = useColorModeValue('white', 'gray.800');
  const checkedTextColor = useColorModeValue('black', 'white');
  const input = getInputProps();
  const checkbox = getRadioProps();
  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        bg={bgColor}
        color={grayText}
        border="none"
        borderRadius="md"
        _checked={{
          bg: checkedBgColor,
          color: checkedTextColor,
          borderColor: checkedBgColor,
          borderRadius: 'md',
        }}
        _focus={{}}
        px={5}
        py={1}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export default TableViewRadioCard;
