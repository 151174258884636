import { Stack, HStack, Heading, Badge, useColorModeValue } from '@ecoinvent/ui';
import mixpanel from 'mixpanel-browser';

import useVersionInfo from 'hooks/useVersionInfo';

type SectorBadgesProps = {
  sector: string;
  subsectors: string[];
  icon: JSX.Element;
  selectedSubSectors: string[];
  onSelectedSubSectorsChange: (seletectedSector: string[]) => void;
};

const SectorBadges = ({ sector, subsectors, icon, selectedSubSectors, onSelectedSubSectorsChange }: SectorBadgesProps) => {
  const { ver: version, system_model } = useVersionInfo();
  const headingColor = useColorModeValue('gray.700', 'gray.200');
  const badgeColor = useColorModeValue('green.600', 'green.400');

  const handleSubSectorClick = (subsector: string) => {
    if (selectedSubSectors.includes(subsector)) {
      onSelectedSubSectorsChange([...selectedSubSectors.filter((selected) => selected !== subsector)]);
    } else {
      onSelectedSubSectorsChange([...selectedSubSectors, subsector]);
      mixpanel.track('Explore by filter', { sector, subsector, version, system_model });
    }
  };

  return (
    <Stack spacing={2} maxW="200px">
      <HStack minH={10}>
        {icon}
        <Heading color={headingColor} size="sm">
          {sector}
        </Heading>
      </HStack>
      <HStack spacing={2} flexWrap="wrap">
        {subsectors.map((subsector) => (
          <Badge
            color={selectedSubSectors.includes(subsector) ? 'white' : badgeColor}
            borderRadius="md"
            key={subsector}
            colorScheme="green"
            variant={selectedSubSectors.includes(subsector) ? 'solid' : 'outline'}
            textTransform="none"
            fontWeight="500"
            onClick={() => handleSubSectorClick(subsector)}
            cursor="pointer"
          >
            {subsector}
          </Badge>
        ))}
      </HStack>
    </Stack>
  );
};

export default SectorBadges;
