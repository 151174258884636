import { localStorageKeys } from 'storageKeys';

import { SpoldDataResponse } from 'hooks/data/search/types';

import { UserSearchPreferences } from '.';

export const loadUserSearchPreferences = () => {
  const userSearchPreferences = localStorage.getItem(localStorageKeys.USER_SEARCH_PREFERENCES);
  return userSearchPreferences ? JSON.parse(userSearchPreferences) : {};
};
export const saveUserSearchPreferences = (userSearchPreferences: UserSearchPreferences | undefined) => {
  localStorage.setItem(localStorageKeys.USER_SEARCH_PREFERENCES, JSON.stringify(userSearchPreferences));
};
export const buildAggregations = (data: SpoldDataResponse | undefined) => ({
  sector: {
    sum_other_doc_count: 0,
    doc_count_error_upper_bound: 0,
    buckets: data ? data.filters.sectors.map((item) => ({ key: item.name, doc_count: item.count })) : [],
  },
  geography: {
    sum_other_doc_count: 0,
    doc_count_error_upper_bound: 0,
    buckets: data ? data.filters.geographies.map((item) => ({ key: item.name, doc_count: item.count })) : [],
  },
  isic_section: {
    sum_other_doc_count: 0,
    doc_count_error_upper_bound: 0,
    buckets: data ? data.filters.isic_sections.map((item) => ({ key: item.name, doc_count: item.count })) : [],
  },
  isic_class: {
    sum_other_doc_count: 0,
    doc_count_error_upper_bound: 0,
    buckets: data ? data.filters.isic_classes.map((item) => ({ key: item.name, doc_count: item.count })) : [],
  },
  activity_type: {
    sum_other_doc_count: 0,
    doc_count_error_upper_bound: 0,
    buckets: data ? data.filters.activity_types.map((item) => ({ key: item.name, doc_count: item.count })) : [],
  },
});

export const newerVersions = ['3.10', '3.10.1', '3.11'];
