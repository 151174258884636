import React from 'react';

import { Button, HStack, Icon, Td, Tr } from '@ecoinvent/ui';
import { BiChevronDown } from 'react-icons/bi';

import { ActivityReferenceProduct } from 'hooks/data/search/types';
import useVersionInfo from 'hooks/useVersionInfo';

import DatasetRow from './DatasetRow';

type Props = {
  referenceProduct: ActivityReferenceProduct;
  isLastGroup?: boolean;
};

const DatasetRowGroup = ({ referenceProduct, isLastGroup }: Props) => {
  const [displayCount, setDisplayCount] = React.useState(10);
  const { system_model } = useVersionInfo();

  const isLastVisibleGroup = isLastGroup || referenceProduct.total_datasets > displayCount;
  const currentlyDisplayed = Math.min(displayCount, referenceProduct.total_datasets);

  return (
    <>
      {referenceProduct.datasets.slice(0, displayCount).map((dataset, datasetIndex) => (
        <DatasetRow
          dataset={dataset}
          key={datasetIndex}
          isLastGroup={isLastVisibleGroup}
          referenceProductName={referenceProduct.name}
          index={datasetIndex}
          unit={referenceProduct.unit}
          currentlyDisplayed={currentlyDisplayed}
        />
      ))}
      {displayCount < referenceProduct.total_datasets && (
        <Tr>
          {system_model !== 'undefined' && <Td border={isLastGroup ? 'none' : 'auto'} />}
          <Td border={isLastGroup ? 'none' : 'auto'} colSpan={3}>
            <HStack justifyContent={'center'} py={2}>
              <Button
                variant={'link'}
                rightIcon={<Icon as={BiChevronDown} />}
                size="sm"
                onClick={() => setDisplayCount((count) => count * 2)}
              >
                Show more geographies ({referenceProduct.datasets.length - displayCount} more)
              </Button>
            </HStack>
          </Td>
        </Tr>
      )}
    </>
  );
};

export default DatasetRowGroup;
