import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import api from 'api/api';
import useVersionInfo from 'hooks/useVersionInfo';

import { FetchDatasetParams } from '../types';

type IndicatorNames = Record<string, number>;

type ImpactCategories = Record<string, IndicatorNames>;

export type LCIAMethod = Record<string, ImpactCategories>;

const fetchAllIndicators = async (params: Omit<FetchDatasetParams, 'dataset_id'>) => {
  const { data } = await api.get(`/web/indicators`, { params });

  return data;
};

const useAllIndicators = () => {
  const { ver: version, system_model } = useVersionInfo();
  return useQuery<LCIAMethod, AxiosError>(['indicators', version, system_model], () =>
    fetchAllIndicators({ version, system_model })
  );
};

export default useAllIndicators;
