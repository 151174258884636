import { Alert, AlertDescription, AlertIcon, Icon, IconButton, Link, Stack } from '@ecoinvent/ui';
import { BiX } from 'react-icons/bi';

import useCloseableAlertState from 'hooks/useCloseableAlertState';

const YearReviewBanner = () => {
  const { isOpen, onClose } = useCloseableAlertState('yearReviewBanner');

  if (!isOpen) {
    return null;
  }

  return (
    <Stack p={3} px={5}>
      <Alert rounded="lg">
        <AlertIcon />
        <AlertDescription fontSize={'sm'} width={'100%'}>
          ecoinvent version 3.11 is now available! Read more about this new release{' '}
          <Link colorScheme="blue" isExternal href="https://support.ecoinvent.org/ecoinvent-version-3.11">
            here
          </Link>
          .
        </AlertDescription>
        <IconButton
          onClick={onClose}
          aria-label={'close'}
          icon={<Icon as={BiX} />}
          size={'xs'}
          fontSize={'lg'}
          variant={'ghost'}
          colorScheme="blue"
        />
      </Alert>
    </Stack>
  );
};

export default YearReviewBanner;
