import { supportedFilters } from 'hooks/data/search/constants';
import { SupportedFilter, FilterMap, ElasticAggregation } from 'hooks/data/search/types';

import { LabeledFilterBucket } from './filters/ActivitySearchFilters/types';

export const matchSelectedFilterToAggregations = (
  filterName: SupportedFilter['field'],
  aggregations: Record<keyof FilterMap, ElasticAggregation> | undefined,
  filters: FilterMap
) => {
  if (!aggregations) {
    return filters[filterName].map((item) => ({ key: item, doc_count: 0 })); // Return the filters as-is if there are no aggregations.
  }
  // Filter the aggregation buckets to include the keys present in filters for the given field.
  const matchingFiltersInAggregations =
    aggregations[filterName].buckets.filter((o) => filters[filterName].indexOf(o.key) != -1) ?? [];

  // Return the matching aggregations if they exist, otherwise return the filters as-is to not step on values.
  return matchingFiltersInAggregations.length
    ? matchingFiltersInAggregations
    : filters[filterName].map((item) => ({ key: item, doc_count: 0 }));
};

export const generateSelectedItems = (
  aggregations: Record<keyof FilterMap, ElasticAggregation> | undefined,
  filters: FilterMap
) => {
  if (!aggregations || !Object.keys(aggregations).length) {
    return {} as Record<keyof FilterMap, LabeledFilterBucket>; // Return an empty object if there are no aggregations.
  }

  return supportedFilters.reduce(
    (acc, f) => {
      acc[f.field] = { displayName: f.label, values: matchSelectedFilterToAggregations(f.field, aggregations, filters) };
      return acc; // Accumulate results for each supported filter in the output map.
    },
    {} as Record<keyof FilterMap, LabeledFilterBucket>
  );
};
