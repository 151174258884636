import { useColorModeValue, Icon } from '@ecoinvent/ui';
import { IconType } from 'react-icons';

const SectorIcon = ({ icon }: { icon: IconType }) => {
  const bgColor = useColorModeValue('gray.50', 'gray.600');
  const color = useColorModeValue('green.500', 'green.300');
  return <Icon color={color} as={icon} backgroundColor={bgColor} borderRadius={'md'} w="32px" h="32px" p={1} />;
};

export default SectorIcon;
